import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatWeekday'
})

export class WeekdayPipe implements PipeTransform {
  constructor () {
  }

  transform(day: string): string {
    switch (day) {
      case ('monday'):
        return 'Пн';
      break;
      case ('tuesday'):
        return 'Вт';
      break;
      case ('wednesday'):
        return 'Ср';
      break;
      case ('thursday'):
        return 'Чт';
      break;
      case ('friday'):
        return 'Пт';
      break;
      case ('saturday'):
        return 'Сб';
      break;
      case ('sunday'):
        return 'Вс';
      break;
    }
  }
}
