<div class="product-item__block" *ngIf="ProductView === PITypes.Block">
  <div class="product-item__block-content" (click)="onViewProductDetail()">
    <div class="product-item__block-picture" *ngIf="onProductPhoto">
      <img src="{{StaticURL}}{{onProductPhoto}}" onerror="this.src='assets/images/others/balalaika_dark.png'">
    </div>
    <div class="product-item__block-cup" *ngIf="!onProductPhoto">
      <i class="pi pi-camera"></i>
    </div>
    <div class="product-item__block-heading pt-2">
      <p>{{ProductItem?.title}}</p>
    </div>
    <div class="product-item__block-price pt-2">
      <h5>{{ProductItem?.price | price}} RUB</h5>
    </div>
  </div>
</div>

<div class="product-item__profile" *ngIf="ProductView === PITypes.Profile">
  <div class="grid align-items-start"
    ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : ''}}">

    <div class="product-item__profile-content">
      <div class="grid align-items-start justify-content-start"
        ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : ''}}">

        <div class="col-3 col-sm-12"
          ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'no-padding' : ''}}">

          <div class="product-item__profile-picture" (click)="onViewProductDetail()" *ngIf="onProductPhoto">
            <img src="{{StaticURL}}{{onProductPhoto}}" onerror="this.src='assets/images/others/balalaika_dark.png'">
          </div>
          <div class="product-item__profile-cup" (click)="onViewProductDetail()" *ngIf="!onProductPhoto">
            <i class="pi pi-camera"></i>
          </div>
        </div>

        <div class="col-9 col-sm-12"
          ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-4 no-padding' : ''}}">

          <div class="product-item__profile-heading" (click)="onViewProductDetail()"
            ngClass="{{(deviceValue | async) === DTypes.Desktop ? 'pb-3' : 'pb-2'}}">

            <div class="col-10 product-item__profile-row"
              ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'no-padding' : ''}}">
              <p>{{ProductItem?.title}}</p>
            </div>
          </div>
          <div class="grid align-items-start flex-column">
            <div class="col-10 product-item__profile-row mr-1 mb-2"
              ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'no-padding' : ''}}">

              <div class="grid align-self-center flex-wrap">
                <div class="col-auto align-self-center">
                  <p class="__title mr-2">Описание:</p>
                </div>
                <div class="col-auto">
                  <p>{{ProductItem?.description}}</p>
                </div>
              </div>
            </div>
            <div class="col-10 product-item__profile-row mr-1 mb-2"
              ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'no-padding' : ''}}">

              <div class="grid align-self-center flex-wrap">
                <div class="col-auto align-self-center">
                  <p class="__title mr-2"> Цена:</p>
                </div>
                <div class="col-auto">
                  <p>{{ProductItem?.price | price}} RUB</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="grid align-items-center justify-content-center product-item__profile-event"
      ngClass="{{(deviceValue | async) === DTypes.Mobile ? '' : 'flex-column'}}">

      <div class="col-auto col-sm-6"
        ngClass="{{(deviceValue | async) === DTypes.Mobile ? '' : 'w-full mt-4'}}">

        <button class="ui-buttons ui-buttons__light-small" (click)="onEditProductProfile()" pRipple>
          Редактировать
        </button>
      </div>

      <div class="col-auto col-sm-6"
        ngClass="{{(deviceValue | async) === DTypes.Mobile ? '' : 'w-full mt-2 mb-2'}}">

        <button class="ui-buttons ui-buttons__light-red" (click)="onDeleteProductProfile()">
          Удалить
        </button>
      </div>
    </div>
  </div>
</div>
