import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AuthRoutes } from './auth.routes';
import { ReactiveFormsModule } from '@angular/forms';

/**
 * Components
 */
import { SignInComponent } from './sign-in/sign-in.component';
import { SignOauthComponent } from './sign-oauth/sign-oauth.component';
import { SignRemindComponent } from './sign-remind/sign-remind.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { SignNeedComponent } from './sign-need/sign-need.component';
import { SignConfirmedComponent } from './sign-confirmed/sign-confirmed.component';
import { SignSuccessComponent } from './sign-success/sign-success.component';

/**
 * Shared
 */
import { BreadcrumbsModule } from '@app/shared/breadcrumbs/breadcrumbs.module';

/**
 * Services
 */
import { AuthService } from '@app/modules/auth-modules/auth.service';
import { OAuthService } from '@app/modules/auth-modules/oauth.service';

/**
 * Vendors
 */
import { DialogService } from 'primeng/dynamicdialog';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { TooltipModule } from 'primeng/tooltip';
import { ChipModule } from 'primeng/chip';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE, DateAdapter } from '@angular/material/core';
import { NgxMaskModule } from 'ngx-mask';
import { InputMaskModule } from 'primeng/inputmask';
import { RippleModule } from 'primeng/ripple';
import { KeyFilterModule } from 'primeng/keyfilter';

/**
 * Adapters
 */
import { DatepickerAdapter } from '@app/core/adapters';

@NgModule({
  declarations: [
    SignInComponent,
    SignOauthComponent,
    SignRemindComponent,
    SignUpComponent,
    SignNeedComponent,
    SignConfirmedComponent,
    SignSuccessComponent
  ],
  exports: [
    SignInComponent,
    SignOauthComponent,
    SignUpComponent,
    SignNeedComponent,
    SignConfirmedComponent,
    SignSuccessComponent
  ],
  entryComponents: [
    SignOauthComponent,
    SignRemindComponent,
    SignNeedComponent,
    SignConfirmedComponent
  ],
  imports: [
    RouterModule.forChild(AuthRoutes),
    CommonModule,
    ReactiveFormsModule,
    BreadcrumbsModule,
    TooltipModule,
    ChipModule,
    ToastModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxMaskModule.forRoot(),
    InputMaskModule,
    RippleModule,
    KeyFilterModule
  ],
  providers: [
    AuthService,
    OAuthService,
    DialogService,
    DynamicDialogRef,
    DynamicDialogConfig,
    MessageService,
    {provide: MAT_DATE_LOCALE, useValue: 'ru-RU'},
    {provide: DateAdapter, useClass: DatepickerAdapter},
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})

export class AuthModule {}
