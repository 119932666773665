import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DevicesClass } from '@app/core/classes';
import { BreadcrumbsDataModel } from '@app/core/models/breadcrumbs';
import { RoutingConfig } from '@app/routing/routing.config';
import { AccountService } from '@app/modules/account-modules/account.service';
import { DialogIncludesTypes } from '@app/core/models/diallog';
import { NotificationService } from '@app/modules/notification-modules/notification.service';

export enum MessengerType {
  vk,           // 0
  telegram,     // 1
}

@UntilDestroy()
@Component({
  selector: 'app-account-discount',
  templateUrl: 'settings.component.html',
  styleUrls: [`./settings.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class SettingsComponent extends DevicesClass implements OnInit {
  public BreadcrumbsData: BreadcrumbsDataModel = [
    {
      title: 'Личный кабинет',
      url: '/' + RoutingConfig.Account
    },
    {
      title: 'Настройки',
      url: '/' + RoutingConfig.AccountDiscount,
      active: true
    }
  ];

  public SettingsForm: FormGroup;
  public SettingsFormModify: boolean;
  public SettingsLoading: boolean;

  /**
   * Messengers settings
   */
  public TelegramNotifyEnabled: boolean;
  public VKNotifyEnabled: boolean;
  public MType = MessengerType;

  constructor(injector: Injector,
    public cdr: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    private notificationService: NotificationService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.SettingsForm = this.formBuilder.group({
      musicianSearch: [false, Validators.required],
      email: [false, Validators.required],
      news: [false, Validators.required]
    });

    this.onSettingsFormCtrl();
    this.onGetSettingsValue();
  }

  onGetSettingsValue(): void {
    this.accountService.getSettingsNotification().subscribe(
      response => {
        for (const key of Object.keys(response)) {
          if (this.SettingsForm.controls[key]) {
            this.SettingsForm.controls[key].setValue(
              response[key], { emitEvent: false }
            );
          }
        }

        this.TelegramNotifyEnabled = response.telegramNotifyEnabled;
        this.VKNotifyEnabled = response.vkNotifyEnabled;

        this.cdr.markForCheck();
      }
    )
  }

  onSettingsFormCtrl(): void {
    this.SettingsForm.valueChanges.pipe(untilDestroyed(this)).subscribe(
      value => {
        this.SettingsFormModify = true;
        this.cdr.markForCheck();
      }
    )
  }

  onMessengerGetDeepLinkIdentifier(type: MessengerType): void {
    this.accountService.getDeepLinkIdentifier(type).subscribe(
      response => {
        window.open(
          `${response}`, '_blank'
        );
      }
    )
  }

  onMessengerUnsubscribe(type: MessengerType): void {
    this.accountService.unsubscribeNotification(type).subscribe(
      response => {
        this.onGetSettingsValue();
      }
    )
  }

  onSaveSettings(): void {
    this.accountService.saveSettingsNotification(this.SettingsForm.getRawValue()).subscribe(
      response => {
        this.notificationService.onDialog({
          type: DialogIncludesTypes.PopupSuccessTemplate,
          header: 'Обновление профиля',
          content: 'Настройки Вашего профиля успешно обновлены.',
          icons: 'pi pi-user-edit'
        });
        this.SettingsFormModify = false;
      },
      error => {
        this.notificationService.onDialog({
          type: DialogIncludesTypes.PopupErrorTemplate,
          header: 'Ошибка',
          content: 'Не удалось обновить настройки Вашего профиля',
        });
        this.SettingsFormModify = false;
      }
    );
  }
}
