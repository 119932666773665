<div class="detail-product__wrapper">
  <div class="row">
    <div class="detail-product__content">
      <div class="grid align-items-start"
        ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : ''}}">

        <div class="col-4 col-sm-12"
          ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'order-0 pl-1 pr-1' : ''}}">

          <div class="ui-gallery-upload__cup" *ngIf="!onProductMainPhoto">
            <div class="grid align-items-center justify-content-center flex-column">
              <i class="pi pi-camera"></i>
              <h5>Фотограция отсутствует</h5>
            </div>
          </div>

          <div class="ui-gallery-upload__preview"  *ngIf="onProductMainPhoto">
            <div class="grid align-items-end flex-nowrap">
              <div class="ui-gallery-upload__preview__item">
                <img src="{{StaticURL}}{{onProductMainPhoto}}">
              </div>
            </div>
          </div>
        </div>
        <div class="col-8 col-sm-12"
          ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'order-1 pt-4 pl-0 pr-0' : ''}}">

          <div class="grid align-items-start flex-column">
            <div class="col-11 detail-product__row mr-1 mb-6">
              <div class="grid align-self-center flex-wrap">
                <div class="col-12 align-self-center no-padding">
                  <p class="__title mr-2">Описание:</p>
                </div>
                <div class="col-12 mt-2 no-padding">
                  <p>{{Product?.description}}</p>
                </div>
              </div>
            </div>

            <div class="col-11 detail-product__row mr-1 mt-2">
              <div class="grid align-self-center flex-wrap">
                <div class="col-12 align-self-center no-padding">
                  <p class="__title mr-2">Цена:</p>
                </div>
                <div class="col-12 mt-1 no-padding">
                  <h5>{{Product?.price | price}} RUB</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="detail-product__helpers">
      <div class="grid align-items-center"
        ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : ''}}">

        <div class="col-3 col-sm-12 pl-0">
          <button class="ui-buttons ui-buttons__blue-large" (click)="onCloseDialog()">Закрыть</button>
        </div>
      </div>

      <div class="detail-product__helpers-graphic"
        *ngIf="(deviceValue | async) === DTypes.Desktop || (deviceValue | async) === DTypes.Tablet">
        <div class="ui-graphics__balalaika-dark"></div>
      </div>
    </div>
  </div>
</div>
