import { AppApiURL } from '@app/app.settings';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { IBulletinsSearch } from '@app/modules/bulletins-modules/bulletins.model';
import { ILoyaltyPartnersSearch } from '@app/modules/loyalty-modules/loyalty.model';
import { IBlogPostNewsLists } from '@app/modules/news-modules/news.models';
import { Observable, throwError } from 'rxjs';
import { timeout, map, catchError } from 'rxjs/operators';

@Injectable()

export class FavoritesService {
  constructor(
    private http: HttpClient
  ) {}

  /**
   * Api: v01.
   * Method: Get of favorites bulletins for user
   */
  public bulletinsFavorites(): Observable<IBulletinsSearch> {
    return this.http.get<IBulletinsSearch>(AppApiURL + `/favorites/searchBulletins`,
      {
        withCredentials: true,
        observe: 'response',
        reportProgress: false
      }
    ).pipe(
      map((response: HttpResponse<any>) => response.body),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  /**
   * Api: v01.
   * Method: Get of favorites news for user
   */
  public newsFavorites(): Observable<IBlogPostNewsLists> {
    return this.http.get<IBlogPostNewsLists>(AppApiURL + `/favorites/news`,
      {
        withCredentials: true,
        observe: 'response',
        reportProgress: false
      }
    ).pipe(
      map((response: HttpResponse<any>) => response.body),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  /**
   * Api: v01.
   * Method: Get of favorites news for user
   */
  public partnersFavorites(): Observable<ILoyaltyPartnersSearch> {
    return this.http.get<ILoyaltyPartnersSearch>(AppApiURL + `/favorites/partners`,
      {
        withCredentials: true,
        observe: 'response',
        reportProgress: false
      }
    ).pipe(
      map((response: HttpResponse<any>) => response.body),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  /**
   * Api: v01.
   * Method: Add bulletins to favorites
   * @param id
   * @param type
   * Новость - 1, статья - 2, музыкант ищет группу = 3, группа ищем музыканта - 4, партнер - 5
   */
  public addToFavorites(id: number, type: number): Observable<any> {
    return this.http.post<any>(AppApiURL + `/favorites?itemId=${id}&type=${type}`,
      null,
      {
        withCredentials: true,
        observe: 'response',
        reportProgress: false
      }
    ).pipe(
      timeout(10000),
      map((response: HttpResponse<any>) => response.body),
      catchError((error: HttpErrorResponse) => {
        return throwError(error.status);
      })
    );
  }

  /**
   * Api: v01.
   * Method: Remove bulletins from favorites
   * @param id
   */
  public removeFromFavorites(id: number): Observable<any> {
    return this.http.delete<any>(AppApiURL + `/favorites?ids=${id}`,
      {
        withCredentials: true,
        observe: 'response',
        reportProgress: false
      }
    ).pipe(
      timeout(10000),
      map((response: HttpResponse<any>) => response.body),
    );
  }

  /**
   * Api: v01.
   * Method: Remove bulletins from favorites on arrays values
   * @param arrays
   */
  public removeFromFavoritesArray(arrays: string): Observable<any> {
    return this.http.delete<any>(AppApiURL + `/favorites?${arrays}`,
      {
        withCredentials: true,
        observe: 'response',
        reportProgress: false
      }
    ).pipe(
      timeout(10000),
      map((response: HttpResponse<any>) => response.body),
    );
  }
}
