<div class="partner-item__block" *ngIf="PartnerView === PITypes.Block">
  <div class="grid align-items-center partner-item__block-content">
    <div class="col-9 align-self-start">
      <a class="partner-item__block-heading"
        routerLink="/{{Routing.Loyalty}}/{{Routing.Partner}}/{{PartnerItem.id}}"
        target="{{onViewDetailPartner()}}"
        ngClass="{{(deviceValue | async) === DTypes.Desktop ? 'pb-3' : 'pb-2'}}">

        <p>{{PartnerItem | partnerHeading}}</p>
      </a>

      <div class="partner-item__block-row" *ngIf="PartnerItem.address">
        <div class="grid align-self-start flex-wrap">
          <div class="col-auto" ngClass="{{(deviceValue | async) === DTypes.Desktop ? 'mb-1' : 'mb-1'}}">
            <p class="__title mr-2">Адрес:</p>
          </div>
          <div class="col-auto address__text">
            <p>{{PartnerItem.address?.fullAddress ? PartnerItem.address?.fullAddress : '- - -'}}</p>
            <div class="row pt-2">
              <ng-container *ngFor="let metro of PartnerItem?.address?.metro">
                <div class="ui-address__metro">
                  <div class="grid align-items-start">
                    <div class="col-auto mr-1">
                      <div class="ui-address__metro-icon {{metro?.line | metroLineAlias}}"
                        pTooltip="{{metro?.line}}"
                        tooltipPosition="top"
                        tooltipStyleClass="p-tooltip__input">
                      </div>
                    </div>
                    <div class="col-auto">
                      <p>{{metro?.name}} {{metro?.distanceKm | metroDistance}}</p>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="row mb-2" *ngIf="PartnerItem.address?.fullAddress">
              <p class="txt-link" (click)="onPartnerViewOnMap()">Показать на карте</p>
            </div>
          </div>
        </div>
      </div>

      <div class="partner-item__block-row mt-1">
        <div class="grid align-self-center flex-wrap">
          <div class="col-auto align-self-center" ngClass="{{(deviceValue | async) === DTypes.Desktop ? 'mb-1' : 'mb-1'}}">
            <p class="__title mr-2">График работы:</p>
          </div>
        </div>
        <div class="grid align-items-start">
          <div class="col-auto">
            <ng-container *ngFor="let weekday of onConvertWeekday()">
              <div class="grid align-items-center">
                <div class="col-auto">
                  <p>{{weekday.day | formatWeekday}}<span *ngIf="weekday.endDay"> - {{weekday.endDay | formatWeekday}}</span>:</p>
                </div>
                <div class="col-auto pl-2 mr-1">
                  <p class="txt-caption__large">{{onSplitWorkHours(weekday.fromIni)}}</p>
                </div>
                <div class="col-auto align-items-center text-center">
                  <p class="txt-caption__large">—</p>
                </div>
                <div class="col-auto pr-2 ml-1">
                  <p class="txt-caption__large">{{onSplitWorkHours(weekday.toIni)}}</p>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="partner-item__block-row mt-3">
        <div class="grid align-self-center flex-wrap">
          <div class="col-auto align-self-center" ngClass="{{(deviceValue | async) === DTypes.Desktop ? 'mb-2' : 'mb-1'}}">
            <p class="__title __red mr-2">Скидка партнера:</p>
          </div>
          <div class="col-auto mb-2">
            <p class="__red">{{PartnerItem.partnerDiscountPercent}}%</p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-3 align-self-start">
      <a class="partner-item__block-picture"
        routerLink="/{{Routing.Loyalty}}/{{Routing.Partner}}/{{PartnerItem.id}}"
        target="{{onViewDetailPartner()}}">
        <img src="{{StaticURL}}{{PartnerItem.logoLink}}" onerror="this.src='assets/images/others/balalaika_dark.png'">
      </a>
    </div>
  </div>
  <div class="grid align-items-center partner-item__block-event">
    <div class="col-auto partner-item__block-event__favorites animated fast zoomIn" *ngIf="PIFavLoading">
      <div class="ui-loader__base"></div>
    </div>
    <div class="col-auto partner-item__block-event__favorites" *ngIf="!PIFavLoading && !PIFavActive" (click)="onAddToFavorites()">
      <span class="mr-icon mr-icon-favorites"></span>
      <span>В избранное</span>
    </div>
    <div class="col-auto partner-item__block-event__favorites" *ngIf="!PIFavLoading && PIFavActive" (click)="onRemoveFromFavorites()">
      <span class="mr-icon mr-icon-favorites-active"></span>
      <span>В избранном</span>
    </div>
  </div>
</div>

<div class="partner-item__list" *ngIf="PartnerView === PITypes.List">
  <div class="partner-item__list-content">
    <a class="partner-item__list-heading"
      routerLink="/{{Routing.Loyalty}}/{{Routing.Partner}}/{{PartnerItem.id}}"
      target="{{onViewDetailPartner()}}"
      ngClass="{{(deviceValue | async) === DTypes.Desktop ? 'pb-3' : 'pb-2'}}">

      <p>{{PartnerItem | partnerHeading}}</p>
    </a>

    <div class="grid align-items-center flex-wrap">
      <div class="col-auto partner-item__list-row mr-1" *ngIf="PartnerItem.address">
        <div class="grid align-self-center flex-wrap">
          <div class="col-auto mb-2 align-self-center">
            <p class="__title mr-2">Адрес:</p>
          </div>
          <div class="col-auto mb-2 mr-4">
            <p ngClass="{{PartnerItem.address?.fullAddress ? 'txt-link' : ''}}" (click)="onPartnerViewOnMap()">
              {{PartnerItem.address?.fullAddress ? PartnerItem.address?.fullAddress : '- - -'}}
            </p>
          </div>
        </div>
      </div>

      <div class="col-auto partner-item__list-row mr-4">
        <div class="grid align-self-center flex-wrap">
          <div class="col-auto mb-2 align-self-center">
            <p class="__title mr-2">График работы:</p>
          </div>
          <div class="col-auto mb-2">
            <div class="grid align-items-center">
              <ng-container *ngFor="let weekday of onConvertWeekday()">
                <div class="grid align-items-center mr-2">
                  <div class="col-auto">
                    <p>{{weekday.day | formatWeekday}}<span *ngIf="weekday.endDay"> - {{weekday.endDay | formatWeekday}}</span>:</p>
                  </div>
                  <div class="col-auto pl-2 mr-1">
                    <p class="txt-caption__large">{{onSplitWorkHours(weekday.fromIni)}}</p>
                  </div>
                  <div class="col-auto align-items-center text-center">
                    <p class="txt-caption__large">—</p>
                  </div>
                  <div class="col-auto pr-2 ml-1">
                    <p class="txt-caption__large">{{onSplitWorkHours(weekday.toIni)}}</p>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>

      <div class="col-auto partner-item__list-row mr-4">
        <div class="grid align-self-center flex-wrap">
          <div class="col-auto mb-2 align-self-center">
            <p class="__title __red mr-2">Скидка партнера:</p>
          </div>
          <div class="col-auto mb-2">
            <p class="__red">{{PartnerItem.partnerDiscountPercent}}%</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="grid align-items-center partner-item__list-event">
    <div class="col-auto partner-item__block-event__favorites animated fast zoomIn" *ngIf="PIFavLoading">
      <div class="ui-loader__base"></div>
    </div>
    <div class="col-auto partner-item__block-event__favorites" *ngIf="!PIFavLoading && !PIFavActive" (click)="onAddToFavorites()">
      <span class="mr-icon mr-icon-favorites"></span>
      <span>В избранное</span>
    </div>
    <div class="col-auto partner-item__block-event__favorites" *ngIf="!PIFavLoading && PIFavActive" (click)="onRemoveFromFavorites()">
      <span class="mr-icon mr-icon-favorites-active"></span>
      <span>В избранном</span>
    </div>
  </div>
</div>

<div class="partner-item__list" *ngIf="PartnerView === PITypes.ProfileList">
  <div class="partner-item__list-content">
    <a class="partner-item__list-heading"
      routerLink="/{{Routing.Loyalty}}/{{Routing.Partner}}/{{PartnerItem.itemId}}"
      target="{{onViewDetailPartner()}}"
      ngClass="{{(deviceValue | async) === DTypes.Desktop ? 'pb-3' : 'pb-2'}}">

      <p>{{PartnerItem | partnerHeading}}</p>
    </a>

    <div class="grid align-items-center flex-wrap">
      <div class="col-auto partner-item__list-row mr-1" *ngIf="PartnerItem.address">
        <div class="grid align-self-center flex-wrap">
          <div class="col-auto mb-2 align-self-center">
            <p class="__title mr-2">Адрес:</p>
          </div>
          <div class="col-auto mb-2 mr-4">
            <p ngClass="{{PartnerItem.address?.fullAddress ? 'txt-link' : ''}}" (click)="onPartnerViewOnMap()">
              {{PartnerItem.address?.fullAddress ? PartnerItem.address?.fullAddress : '- - -'}}
            </p>
          </div>
        </div>
      </div>

      <div class="col-auto partner-item__list-row mr-4">
        <div class="grid align-self-center flex-wrap">
          <div class="col-auto mb-2 align-self-center">
            <p class="__title mr-2">График работы:</p>
          </div>
          <div class="col-auto mb-2">
            <div class="grid align-items-center">
              <ng-container *ngFor="let weekday of onConvertWeekday()">
                <div class="grid align-items-center mr-2">
                  <div class="col-auto">
                    <p>{{weekday.day | formatWeekday}}<span *ngIf="weekday.endDay"> - {{weekday.endDay | formatWeekday}}</span>:</p>
                  </div>
                  <div class="col-auto pl-2 mr-1">
                    <p class="txt-caption__large">{{onSplitWorkHours(weekday.fromIni)}}</p>
                  </div>
                  <div class="col-auto align-items-center text-center">
                    <p class="txt-caption__large">—</p>
                  </div>
                  <div class="col-auto pr-2 ml-1">
                    <p class="txt-caption__large">{{onSplitWorkHours(weekday.toIni)}}</p>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>

      <div class="col-auto partner-item__list-row mr-4">
        <div class="grid align-self-center flex-wrap">
          <div class="col-auto mb-2 align-self-center">
            <p class="__title __red mr-2">Скидка партнера:</p>
          </div>
          <div class="col-auto mb-2">
            <p class="__red">{{PartnerItem.partnerDiscountPercent}}%</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="partner-item__block" *ngIf="PartnerView === PITypes.ProfileBlock">
  <div class="grid align-items-center partner-item__block-content pb-4">
    <div class="col-9 align-self-start">
      <a class="partner-item__block-heading"
        routerLink="/{{Routing.Loyalty}}/{{Routing.Partner}}/{{PartnerItem.id}}"
        target="{{onViewDetailPartner()}}"
        ngClass="{{(deviceValue | async) === DTypes.Desktop ? 'pb-3' : 'pb-2'}}">

        <p>{{PartnerItem | partnerHeading}}</p>
      </a>

      <div class="partner-item__block-row" *ngIf="PartnerItem.address">
        <div class="grid align-self-start flex-wrap">
          <div class="col-auto" ngClass="{{(deviceValue | async) === DTypes.Desktop ? 'mb-1' : 'mb-1'}}">
            <p class="__title mr-2">Адрес:</p>
          </div>
          <div class="col-auto address__text">
            <p ngClass="{{PartnerItem.address?.fullAddress ? 'txt-link' : ''}}" (click)="onPartnerViewOnMap()">
              {{PartnerItem.address?.fullAddress ? PartnerItem.address?.fullAddress : '- - -'}}
            </p>
          </div>
        </div>
      </div>

      <div class="partner-item__block-row mt-1">
        <div class="grid align-self-center flex-wrap">
          <div class="col-auto align-self-center" ngClass="{{(deviceValue | async) === DTypes.Desktop ? 'mb-1' : 'mb-1'}}">
            <p class="__title mr-2">График работы:</p>
          </div>
        </div>
        <div class="grid align-items-start">
          <div class="col-auto">
            <ng-container *ngFor="let weekday of onConvertWeekday()">
              <div class="grid align-items-center">
                <div class="col-auto">
                  <p>{{weekday.day | formatWeekday}}<span *ngIf="weekday.endDay"> - {{weekday.endDay | formatWeekday}}</span>:</p>
                </div>
                <div class="col-auto pl-2 mr-1">
                  <p class="txt-caption__large">{{onSplitWorkHours(weekday.fromIni)}}</p>
                </div>
                <div class="col-auto align-items-center text-center">
                  <p class="txt-caption__large">—</p>
                </div>
                <div class="col-auto pr-2 ml-1">
                  <p class="txt-caption__large">{{onSplitWorkHours(weekday.toIni)}}</p>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="partner-item__block-row mt-3">
        <div class="grid align-self-center flex-wrap">
          <div class="col-auto align-self-center" ngClass="{{(deviceValue | async) === DTypes.Desktop ? 'mb-2' : 'mb-1'}}">
            <p class="__title __red mr-2">Скидка партнера:</p>
          </div>
          <div class="col-auto mb-2">
            <p class="__red">{{PartnerItem.partnerDiscountPercent}}%</p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-3">
      <a class="partner-item__block-picture"
        routerLink="/{{Routing.Loyalty}}/{{Routing.Partner}}/{{PartnerItem.id}}"
        target="{{onViewDetailPartner()}}">
        <img src="{{StaticURL}}{{PartnerItem.logoLink}}" onerror="this.src='assets/images/others/balalaika_dark.png'">
      </a>
    </div>
  </div>
</div>

<div class="partner-item__detail" *ngIf="PartnerView === PITypes.Detail">
  <div class="partner-item__detail-favorites" *ngIf="!PIFavActive"
    (click)="onAddToFavorites()">

    <div class="partner-item__detail-favorites__background">
      <span class="mr-icon mr-icon-ribbon"></span>
    </div>
    <div class="partner-item__detail-favorites__event">
      <span class="mr-icon mr-icon-favorites" *ngIf="!PIFavLoading"></span>
      <div class="ui-loader__base" *ngIf="PIFavLoading"></div>
    </div>
    <div class="partner-item__detail-favorites__text"
      ngClass="{{PIFavLoading ? 'hide' : 'view'}}">
      <span class="pt-1">В избранное</span>
    </div>
  </div>

  <div class="partner-item__detail-favorites" *ngIf="PIFavActive"
    (click)="onRemoveFromFavorites()">

    <div class="partner-item__detail-favorites__background">
      <span class="mr-icon mr-icon-ribbon"></span>
    </div>
    <div class="partner-item__detail-favorites__event">
      <span class="mr-icon mr-icon-favorites-active" *ngIf="!PIFavLoading"></span>
      <div class="ui-loader__base" *ngIf="PIFavLoading"></div>
    </div>
    <div class="partner-item__detail-favorites__text"
      ngClass="{{PIFavLoading ? 'hide' : 'view'}}">
      <span class="pt-1">В избранном</span>
    </div>
  </div>
  
  <div class="partner-item__detail-info">
    <div class="pg-wrapper">
      <div class="row" ngClass="{{(deviceValue | async) === DTypes.Desktop ? 'pt-6 pb-2' : 'pt-4 pb-2'}}">
        <div class="partner-item__detail-heading">
          <h1>{{PartnerDetail | partnerHeading:'detailType'}}</h1>
        </div>
      </div>
      <div class="row" ngClass="{{(deviceValue | async) === DTypes.Desktop ? 'pb-4' : 'pb-4'}}">
        <div class="grid align-items-center justify-content-center"
          ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-wrap' : ''}}">

          <div class="partner-item__detail-icons col-auto">
            <span>Рейтинг: </span>
            <span class="pi pi-star _orange"></span>
            <span class="pi pi-star _orange"></span>
            <span class="pi pi-star _orange"></span>
            <span class="pi pi-star _orange"></span>
            <span class="pi pi-star  _orange"></span>
          </div>

          <div class="partner-item__detail-icons col-auto">
            <span class="pi pi-comment"></span>
            <span>Отзывов: {{PartnerDetail.rateInfo.feedbacksCount}}</span>
          </div>

          <div class="partner-item__detail-icons col-auto">
            <span>Просмотров: {{PartnerDetail?.viewCount}}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="pg-wrapper"
      ngClass="{{(deviceValue | async) === DTypes.Desktop ? '' : 'p-0'}}">

      <div class="row">
        <div class="partner-item__detail-gallery">
          <shared-photos-gallery [PartnersGalleryData]="PIGallery"></shared-photos-gallery>
        </div>
      </div>
    </div>
  </div>

  <div class="partner-item__detail-description">
    <div class="pg-wrapper">
      <div class="row" *ngIf="onPartnerProducts()">
        <div class="row animated fadeIn faster delay-0_3s">
          <div class="grid align-items-center justify-content-center">
            <div class="ui-tabs">
              <div class="ui-tabs__link active">
                <p>Товары и услуги</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="partner-item__detail-products">
            <div class="products-gallery__slide">
              <ng-container *ngFor="let product of onPartnerProducts();">
                <shared-product-item [ProductItem]="product" [ProductView]="PrITypes.Block"></shared-product-item>
              </ng-container>
            </div>
          </div>
        </div>
      </div>

      <div class="partner-item__detail-description__wrapper"
        ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-6' : 'mt-10'}}">
        <div class="partner-item__detail-description__row">
          <div class="grid align-items-start"
            ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : ''}}">

            <div class="col-3 align-self-end col-sm-12"
              ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'order-1 mt-4' : ''}}">

              <div class="partner-item__detail-performer animated fast fadeIn">
                <div class="partner-item__detail-performer__avatar">
                  <img src="{{StaticURL}}{{PartnerDetail.additionalInfoVm.logoPath}}" *ngIf="PartnerDetail.additionalInfoVm.logoPath"
                    onError="this.src='assets/images/others/astronaut_dark.png'">

                  <img src="assets/images/others/astronaut_dark.png" *ngIf="!PartnerDetail.additionalInfoVm.logoPath">
                </div>
                <div class="partner-item__detail-performer__heading">
                  <h5>{{PartnerDetail.title}}</h5>
                </div>
              </div>
            </div>

            <div class="col-8 no-padding col-sm-12"
              ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'order-0 mb-4' : ''}}">

              <div class="row"
                ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-3 mb-1' : 'mt-1 mb-3'}}">

                <div class="grid justify-content-between"
                  ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : ''}}">

                  <div class="col-12 col-sm-12 no-padding"
                    ngClass="{{(deviceValue | async) === DTypes.Desktop ? 'mr-2' : ''}}">

                    <div class="partner-item__detail-table">
                      <div class="partner-item__detail-table-tr">
                        <div class="partner-item__detail-table-td">
                          <p>Адрес:</p>
                        </div>
                        <div class="partner-item__detail-table-td">
                          <div class="grid align-items-start"
                            ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : ''}}">

                            <div class="col-8 col-sm-12">
                              <div class="row">
                                <p>{{PartnerDetail.additionalInfoVm.address?.fullAddress ? PartnerDetail.additionalInfoVm.address?.fullAddress : 'Информация отсутствует'}}</p>
                              </div>
                              <div class="row pt-1" *ngIf="PartnerDetail.additionalInfoVm.address?.fullAddress">
                                <ng-container *ngFor="let metro of PartnerDetail?.additionalInfoVm?.address?.metro">
                                  <div class="ui-address__metro">
                                    <div class="grid align-items-start">
                                      <div class="col-auto mr-1">
                                        <div class="ui-address__metro-icon {{metro?.line | metroLineAlias}}"
                                          pTooltip="{{metro?.line}}"
                                          tooltipPosition="top"
                                          tooltipStyleClass="p-tooltip__input">
                                        </div>
                                      </div>
                                      <div class="col-auto">
                                        <p>{{metro?.name}} {{metro?.distanceKm | metroDistance}}</p>
                                      </div>
                                    </div>
                                  </div>
                                </ng-container>
                              </div>
                            </div>
                            <div class="col-4 col-sm-12" *ngIf="PartnerDetail.additionalInfoVm.address?.fullAddress"
                              ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'pt-2' : 'text-right'}}">

                              <p class="txt-link" (click)="onPartnerViewOnMap()">Показать на карте</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="partner-item__detail-table-tr">
                        <div class="partner-item__detail-table-td">
                          <p>График работы:</p>
                        </div>
                        <div class="partner-item__detail-table-td">
                          <div class="col-auto">
                            <div class="grid align-items-start">
                              <div class="col-auto">
                                <ng-container *ngFor="let weekday of onConvertWeekday()">
                                  <div class="grid align-items-center mb-1">
                                    <div class="col-auto">
                                      <p>{{weekday.day | formatWeekday}}<span *ngIf="weekday.endDay"> - {{weekday.endDay | formatWeekday}}</span>:</p>
                                    </div>
                                    <div class="col-auto pl-2 mr-1">
                                      <p class="txt-caption__large">{{onSplitWorkHours(weekday.fromIni)}}</p>
                                    </div>
                                    <div class="col-auto align-items-center text-center">
                                      <p class="txt-caption__large">—</p>
                                    </div>
                                    <div class="col-auto pr-2 ml-1">
                                      <p class="txt-caption__large">{{onSplitWorkHours(weekday.toIni)}}</p>
                                    </div>
                                  </div>
                                </ng-container>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="partner-item__detail-table-tr">
                        <div class="partner-item__detail-table-td">
                          <p class="__red">Скидка партнера:</p>
                        </div>
                        <div class="partner-item__detail-table-td">
                          <p class="__red">{{PartnerDetail?.discount ? PartnerDetail?.discount + '%' : 'Нет данных'}}</p>
                        </div>
                      </div>
                      <div class="partner-item__detail-table-tr" *ngIf="PartnerDetail?.siteUrl">
                        <div class="partner-item__detail-table-td">
                          <p>Ссылка на сайт:</p>
                        </div>
                        <div class="partner-item__detail-table-td">
                          <a href="{{PartnerDetail?.siteUrl}}" target="_blank">{{PartnerDetail?.siteUrl}}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="partner-item__detail-description__wrapper"
        ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-6' : 'mt-10'}}">

        <div class="partner-item__detail-description__row">
          <div class="grid align-items-start partner-item__detail-description__row__content"
            ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : ''}}">

            <div class="col-3 col-sm-12">
              <div class="partner-item__detail-description__row__heading">
                <div class="grid align-items-baseline"
                  ngClass="{{(deviceValue | async) === DTypes.Mobile ? '' : 'justify-content-center'}}">

                  <span class="mr-icon mr-icon-info"></span>
                  <h5 class="ml-2">Описание</h5>
                </div>
              </div>
            </div>
            <div class="col-8 col-sm-12">
              <div class="partner-item__detail-description__row__text">
                <p [innerHTML]="onPartnerAboutUsText()">
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="partner-item__detail-description__wrapper" *ngIf="onCheckAdditionalInfoVm()"
        ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-6' : 'mt-10'}}">

        <div class="partner-item__detail-description__row">
          <div class="grid align-items-start justify-content-center partner-item__detail-description__row__content"
            ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-wrap' : ''}}">

            <div class="col-2 col-sm-4 partner-item__detail-description__row__addition" *ngIf="PartnerDetail?.additionalInfoVm?.additionalAmenities?.tea">
              <div class="">
                <div class="addition__picture">
                  <img src="assets/images/icons/tea.svg">
                </div>
                <div class="addition__heading">
                  <p>Бесплатный чай или кофе</p>
                </div>
              </div>
            </div>

            <div class="col-2 col-sm-4 partner-item__detail-description__row__addition" *ngIf="PartnerDetail?.additionalInfoVm?.additionalAmenities?.conditioner">
              <div class="">
                <div class="addition__picture">
                  <img src="assets/images/icons/conditioner.svg">
                </div>
                <div class="addition__heading">
                  <p>Кондиционер</p>
                </div>
              </div>
            </div>

            <div class="col-2 col-sm-4 partner-item__detail-description__row__addition" *ngIf="PartnerDetail?.additionalInfoVm?.additionalAmenities?.instrumentsRent">
              <div class="">
                <div class="addition__picture">
                  <img src="assets/images/icons/rent.svg">
                </div>
                <div class="addition__heading">
                  <p>Аренда инструментов</p>
                </div>
              </div>
            </div>

            <div class="col-2 col-sm-4 partner-item__detail-description__row__addition" *ngIf="PartnerDetail?.additionalInfoVm?.additionalAmenities?.recording">
              <div class="">
                <div class="addition__picture">
                  <img src="assets/images/icons/record.svg">
                </div>
                <div class="addition__heading">
                  <p>Звукозапись</p>
                </div>
              </div>
            </div>

            <div class="col-2 col-sm-4 partner-item__detail-description__row__addition" *ngIf="PartnerDetail?.additionalInfoVm?.additionalAmenities?.toilet">
              <div class="">
                <div class="addition__picture">
                  <img src="assets/images/icons/toilet.svg">
                </div>
                <div class="addition__heading">
                  <p>Туалет</p>
                </div>
              </div>
            </div>

            <div class="col-2 col-sm-4 partner-item__detail-description__row__addition" *ngIf="PartnerDetail?.additionalInfoVm?.additionalAmenities?.wiFi">
              <div class="">
                <div class="addition__picture">
                  <img src="assets/images/icons/wifi.svg">
                </div>
                <div class="addition__heading">
                  <p>Бесплатный WiFi</p>
                </div>
              </div>
            </div>

            <div class="col-2 col-sm-4 partner-item__detail-description__row__addition" *ngIf="PartnerDetail?.additionalInfoVm?.additionalAmenities?.coffeeVendingMachine">
              <div class="">
                <div class="addition__picture">
                  <img src="assets/images/icons/coffee.svg">
                </div>
                <div class="addition__heading">
                  <p>Кофемашина</p>
                </div>
              </div>
            </div>

            <div class="col-2 col-sm-4 partner-item__detail-description__row__addition" *ngIf="PartnerDetail?.additionalInfoVm?.additionalAmenities?.smokingRoom">
              <div class="">
                <div class="addition__picture">
                  <img src="assets/images/icons/smoke.svg">
                </div>
                <div class="addition__heading">
                  <p>Комната для курения</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="partner-item__detail-description__wrapper"
        ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-6' : 'mt-10'}}">

        <div class="partner-item__detail-description__row no-border">
          <div class="grid align-items-start partner-item__detail-description__row__content"
            ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : ''}}">

            <div class="col-6 col-sm-12 border__col"
              ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-4 pb-4 mb-4' : ''}}">

              <div class="row text-center">
                <h5>Социальные сети</h5>
              </div>
              <div class="row text-center"
                ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'pt-2 pb-2' : 'pt-4 pb-4'}}">

                <div class="partner-item__detail-description__row__auth">
                  <div class="grid h-full align-items-center justify-content-center">
                    <div class="col-auto partner-item__detail-description__row__social-link"
                      ngClass="{{PartnerDetail.contacts.vkUsername ? '' : 'disabled'}}"
                      (click)="onRouteToSocialLinkURL('https://vk.com/', PartnerDetail.contacts.vkUsername)">
                        <span class="mr-icon mr-icon-vk-color">
                          <span class="path1"></span>
                          <span class="path2"></span>
                        </span>
                    </div>
                    <!--<div class="col-auto partner-item__detail-description__row__social-link"
                      ngClass="{{PartnerDetail.contacts.facebookUsername ? '' : 'disabled'}}"
                      (click)="onRouteToSocialLinkURL('https://facebook.com/', PartnerDetail.contacts.facebookUsername)">
                        <span class="mr-icon mr-icon-fb-color">
                          <span class="path1"></span>
                          <span class="path2"></span>
                        </span>
                    </div>-->
                    <div class="col-auto partner-item__detail-description__row__social-link"
                      ngClass="{{PartnerDetail.contacts.instagramUsername ? '' : 'disabled'}}"
                      (click)="onRouteToSocialLinkURL('https://instagram.com/', PartnerDetail.contacts.instagramUsername)">
                        <span class="mr-icon mr-icon-inst-color disabled">
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                          <span class="path4"></span>
                        </span>
                    </div>
                    <div class="col-auto partner-item__detail-description__row__social-link"
                      ngClass="{{PartnerDetail.contacts.hasViber ? '' : 'disabled'}}"
                      (click)="onRouteToMessengerLinkURL(PIMessenger.Viber, PartnerDetail.contacts.hasViber)">
                        <span class="mr-icon mr-icon-viber-color"
                          pTooltip="{{PartnerDetail.contacts.hasViber ? 'Нажмите, что бы начать общаться в Viber' : ''}}"
                          tooltipStyleClass="p-tooltip__input"
                          tooltipPosition="top">
                          <span class="path1"></span>
                          <span class="path2"></span>
                        </span>
                    </div>
                    <div class="col-auto partner-item__detail-description__row__social-link"
                      ngClass="{{PartnerDetail.contacts.hasWhatsapp ? '' : 'disabled'}}"
                      (click)="onRouteToMessengerLinkURL(PIMessenger.Whatsapp, PartnerDetail.contacts.hasWhatsapp)">
                        <span class="mr-icon mr-icon-wh-color"
                          pTooltip="{{PartnerDetail.contacts.hasWhatsapp ? 'Нажмите, что бы начать общаться в WhatsApp' : ''}}"
                          tooltipStyleClass="p-tooltip__input"
                          tooltipPosition="top">
                          <span class="path1"></span>
                          <span class="path2"></span>
                        </span>
                    </div>
                    <div class="col-auto partner-item__detail-description__row__social-link"
                      ngClass="{{PartnerDetail.contacts.hasTelegram ? '' : 'disabled'}}">
                        <span class="mr-icon mr-icon-telegram-color"
                          pTooltip="{{PartnerDetail.contacts.hasTelegram ? 'Номер телефона привязан к Telegram' : ''}}"
                          tooltipStyleClass="p-tooltip__input"
                          tooltipPosition="top">
                          <span class="path1"></span>
                          <span class="path2"></span>
                        </span>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div class="col-6 col-sm-12"
              ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-4 mb-4' : ''}}">

              <div class="row text-center">
                <h5>Контактная информация</h5>
              </div>
              <div class="row text-center"
                ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'pt-2 pb-2' : 'pt-4 pb-4'}}">

                <div class="col-6 col-sm-9 col-md-7 margin-auto">
                  <button class="ui-buttons ui-buttons__light-large" (click)="onShowPartnerPhone()">
                    Показать телефон
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      
    </div>
  </div>
</div>
