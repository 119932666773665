/**
 * Available routing URL's of customer.
 */
export const RoutingConfig = {
  /**
   * Root path.
   */
  Root: '',

  /**
   * Shell path.
   */
  ShellRoot: '',

  /**
   * Base pages
   */
  Login               : 'login',
  Bulletins           : 'bulletins',
  Market              : 'market',
  Calendar            : 'calendar',
  Loyalty             : 'loyalty',
  Account             : 'account',
  Partner             : 'partner',
  News                : 'news',
  Pages               : 'pages',
  Admin               : 'admin',
  Search              : 'search',
  Detail              : 'detail',
  Create              : 'create',
  Edit                : 'edit',
  Success             : 'success',

  /**
   * Login Routing
   */
  SignIn              : 'sign-in',
  SignUp              : 'sign-up',
  SignOAuth           : 'sign-oauth',
  SignRemind          : 'sign-remind',
  SignSuccess         : 'sign-success',

  /**
   * News Routing
   */
  NewsCreate          : 'create-news',
  NewsEdit            : 'edit-news',

  /**
   * Bulletins Routing
   */
  Artist              : 'artist',
  Band                : 'band',


  /**
   * Account Routing
   */
  AccountUser                 : 'user',
  AccountPartner              : 'partner',
  AccountBulletins            : 'bulletins',
  AccountFavorites            : 'favourites',
  AccountNotify               : 'notifications',
  AccountChangePass           : 'change-password',
  AccountRecoveryPass         : 'recovery-password',
  AccountConfirmEmail         : 'confirm',
  AccountResetPass            : 'reset-password',
  AccountPoints               : 'points',
  AccountProducts             : 'products',
  AccountDiscount             : 'discount',
  AccountSettings             : 'settings',
  AccountManagement           : 'management',
  AccountStatistics           : 'statistics',
  AccountQRScan               : 'qr-scan',

  /**
   * Partners Routing
   */
  PartnerSignUp               : 'partner/sign-up',
  PartnerSignSuccess          : 'partner/sign-success',
  PartnerSearchAll            : 'all',
  PartnerSearchBase           : 'base',
  PartnerSearchRecord         : 'record',
  PartnerSearchShop           : 'shop',
  PartnerSearchSchool         : 'school',
  PartnerSearchWorkshop       : 'workshop',


  /**
   * Other Routing
   */
  Announcement        : 'announcement',

  /**
   * Static Pages Routing
   */
  Confidential        : 'confidential',
  Rules               : 'rules',
  Contacts            : 'contacts',
  BecomePartner       : 'become-partner',
  Technical           : 'technical',
  Advertisement       : 'advertisement',
  CookiesContract     : 'cookies-contract',

  /**
   * Admin Routing
   */
  AdminUsers          : 'users',
  AdminBulletins      : 'bulletins',

  /**
   * Errors
   */
  PageErrors          : 'errors',
  Page404             : '404',
  PagePermission      : 'permission',
  PageDeleted         : 'deleted'
};
