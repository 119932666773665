import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'price'
})

export class PricePipe implements PipeTransform {
  constructor() {
  }

  transform(price: number): string {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }
}
