import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ProductItemComponent } from './product-item.component';

/**
 * Pipes
 */
import { PipesCoreModule } from '@app/core/pipes/pipes.module';

/**
 * Vendors
 */
import { ChipModule } from 'primeng/chip';
import { RippleModule } from 'primeng/ripple';
import { TooltipModule } from 'primeng/tooltip';
import { NgxCurrencyModule } from 'ngx-currency';

/**
 * Shared
 */
import { PhotosGalleryModule } from '@app/shared/photos-gallery/photos-gallery.module';

@NgModule({
  declarations: [
    ProductItemComponent
  ],
  exports: [
    ProductItemComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    PipesCoreModule,
    PipesCoreModule,
    ChipModule,
    RippleModule,
    TooltipModule,
    PhotosGalleryModule,
    NgxCurrencyModule
  ]
})

export class ProductItemModule {
  constructor() {}
}
