import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { ObjectToUrlParams } from '@app/utils/converter';
declare var $;

export interface PaginationValuesModel {
  ini?: number;
  index?: number;
  pages?: number;
  count?: number;
  loading?: boolean;
  size?: number;
  selector?: string;
}

@Injectable()

export class PaginationService {
  private pageIni: number = 0;
  private pageIndex: number = 0;
  private totalPages: number = 0;
  private totalCount: number = 0;
  private pageSize: number = 0;
  private pageSelector: string = '';
  private pageLoading: boolean = false;
  private pageRequest: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private pageURL: string = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location
  ) {}

  setPaginationEvents(): void {
    this.onHandleScroll();
  }

  setPaginationValues(value: PaginationValuesModel): void {
    if (value.hasOwnProperty('ini')) {
      this.pageIni = value.ini;
    }
    if (value.hasOwnProperty('index')) {
      this.pageIndex = value.index;
    }

    if (value.hasOwnProperty('pages')) {
      this.totalPages = value.pages;
    }

    if (value.hasOwnProperty('count')) {
      this.totalCount = value.count;
    }

    if (value.hasOwnProperty('loading')) {
      this.pageLoading = value.loading;
    }

    if (value.hasOwnProperty('size')) {
      this.pageSize = value.size;
    }

    if (value.hasOwnProperty('selector')) {
      this.pageSelector = value.selector;
    }
  }

  setPaginationURL(URL: string): void {
    this.pageURL = URL;
  }

  getPaginationValue(): PaginationValuesModel {
    return {
      index: this.pageIndex,
      pages: this.totalPages,
      count: this.totalCount,
      loading: this.pageLoading,
      size: this.pageSize,
      selector: this.pageSelector
    }
  }

  getPaginationIniURL(): number {
    const ParamsURL = this.route.queryParams['getValue']();

    for (const type of Object.keys(ParamsURL)) {
      if (type === 'page') {
        return Number(ParamsURL[type]);
      }
    }
    return 1;
  }

  onHandleScroll(): void {
    const PgLayer: HTMLElement = document.querySelector('.pg-layer') || null;
    const PgFooter: HTMLElement = document.querySelector('.pg-footer') || null;

    window.onscroll = () =>  {
      if (PgLayer && PgFooter) {
        if ((window.innerHeight + window.scrollY) >= (PgLayer.offsetHeight - PgFooter.offsetHeight)) {
          this.onDetectUpload();
        }
      }
    };
  }

  onSetParamsURL(key: string, value: number, reload?: boolean): void {
    const urlTree = this.router.createUrlTree([], {
      queryParams: {
        [key]: value
      },
      queryParamsHandling: 'merge',
      preserveFragment: true,
      skipLocationChange: false,
      replaceUrl: false
    });
    const urlTreeParams = ObjectToUrlParams(urlTree.queryParams);

    if (reload || value < this.pageIni) {
      this.router.navigateByUrl(urlTree).then(
        e => location.reload()
      ).catch();
      return;
    }

    this.location.replaceState(this.pageURL, urlTreeParams);
  }

  onSetPagerURL(index: number): void {
    const selectedHTML: HTMLCollection = document.getElementsByTagName(this.pageSelector) || null;
    const selectedCount: number = (
      Math.floor(selectedHTML.length / this.pageSize) - (this.onPaginationUpdate().getValue() - index)
    ) * this.pageSize;

    if (selectedHTML && selectedHTML.length) {
      const $el = selectedHTML[selectedCount <= selectedHTML.length ? selectedCount : 0] as HTMLElement;

      if (this.onPaginationUpdate().getValue() === 0 || !$el) {
        return this.onSetParamsURL('page', index, true);
      }
      this.onSetParamsURL('page', index);

      $('html, body').animate({
        scrollTop: $el.offsetTop || 0
      }, 500);
    }
  }

  onDetectUpload(): void {
    if (this.pageLoading || this.pageIndex + 1 > this.totalPages) {
      return;
    }
    this.pageRequest.next(this.pageIndex + 1);
  }

  onPaginationUpdate(): BehaviorSubject<number> {
    return this.pageRequest;
  }

  offPaginationUpdate(): void {
    this.pageRequest.next(0);
  }
}
