import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, Input, OnInit } from '@angular/core';
import { DevicesClass } from '@app/core/classes';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DialogComponentModel } from '@app/core/models/diallog';
import { IPartnerAddress } from '@app/modules/partner-modules/partner.model';
import { MetroDistancePipe } from '@app/core/pipes/address/metro-distance.pipe';
import { MetroLineAliasPipe } from '@app/core/pipes/address/metro-lines.pipe';

declare var ymaps;

@UntilDestroy()
@Component({
  selector: 'app-map-address',
  templateUrl: 'map-address.component.html',
  styleUrls: [`./map-address.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class MapAddressComponent extends DevicesClass implements OnInit {
  public Address: IPartnerAddress;
  public YMap: any;

  @Input() Data: any;

  constructor(injector: Injector,
    public cdr: ChangeDetectorRef,
    public ref: DynamicDialogRef,
    private router: Router,
    private dialogConfig: DynamicDialogConfig,
    private metroDistancePipe: MetroDistancePipe,
    private metroLineAliasPipe: MetroLineAliasPipe
  ) {
    super(injector);
  }

  ngOnInit(): void {
    /**
     * Set of base popup settings.
     */
    this.onAddressInputConfig();

    /**
     * Initialize of YMap.
     */
    this.onInitYMap();

    console.log(this.Address);
  }

  onAddressInputConfig(): void {
    const InputConfig = this.dialogConfig.data as DialogComponentModel || null;
    this.Address = InputConfig.includeData as any || null;
  }

  onInitYMap(): void {
    const initYMapPlacemark = () => {
      this.YMap = new ymaps.Map('mapFullAddress', {
        center: [this.Address.latitude, this.Address.longitude],
        zoom: 15,
        controls: ['zoomControl']
      }, {
        searchControlProvider: 'yandex#search'
      });

      const mapMetroPoints = this.Address.metro.map(item => {
        return `
          <div class="ui-address__metro">
            <div class="grid align-items-start">
              <div class="col-auto mr-1">
                <div class="ui-address__metro-icon ${this.metroLineAliasPipe.transform(item?.line)}"></div>
              </div>
              <div class="col-auto">
                <p>${item?.name} ${this.metroDistancePipe.transform(item?.distanceKm)}</p>
              </div>
            </div>
          </div>
        `
      }).join('');

      const basePlacemark = new ymaps.Placemark([this.Address.latitude, this.Address.longitude], {
        hintContent: this.dialogConfig.header,
        balloonContent: `
          <h5>${this.dialogConfig.header}</h5>
          <p class="mt-2">${this.Address.fullAddress}</p>
          <div class="mt-4">${mapMetroPoints}</div>
        `,
      }, {
        iconLayout: 'default#image',
        iconImageHref: 'assets/images/ymaps/ymap-marker.png',
        iconImageSize: [40, 40],
        iconImageOffset: [-20, -20],
        hideIconOnBalloonOpen: false,
      });

      this.YMap.geoObjects.add(basePlacemark);

      basePlacemark.balloon.open();
    };

    ymaps.ready(initYMapPlacemark);
  }

  onCloseDialog(): void {
    this.ref.close()
  }
}
