import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DevicesClass } from '@app/core/classes';
import { PartnerService } from '@app/modules/partner-modules/partner.service';
import { IPartnerSaleLists } from '@app/modules/partner-modules/partner.model';

@UntilDestroy()
@Component({
  selector: 'app-account-statistics',
  templateUrl: 'statistics.component.html',
  styleUrls: [`./statistics.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class StatisticsComponent extends DevicesClass implements OnInit {
  /**
   * Base component variables.
   */
  public StatisticsPartnerLists: IPartnerSaleLists;

  constructor(injector: Injector,
    public cdr: ChangeDetectorRef,
    private partnerService: PartnerService,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.onGetPartnerSales(1);
  }

  onGetPartnerSales(page: number): void {
    this.partnerService.partnerSales(page, 1000, 0).subscribe(
      response => {
        this.StatisticsPartnerLists = response;
        this.cdr.markForCheck();
      }
    );
  }

  onPageChange(event: number): void {
    this.onGetPartnerSales(event);
  }
}
