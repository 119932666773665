<div class="account-menu" *ngIf="Device === DTypes.Desktop">
  <div class="pg-wrapper"
    ngClass="{{(deviceValue | async) === DTypes.Tablet || (deviceValue | async) === DTypes.Mobile ? 'pl-0 pr-0' : ''}}">

    <div class="grid align-items-center ui-scroll-horizontal sm-scrollbar __js__account-menu"
       ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-nowrap justify-content-start' : 'justify-content-center'}}">

      <ng-container *ngFor="let menuItem of onAccountMenuData">
        <div class="account-menu__link"
           ngClass="{{onViewLinkActive(menuItem.url) ? 'active' : ''}} {{menuItem.disabled ? 'disabled' : ''}}">

          <a (click)="onRouteLink($event, menuItem.url, menuItem.disabled)">
            {{menuItem.title}}
          </a>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<div class="account-menu-mobile" *ngIf="Device === DTypes.Mobile">
  <div class="account-menu-mobile__initial" *ngIf="!AccountMenuUser">
    <h3 (click)="onSignInURL($event)">Вход</h3>
    <p>или</p>
    <h3 (click)="onSignUpURL($event)">Регистрация</h3>
  </div>

  <div class="account-menu-mobile__user" *ngIf="AccountMenuUser">
    <div class="grid align-items-center">
      <div class="col-auto p-relative">
        <p-badge severity="danger" *ngIf="AccountMenuNotify > 0"
          [value]="AccountMenuNotify"
          [styleClass]="'p-badge__notify'">
        </p-badge>

        <div class="account-menu-mobile__avatar" *ngIf="AccountMenuUser.picPath">
          <img src="{{StaticURL}}{{AccountMenuUser.picPath}}" (error)="AccountMenuUser.picPath = ''">
        </div>

        <span class="mr-icon mr-icon-user"*ngIf="!AccountMenuUser.picPath">
          <span class="path1"></span>
          <span class="path2"></span>
        </span>
      </div>
      <div class="col-auto">
        <p>{{AccountMenuUser.username}}</p>
      </div>
    </div>
  </div>

  <div class="account-menu-mobile__menu" *ngIf="AccountMenuUser">
    <ng-container *ngFor="let menuItem of onAccountMenuData">
      <div class="account-menu-mobile__link" ngClass="{{onViewLinkActive(menuItem.url) ? 'active' : ''}}">
        <a (click)="onRouteLink($event, menuItem.url, menuItem.disabled)">
          {{menuItem.title}}
        </a>
      </div>
    </ng-container>

    <div class="account-menu-mobile__button" (click)="onAccountLogOut()">
      <div class="grid align-items-center justify-content-center">
        <span class="pi pi-fw pi-power-off"></span>
        <span class="text">Выход</span>
      </div>
    </div>
  </div>

  <div class="account-menu-mobile__logo">
    <div class="ui-logo__footer"></div>
  </div>
</div>
