import { AppApiURL, AppDomainURL } from '@app/app.settings';
import { RoutingConfig } from '@app/routing/routing.config';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { TokenService, RolesService } from '@app/core/services';
import { AccountStoryService } from '@app/modules/account-modules/account.story';
import { CitiesStoryService } from '@app/modules/cities-module/cities.story';
import { HttpRequestToken, HttpRequestFile } from '@app/core/classes';
import { Observable, throwError } from 'rxjs';
import { map, take, timeout, catchError } from 'rxjs/operators';
import {
  IAccountUserInfo,
  AccountForgotPasswordRequest,
  IRecovertPassword,
  IChangePassword,
  IAccountSettings
} from './account.model';

@Injectable()

export class AccountService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private accountStory: AccountStoryService,
    private citiesStory: CitiesStoryService,
    private tokenService: TokenService,
    private rolesService: RolesService,
  ) {}


  /**
   * Api: v01.
   * Method: Account avatar
   * @params formData
   */
  public uploadAvatar(files: FileList): Observable<any> {
    const formData = new FormData();
    formData.append('file', files[0]);

    return this.http.post<any>(AppApiURL + '/account/avatar', formData, {
      params: new HttpRequestFile(true)
    }).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error.error || 'Не удалось загрузить новый аватар!');
      })
    );
  }

  /**
   * Api: v01.
   * Method: Delete account avatar
   */
  public deleteAvatar(): Observable<any> {
    return this.http.delete<any>(AppApiURL + '/account/avatar', {
      params: new HttpRequestFile(true)
    }).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error.error || 'Не удалось удалить аватар!');
      })
    );
  }


  /**
   * Api: v01.
   * Method: Account ForgotPassword
   * @param request
   */
  public forgotPassword(request: AccountForgotPasswordRequest): Observable<any> {
    const returnPath = `?confirmEmailPath=${AppDomainURL}/${RoutingConfig.Account}/${RoutingConfig.AccountRecoveryPass}`;

    return this.http.post<any>(AppApiURL + `/account/forgotPassword${returnPath}`, request, {
      params: new HttpRequestToken(true)
    }).pipe(
      map((response: HttpResponse<any>) => response ? response.body : null),
      catchError((error: HttpErrorResponse) => {
        /**
         * Base error response.
         */
        if (!error.hasOwnProperty('status')) {
          return throwError(`Не удалось восстановить пароль!`);
        }

        /**
         * Error response by status code.
         */
        if (error.status === 500) {
          return throwError(`Не удалось восстановить пароль, так как сервис временно недоступен!`);
        }

        return throwError(error);
      })
    );
  }


  /**
   * Api: v01.
   * Method: Account ResetPassword
   * @param request
   */
  public resetPassword(request: IRecovertPassword): Observable<IRecovertPassword> {
    return this.http.post<IRecovertPassword>(AppApiURL + '/account/resetPassword',
      request,
      {
        withCredentials: true,
        observe: 'response',
        reportProgress: false
      }
    ).pipe(
      map((response: HttpResponse<any>) => response.body),
      catchError(error => {
        return throwError(error);
      })
    );
  }


  /**
   * Api: v01.
   * Method: Account ChangePassword
   * @param request
   */
  public changePassword(request: IChangePassword): Observable<IChangePassword> {
    return this.http.post<IChangePassword>(AppApiURL + '/account/changePassword',
      request,
      {
        withCredentials: true,
        observe: 'response',
        reportProgress: false
      }
    ).pipe(
      map((response: HttpResponse<any>) => response.body),
      catchError((error: HttpErrorResponse) => {
        /**
         * 500 error response
         */
        if (error.status === 500) {
          return throwError(`Ошибка! ` + JSON.stringify(error.error));
        }

        /**
         * Base error response.
         */
        if (error.hasOwnProperty('error')) {
          return throwError(error.error['message']);
        }

        /**
         * Default error response.
         */
        return throwError(error);
      })
    );
  }


  /**
   * Api: v01.
   * Method: Account ResendEmail
   */
  public resendEmail(): Observable<IAccountUserInfo> {
    const returnPath = `?confirmEmailPath=${AppDomainURL}/${RoutingConfig.Account}/${RoutingConfig.AccountConfirmEmail}`;

    return this.http.post<IAccountUserInfo>(AppApiURL + `/account/resendEmailConfirmationCode${returnPath}`,
      null,
      {
        withCredentials: true,
        observe: 'response',
        reportProgress: false
      }
    ).pipe(
      map((response: HttpResponse<any>) => response.body),
      catchError(error => {
        return throwError(error);
      })
    );
  }


  /**
   * Api: v01.
   * Method: get Account getMyInfo
   */
  public getMyInfo(): Observable<IAccountUserInfo> {
    return this.http.get<IAccountUserInfo>(AppApiURL + '/account/userInfo').pipe(
      catchError((error: HttpErrorResponse) => {
        /**
         * Base error response.
         */
        if (!error.hasOwnProperty('status')) {
          return throwError(`Не удалось получить данные профиля!`);
        }

        /**
         * Error response by status code.
         */
        if (error.status === 400 || error.status === 401) {
          return throwError(`Не удалось получить данные профиля!`);
        }
        if (error.status === 403) {
          return throwError(`Ваш профиль заблокирован!`);
        }

        return throwError(error);
      })
    );
  }

  /**
   * Api: v01.
   * Method: get Account getMyInfo
   */
  public updateMyInfo(request: IAccountUserInfo): Observable<IAccountUserInfo> {
    const returnPath = `?confirmEmailPath=${AppDomainURL}/${RoutingConfig.Account}/${RoutingConfig.AccountConfirmEmail}`;

    return this.http.put<IAccountUserInfo>(AppApiURL + `/account/userInfo${returnPath}`, request).pipe(
      catchError((error: HttpErrorResponse) => {
        /**
         * Base error response.
         */
        if (!error.hasOwnProperty('status')) {
          return throwError(`Не удалось обновить данные профиля!`);
        }

        /**
         * Error response by status code.
         */
        if (error.status === 400 || error.status === 401) {
          return throwError(`Не удалось получить данные профиля!`);
        }
        if (error.status === 403) {
          return throwError(`Ваш профиль заблокирован!`);
        }

        return throwError(error);
      })
    );
  }

  /**
   * Api: v01.
   * Method: Account getMyInfo.
   * Getting of AccountInfo on page initialize.
   */
  public onIniGetMyInfo(): void {
    if (!this.tokenService.accessToken) {
      return this.accountStory.AccountEventBus.next({ event: 'onAccountEmpty' });
    }
    this.getMyInfo().subscribe(
      response => {
        this.onSetUserRole(response);

        this.accountStory.AccountEventBus.next({
          event: 'onAccountUpdate',
          data: response
        });
        this.citiesStory.CitiesEventBus.next({
          event: 'onUpdateCityApp',
          cityId: response.cityId
        })
      },
      error => this.accountStory.AccountEventBus.next({ event: 'onAccountEmpty' })
    );
  }


  /**
   * Api: v01.
   * Method: Account Username
   */
  public username(searchText: string): Observable<any> {
    return this.http.get(AppApiURL + '/account/' + searchText, {
      params: new HttpRequestToken(true)
    }).pipe(
      take(1),
      map(response => response),
      catchError((error: HttpErrorResponse) => {
        /**
         * Error response by status code.
         */
        return throwError(error.status);
      })
    );
  }

  /**
   * Api: v01.
   * Method: UserSettings GetNotification
   */
  public getSettingsNotification(): Observable<IAccountSettings> {
    return this.http.get<IAccountSettings>(AppApiURL + '/settings/notification').pipe(
      map(response => response),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  /**
   * Api: v01.
   * Method: UserSettings GetNotification
   * @param request
   */
  public saveSettingsNotification(request: IAccountSettings): Observable<any> {
    return this.http.put<any>(AppApiURL + '/settings/notification', request).pipe(
      map(response => response),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  /**
   * Api: v01.
   * Method: UserSettings getDeepLinkIdentifier
   * @param messengerType
   * telegram - 1, vk - 0
   */
  public getDeepLinkIdentifier(messengerType: number): Observable<any> {
    return this.http.get<string>(AppApiURL + `/settings/${messengerType}/getDeeplinkIdentifier`,
      <any>{
        observe: 'body',
        responseType: 'text'
      }
    ).pipe(
      map(response => response),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  /**
   * Api: v01.
   * Method: UserSettings unsubscribeNotification
   * @param messengerType
   * telegram - 1, vk - 0
   */
  public unsubscribeNotification(messengerType: number): Observable<any> {
    return this.http.put<any>(AppApiURL + '/settings/messengers/unsubscribe', {
      type: messengerType
    }).pipe(
      map(response => response),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }


  /**
   * Set of current UserRole.
   * @param response
   */
  public onSetUserRole(response: IAccountUserInfo): void {
    this.rolesService.onRoles(response.roles, true);
  }
}
