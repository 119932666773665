import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { RoutingConfig } from '@app/routing/routing.config';
import { TokenService } from '@app/core/services/token/token.service';
import { RolesService } from '@app/core/services/roles/roles.service';
import { IAccountUserTypes } from '@app/modules/account-modules/account.model';

@Injectable()

export class GuardAccountService implements CanActivate {
  private GuardRole: Array<IAccountUserTypes> = [];

  constructor(
    private router: Router,
    private token: TokenService,
    private rolesService: RolesService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    this.GuardRole = route.data.ROLES;

    /**
     * Checking User Tokens.
     */
    if (!!this.token.accessToken && !!this.token.refreshToken) {
      return true;
    }

    /**
     * Checking User Roles
     */
    if (!this.onGetActivateRoles()) {
      this.onRedirectToPermission(route);
      return true;
    }



    this.onRedirectToAuthorisation(route);
  }

  /**
   * Checking of current User Role from storage.
   */
  onGetActivateRoles(): boolean {
    return this.rolesService.roles.some(role => this.GuardRole.some(r => r === role));
  }


  /**
   * Redirect to authorisation page with saving path tree.
   * @param route
   */
  onRedirectToAuthorisation(route: ActivatedRouteSnapshot): void {
    const redirectUrl = route['_routerState']['url'];

    /**
     * Redirect to selected URL.
     */
    this.router.navigateByUrl(
      this.router.createUrlTree(
        [RoutingConfig.Login + '/' + RoutingConfig.SignIn], {
          queryParams: {
            redirectUrl
          }
        }
      )
    ).then();
  }


  /**
   * Redirect to permission page with saving path tree.
   * @param route
   */
  onRedirectToPermission(route: ActivatedRouteSnapshot): void {
    const redirectUrl = route['_routerState']['url'];

    /**
     * Redirect to selected URL.
     */
    this.router.navigateByUrl(
      this.router.createUrlTree(
        [RoutingConfig.PageErrors + '/' + RoutingConfig.PagePermission], {
          queryParams: {
            redirectUrl
          }
        }
      )
    ).then();
  }
}
