import { AfterViewInit, ChangeDetectorRef, Component, Injector, OnInit, Input } from '@angular/core';
import { DevicesClass } from '@app/core/classes/component/devices.class';
import { GalleryDataModel } from '@app/core/models/gallery';
declare var $;

@Component({
  selector: 'shared-photos-gallery',
  templateUrl: 'photos-gallery.component.html',
  styleUrls: [`./photos-gallery.component.scss`],
})

export class PhotosGalleryComponent extends DevicesClass implements OnInit, AfterViewInit {
  public GalleryLoading: boolean = true;

  @Input() PartnersGalleryData: GalleryDataModel;

  constructor(injector: Injector,
    public cdr: ChangeDetectorRef
  ) {
    super(injector);
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    /**
     * Hook of Slick plugin init.
     */
    setTimeout(() => this.onPartnersGalleryFn(), 100);

    /**
     * Hook of Slick view init.
     */
    setTimeout(() => this.onToggleLoading(false), 2000);
  }

  onToggleLoading(status: boolean, delay: number = 0) {
    setTimeout(() => {
      this.GalleryLoading = status;
      this.cdr.detectChanges();
    }, delay);
  }

  onPartnersGalleryFn(): void {
    if (typeof $ === 'function') {
      try {
        $('.photos-gallery__slide').slick({
          autoplay: true,
          autoplaySpeed: 5000,
          infinite: true,
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '12%',
          responsive: [
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerPadding: '8%',
              }
            }
          ]
        });
      } catch (e) {}
    }
  }
}
