import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpRequestToken } from '@app/core/classes';
import { IAddressSuggestResponse } from './address.model';

@Injectable()

export class AddressService {
  public dadataToken = '45c2383d1cf01a535daa47d3b419b3936f40dfcb';

  constructor(
    private http: HttpClient,
    private router: Router,
  ) {}

  /**
   * Api: v01.
   * Method:  Get address suggest.
   * @param address
   */
  public getAddressSugges(address: string): Observable<IAddressSuggestResponse> {
    return this.http.post<any>('https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address', {query: address},
      {
        params: new HttpRequestToken(true),
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'Token ' + this.dadataToken
        }
      }).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }
}
