import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccountRoutes } from './account.routes';
import { AccountService } from '@app/modules/account-modules/account.service';
import { BulletinsService } from '@app/modules/bulletins-modules/bulletins.service';

/**
 * Components
 */
import { AccountShellComponent } from './account-shell/account-shell.component';
import { BulletinsComponent } from './bulletins/bulletins.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ConfirmEmailComponent } from './confirm-email/confirm-email.component';
import { DiscountComponent } from './discount/discount.component';
import { FavoritesComponent } from './favorites/favorites.component';
import { NotificationComponent } from './notification/notification.component';
import { PointsComponent } from './points/points.component';
import { ProductsComponent } from './products/products.component';
import { RecoveryPasswordComponent } from './recovery-password/recovery-password.component';
import { ProfilePartnerComponent } from './profile-partner/profile-partner.component';
import { ProfileUserComponent } from './profile-user/profile-user.component';
import { QrScanComponent } from './qr-scan/qr-scan.component';
import { SettingsComponent } from './settings/settings.component';
import { ManagementComponent } from './management/management.component';
import { StatisticsComponent } from './statistics/statistics.component';

/**
 * Shared
 */
import { BreadcrumbsModule } from '@app/shared/breadcrumbs/breadcrumbs.module';
import { AccountMenuModule } from '@app/shared/account-menu/account-menu.module';
import { BulletinItemModule } from '@app/shared/bulletin-item/bulletin-item.module';
import { NotificationItemModule } from '@app/shared/notification-item/notification-item.module';
import { NewsItemModule } from '@app/shared/news-item/news-item.module';
import { PartnerItemModule } from '@app/shared/partner-item/partner-item.module';
import { SkeletonItemModule } from '@app/shared/skeleton-item/skeleton-item.module';
import { ProductItemModule } from '@app/shared/product-item/product-item.module';

/**
 * Vendors
 */
import { DialogService } from 'primeng/dynamicdialog';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { DropdownModule } from 'primeng/dropdown';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { TooltipModule } from 'primeng/tooltip';
import { ChipModule } from 'primeng/chip';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE, DateAdapter } from '@angular/material/core';
import { NgxMaskModule } from 'ngx-mask';
import { NgxPaginationModule } from 'ngx-pagination';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { InputMaskModule } from 'primeng/inputmask';
import { TableModule } from 'primeng/table';
import { NgOtpInputModule } from  'ng-otp-input';
import { NgxCurrencyModule } from 'ngx-currency';
import { JpTimeMaskModule } from '@julianobrasil/timemask';

/**
 * Adapters
 */
import { DatepickerAdapter } from '@app/core/adapters';

/**
 * Validators
 */
import { InputValidators } from '@app/core/validators/input.validator';

/**
 * Services
 */
import { LoyaltyService } from '@app/modules/loyalty-modules/loyalty.service';
import { PartnerService } from '@app/modules/partner-modules/partner.service';

/**
 * Pipes
 */
import { PipesCoreModule } from '@app/core/pipes/pipes.module';

/**
 * Directives
 */
import { TimeMaskModule } from '@app/core/directives';

@NgModule({
  declarations: [
    AccountShellComponent,
    BulletinsComponent,
    ChangePasswordComponent,
    ConfirmEmailComponent,
    DiscountComponent,
    FavoritesComponent,
    NotificationComponent,
    PointsComponent,
    ProductsComponent,
    RecoveryPasswordComponent,
    ProfileUserComponent,
    ProfilePartnerComponent,
    QrScanComponent,
    SettingsComponent,
    ManagementComponent,
    StatisticsComponent
  ],
  exports: [
    AccountShellComponent,
    BulletinsComponent,
    ChangePasswordComponent,
    ConfirmEmailComponent,
    DiscountComponent,
    FavoritesComponent,
    NotificationComponent,
    PointsComponent,
    ProductsComponent,
    RecoveryPasswordComponent,
    ProfileUserComponent,
    ProfilePartnerComponent,
    QrScanComponent,
    SettingsComponent,
    ManagementComponent,
    StatisticsComponent
  ],
  imports: [
    RouterModule.forChild(AccountRoutes),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BreadcrumbsModule,
    AccountMenuModule,
    BulletinItemModule,
    NewsItemModule,
    PartnerItemModule,
    SkeletonItemModule,
    ProductItemModule,
    NotificationItemModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxMaskModule.forRoot(),
    NgxPaginationModule,
    DropdownModule,
    MatFormFieldModule,
    MatSelectModule,
    TooltipModule,
    ChipModule,
    ToastModule,
    ConfirmDialogModule,
    InputMaskModule,
    TableModule,
    PipesCoreModule,
    NgOtpInputModule,
    NgxCurrencyModule,
    JpTimeMaskModule,
    TimeMaskModule
  ],
  providers: [
    DialogService,
    DynamicDialogRef,
    DynamicDialogConfig,
    MessageService,
    AccountService,
    BulletinsService,
    ConfirmationService,
    InputValidators,
    {provide: MAT_DATE_LOCALE, useValue: 'ru-RU'},
    {provide: DateAdapter, useClass: DatepickerAdapter},
    LoyaltyService,
    PartnerService
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})

export class AccountModule {}
