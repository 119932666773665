import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DevicesClass } from '@app/core/classes';
import { AccountService } from '@app/modules/account-modules/account.service';
import { AuthService } from '@app/modules/auth-modules/auth.service';
import { DialogIncludesTypes } from '@app/core/models/diallog';
import { NotificationService } from '@app/modules/notification-modules/notification.service';
declare var $;

export interface ChangePassErrorModels {
  oldPassword: boolean;
  newPassword: boolean;
  newPasswordConfirm: boolean;
}

@UntilDestroy()
@Component({
  selector: 'app-account-change-password',
  templateUrl: 'change-password.component.html',
  styleUrls: [`./change-password.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ChangePasswordComponent extends DevicesClass implements OnInit {
  /**
   * profile variables.
   */
  public ChangePassForm: FormGroup;
  public ChangePassErrorsField: ChangePassErrorModels;
  public ChangePassErrorsText: string;
  public ChangePassLoading: boolean;
  public ChangePassModify: boolean;

  @ViewChild('currentPasswordInput', {static: false}) currentPasswordInput;
  @ViewChild('newPasswordInput', {static: false}) newPasswordInput;
  @ViewChild('repeatPasswordInput', {static: false}) repeatPasswordInput;

  constructor(injector: Injector,
    public cdr: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    private authService: AuthService,
    private notificationService: NotificationService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    /**
     * Build of change password form.
     */
    this.ChangePassForm = this.formBuilder.group({
      oldPassword : ['', Validators.required],
      newPassword : ['', Validators.required],
      newPasswordConfirm : ['', Validators.required]
    });

    /**
     * Set of default errors value.
     */
    this.ChangePassErrorsField = {
      oldPassword: false,
      newPassword: false,
      newPasswordConfirm: false,
    };

    /**
     * Form changes control.
     */
    this.onChangePassFormCtrl();
  }

  onChangePassFormCtrl(): void {
    this.ChangePassForm.valueChanges.pipe(untilDestroyed(this)).subscribe(
      value => {
        for (const key of Object.keys(this.ChangePassErrorsField)) {
          this.ChangePassErrorsField[key] = false;
          this.ChangePassErrorsText = '';
          this.ChangePassModify = true;

          this.onToggleViewErrors(false);
        }
      }
    );
  }

  onChangePassLoading(status: boolean, delay: number = 0) {
    setTimeout(() => {
      this.ChangePassLoading = status;
      this.cdr.detectChanges();
    }, delay);
  }

  isVisiblePassword(type: string): boolean {
    if (this[type + 'PasswordInput']) {
      return this[type + 'PasswordInput'].nativeElement.type === 'text';
    } else {
      return false;
    }
  }

  onToggleDisplayPassword(type: string): void {
    if (this[type + 'PasswordInput']) {
      const Password = this[type + 'PasswordInput'].nativeElement;

      Password.type === 'password' ? Password.type = 'text' : Password.type = 'password';
    }
  }

  onToggleViewErrors(show: boolean): void {
    const FieldErrors = $('.__change-password__errors');
    show ? FieldErrors.slideDown(150) : FieldErrors.slideUp(150);
  }

  onRemindPopup($event: Event): void {
    $event.preventDefault();
    this.authService.onAuthRemindPopup();
  }

  onCoincidencePassword(): boolean {
    return this.ChangePassForm.controls.newPassword.value === this.ChangePassForm.controls.newPasswordConfirm.value;
  }

  onResetFormValue(): void {
    this.ChangePassForm.controls.oldPassword.patchValue('', { emitEvent: false });
    this.ChangePassForm.controls.newPassword.patchValue('', { emitEvent: false });
    this.ChangePassForm.controls.newPasswordConfirm.patchValue('', { emitEvent: false });
  }

  onChangePassword(): void {
    if (!this.ChangePassModify || this.ChangePassLoading) {
      return;
    }
    this.onChangePassLoading(true);

    /**
     * Initial validation.
     */
    if (!this.ChangePassForm.valid) {
      for (const key of Object.keys(this.ChangePassForm.controls)) {
        if (this.ChangePassErrorsField.hasOwnProperty(key)) {
          this.ChangePassErrorsField[key] = !this.ChangePassForm.controls[key].valid;
        }
      }
      /**
       * Hide loading.
       */
      this.onChangePassLoading(false);

      /**
       * View errors description.
       */
      this.ChangePassErrorsText = 'Обязательные поля не могут быть пустыми!';
      return this.onToggleViewErrors(true);
    }

    /**
     * Coincidence password.
     */
    if (!this.onCoincidencePassword()) {
      /**
       * Hide loading.
       */
      this.onChangePassLoading(false);

      this.ChangePassErrorsField.newPassword = true;
      this.ChangePassErrorsField.newPasswordConfirm = true;

      /**
       * View errors description.
       */
      this.ChangePassErrorsText = 'Пароли должны совпадать!';
      return this.onToggleViewErrors(true);
    }

    /**
     * Change password request.
     */
    this.accountService.changePassword(this.ChangePassForm.getRawValue()).subscribe(
      r => {
        this.onChangePassLoading(false);

        this.notificationService.onDialog({
          type: DialogIncludesTypes.PopupSuccessTemplate,
          header: 'Изменение пароля',
          content: 'Ваш пароль успешно обновлен.'
        });

        this.onResetFormValue();
      },
      error => {
        this.onChangePassLoading(false);

        this.notificationService.onDialog({
          type: DialogIncludesTypes.PopupErrorTemplate,
          header: 'Ошибка',
          content: error ? error : 'Не удалось обновить Ваш пароль.'
        });
      }
    )
  }
}
