import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DevicesClass } from '@app/core/classes';
import { ReplaySubject } from 'rxjs';
import { IPartnerProfile } from '@app/modules/partner-modules/partner.model';
import { PartnerService } from '@app/modules/partner-modules/partner.service';

@UntilDestroy()
@Component({
  selector: 'app-account-management',
  templateUrl: 'management.component.html',
  styleUrls: [`./management.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ManagementComponent extends DevicesClass implements OnInit {
  /**
   * Base component variables.
   */
  public ManagementLoading: boolean;
  public ManagementPartnerSubject: ReplaySubject<IPartnerProfile> = new ReplaySubject<IPartnerProfile>(1);


  constructor(injector: Injector,
    public cdr: ChangeDetectorRef,
    private partnerService: PartnerService,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.getPartnerProfile();
  }

  getPartnerProfile(): void {
    this.partnerService.partnerProfile().subscribe(
      response => {
        this.ManagementPartnerSubject.next(response);
        this.onToggleLoading(false, 500);
      }
    )
  }

  onToggleLoading(status: boolean, delay: number = 0) {
    setTimeout(() => {
      this.ManagementLoading = status;
      this.cdr.detectChanges();
    }, delay);
  }

  onSaveManagement(isEnabled: boolean): void {
    this.onToggleLoading(true);

    this.partnerService.partnerPublish(!isEnabled).subscribe(
      response => this.getPartnerProfile()
    );
  }
}
