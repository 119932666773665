import { Routes } from '@angular/router';
import { RoutingConfig } from '@app/routing/routing.config';
import {
  BulletinsSeoParams,
  ChangePasswordSeoParams,
  ConfirmEmailSeoParams,
  DiscountSeoParams,
  FavoritesSeoParams,
  NotificationSeoParams,
  PointsSeoParams,
  ProductsSeoParams,
  RecoveryPassSeoParams,
  ProfileSeoParams,
  QRScanSeoParams,
  SettingsSeoParams,
  StatisticsSeoParams
} from '@app/modules/account-modules/account.seo';

/**
 * Components
 */
import { AccountShellComponent } from './account-shell/account-shell.component';
import { BulletinsComponent } from './bulletins/bulletins.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ConfirmEmailComponent } from './confirm-email/confirm-email.component';
import { DiscountComponent } from './discount/discount.component';
import { FavoritesComponent } from './favorites/favorites.component';
import { NotificationComponent } from './notification/notification.component';
import { PointsComponent } from './points/points.component';
import { ProductsComponent } from './products/products.component';
import { RecoveryPasswordComponent } from './recovery-password/recovery-password.component';
import { ProfileUserComponent } from './profile-user/profile-user.component';
import { ProfilePartnerComponent } from './profile-partner/profile-partner.component';
import { SettingsComponent } from './settings/settings.component';
import { QrScanComponent } from './qr-scan/qr-scan.component';
import { ManagementComponent } from './management/management.component';
import { StatisticsComponent } from './statistics/statistics.component';

/**
 * Services
 */
import { GuardAccountService } from '@app/core/services';

export const AccountRoutes: Routes = [
  {
    path: '',
    component: AccountShellComponent,
    children: [
      {
        path: RoutingConfig.AccountBulletins,
        component: BulletinsComponent,
        data: {
          SEO: BulletinsSeoParams,
          ROLES: ['user']
        },
        canActivate: [
          GuardAccountService
        ],
      },
      {
        path: RoutingConfig.AccountChangePass,
        component: ChangePasswordComponent,
        data: {
          SEO: ChangePasswordSeoParams,
          ROLES: ['user', 'partner']
        },
        canActivate: [
          GuardAccountService
        ]
      },
      {
        path: RoutingConfig.AccountConfirmEmail,
        component: ConfirmEmailComponent,
        data: {
          SEO: ConfirmEmailSeoParams,
          ROLES: ['user']
        },
        canActivate: [
          GuardAccountService
        ]
      },
      {
        path: RoutingConfig.AccountDiscount,
        component: DiscountComponent,
        data: {
          SEO: DiscountSeoParams,
          ROLES: ['user']
        },
        canActivate: [
          GuardAccountService
        ]
      },
      {
        path: RoutingConfig.AccountFavorites,
        component: FavoritesComponent,
        data: {
          SEO: FavoritesSeoParams,
          ROLES: ['user']
        },
        canActivate: [
          GuardAccountService
        ]
      },
      {
        path: RoutingConfig.AccountNotify,
        component: NotificationComponent,
        data: {
          SEO: NotificationSeoParams,
          ROLES: ['user', 'partner']
        },
        canActivate: [
          GuardAccountService
        ]
      },
      {
        path: RoutingConfig.AccountPoints,
        component: PointsComponent,
        data: {
          SEO: PointsSeoParams,
          ROLES: ['user']
        },
        canActivate: [
          GuardAccountService
        ]
      },
      {
        path: RoutingConfig.AccountProducts,
        component: ProductsComponent,
        data: {
          SEO: ProductsSeoParams,
          ROLES: ['partner']
        },
        canActivate: [
          GuardAccountService
        ]
      },
      {
        path: RoutingConfig.AccountRecoveryPass,
        component: RecoveryPasswordComponent,
        data: {
          SEO: RecoveryPassSeoParams,
        }
      },
      {
        path: RoutingConfig.AccountPartner,
        component: ProfilePartnerComponent,
        data: {
          SEO: ProfileSeoParams,
          ROLES: ['partner']
        },
        canActivate: [
          GuardAccountService
        ]
      },
      {
        path: RoutingConfig.AccountUser,
        component: ProfileUserComponent,
        data: {
          SEO: ProfileSeoParams,
          ROLES: ['user']
        },
        canActivate: [
          GuardAccountService
        ]
      },
      {
        path: RoutingConfig.AccountQRScan,
        component: QrScanComponent,
        data: {
          SEO: QRScanSeoParams,
          ROLES: ['partner']
        },
        canActivate: [
          GuardAccountService
        ]
      },
      {
        path: RoutingConfig.AccountQRScan + '/:id',
        component: QrScanComponent,
        data: {
          SEO: QRScanSeoParams,
          ROLES: ['partner']
        },
        canActivate: [
          GuardAccountService
        ]
      },
      {
        path: RoutingConfig.AccountSettings,
        component: SettingsComponent,
        data: {
          SEO: SettingsSeoParams,
          ROLES: ['user']
        },
        canActivate: [
          GuardAccountService
        ]
      },
      {
        path: RoutingConfig.AccountManagement,
        component: ManagementComponent,
        data: {
          SEO: SettingsSeoParams,
          ROLES: ['partner']
        },
        canActivate: [
          GuardAccountService
        ]
      },
      {
        path: RoutingConfig.AccountStatistics,
        component: StatisticsComponent,
        data: {
          SEO: StatisticsSeoParams,
          ROLES: ['partner']
        },
        canActivate: [
          GuardAccountService
        ]
      },
      {
        path: '**',
        redirectTo: RoutingConfig.AccountUser,
        pathMatch: 'full'
      }
    ]
  }
];
