import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DevicesClass } from '@app/core/classes';
import { INotificationItem } from '@app/modules/notification-modules/notification.model';
import { ConfirmationService } from 'primeng/api';
import { NotificationService } from '@app/modules/notification-modules/notification.service';
import { NotificationStoryService } from '@app/modules/notification-modules/notification.story';
import { forkJoin } from 'rxjs';
import { SkeletonViewTypes } from '@app/shared/skeleton-item/skeleton-item.component';

enum TabsValues {
  Bulletins,    // 0
  News,         // 1
}

@UntilDestroy()
@Component({
  selector: 'app-account-notification',
  templateUrl: 'notification.component.html',
  styleUrls: [`./notification.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class NotificationComponent extends DevicesClass implements OnInit {
  public TVTypes = TabsValues;
  public SVTypes = SkeletonViewTypes;

  /**
   * Base component variables.
   */
  public NTabsValue: TabsValues;
  public NLoading: boolean = true;
  public NBulletinsSelected: Array<INotificationItem> = [];
  public NBulletinsSelectedAll: boolean;

  /**
   * Bulletins Favorites.
   */
  public NBulletinsResults: Array<INotificationItem> = [];

  constructor(injector: Injector,
    public cdr: ChangeDetectorRef,
    private confirmationService: ConfirmationService,
    private notificationService: NotificationService,
    private notificationStory: NotificationStoryService,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.onSetTabValue(this.TVTypes.Bulletins);
  }

  onTabsValueLoading(status: boolean, delay: number = 0) {
    setTimeout(() => {
      this.NLoading = status;
      this.cdr.detectChanges();
    }, delay);
  }

  onSetTabValue(value: TabsValues): void {
    if (this.NTabsValue === value) {
      return;
    }
    this.onTabsValueLoading(true);
    this.onUploadResetAll();

    if (value === this.TVTypes.Bulletins) {
      this.onUploadNotification();
    }
    if (value === this.TVTypes.News) {
    }

    this.NTabsValue = value;
  }

  onUploadNotification(): void {
    this.notificationService.getNotification().subscribe(
      response => {
        /**
         * Upload of notifications bulletins.
         */
        this.NBulletinsResults = response.sort((a, b) => +new Date(b.created) - (+new Date(a.created)));
        this.cdr.markForCheck();

        /**
         * Reset of loading.
         */
        this.onTabsValueLoading(false, 1000);
      },
      error => this.onUploadResetAll()
    );
  }

  onUploadResetAll(): void {
    this.NBulletinsSelectedAll = false;
    this.cdr.markForCheck();
  }

  onSetSelectedBulletin(notify: INotificationItem): void {
    if (this.NBulletinsSelected.some(bull => bull.itemId === notify.itemId)) {
      return this.onUncheckedSelectedBulletin(notify);
    }
    notify.selected = true;

    this.NBulletinsSelected.push(notify);

    this.cdr.markForCheck();
  }

  onSetSelectedAllBulletins($event: Event): void {
    const $target = <any>$event.target || null;

    this.NBulletinsResults.map(bulletin => {
      $target.checked ? this.NBulletinsSelected.push(bulletin) : this.NBulletinsSelected = [];

      bulletin.selected = $target.checked;
    });

    this.NBulletinsSelectedAll = true;
  }

  onUncheckedSelectedBulletin(notify: INotificationItem) {
    if (this.NBulletinsResults) {
      this.NBulletinsResults.map(inst => {
        if (inst.itemId === notify.itemId) {
          inst.selected = false;
        }
      });
    }
    this.NBulletinsSelected = this.NBulletinsSelected.filter(bull => {
      return bull.itemId !== notify.itemId;
    });
  }

  onDeleteSelectedBulletins(): void {
    if (!this.NBulletinsSelected.length) {
      return;
    }
    this.confirmationService.confirm({
      key: 'default',
      header: 'Удалить выбранные?',
      message: 'Выбранные уведомления будут удалены',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.onDeleteSelectedBulletinsAccept();
      }
    });
  }

  onDeleteAllBulletins(): void {
    this.confirmationService.confirm({
      key: 'default',
      header: 'Удалить все?',
      message: 'Все уведомления будут удалены',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.onDeleteAllBulletinsAccept();
      }
    });
  }

  onDeleteSelectedBulletinsAccept(): void {
    const collectionAPI: any = [];

    this.NBulletinsSelected.map(notify => {
      collectionAPI.push(
        this.notificationService.deleteNotification(notify.id)
      )
    });

    forkJoin(collectionAPI).subscribe(
      collectionAPI => {
        this.onUploadNotification();
      }
    );
  }

  onDeleteAllBulletinsAccept(): void {
    const collectionAPI: any = [];

    this.NBulletinsResults.map(notify => {
      collectionAPI.push(
        this.notificationService.deleteNotification(notify.id)
      )
    });

    forkJoin(collectionAPI).subscribe(
      collectionAPI => {
        this.onUploadNotification();
      }
    );
  }
}
