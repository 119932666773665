<div class="account-shell__loading animated faster fadeIn" *ngIf="QRLoading">
  <div class="ui-loader ui-loader__page-light"></div>
</div>

<div class="account-shell__content"
  ngClass="{{QRLoading ? 'loading' : ''}}">

  <div class="pg-wrapper">
    <div class="account-shell__content-wrapper"
      ngClass="{{(deviceValue | async) === DTypes.Mobile ? '' : 'pl-3 pr-3'}}">

      <div class="account-shell__content-subtitle">
        <h5>Номер карты</h5>
        <div class="grid align-items-start pt-2">
          <p class="txt-caption__large">Введите номер карты клиента</p>
        </div>
      </div>
    </div>

    <div class="account-shell__content-wrapper"
      ngClass="{{(deviceValue | async) === DTypes.Mobile ? '' : 'pl-3 pr-3'}}">

      <div class="qr-scan__card">
        <ng-otp-input #ngOtpInput
          (onInputChange)="onInputQRCode($event)"
          [config]="{length: QRCodeLength, containerClass: 'qr-scan__otp', allowNumbersOnly: true}">
        </ng-otp-input>
      </div>
    </div>

    <div class="account-shell__content-wrapper"
      ngClass="{{(deviceValue | async) === DTypes.Mobile ? '' : 'pl-3 pr-3'}}">
      <div class="account-shell__content-button">
        <div class="grid align-items-center justify-content-center">
          <!--<div class="col-3 col-sm-6">
            <button class="ui-buttons ui-buttons__blue-large" (click)="onSubmitQRCode()">
              Сканировать QR-код
            </button>
          </div>-->
          <div class="col-3 col-sm-6">
            <button class="ui-buttons ui-buttons__blue-large" (click)="onSubmitQRCode()"
              ngClass="{{QRCodeValid ? '' : 'disabled'}}">

              Поиск по номеру карты
            </button>
          </div>
        </div>
      </div>
    </div>


    <div class="account-shell__content-wrapper" *ngIf="QRUserInfo | async as UserInfo"
      ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'pt-4' : 'pt-8 pl-3 pr-3'}}">

      <div class="row">
        <div class="account-shell__content-subtitle">
          <h5>Данные клиента</h5>
          <div class="grid align-items-start pt-2">
            <p class="txt-caption__large">Заполните поля для начисления баллов</p>
          </div>
        </div>
      </div>

      <div class="row">
        <form class="account-shell__form" [formGroup]="QRUserSale" (keydown.enter)="$event.preventDefault()" autocomplete="off">
          <div class="grid align-items-start justify-content-center"
            ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : ''}}">

            <div class="col-4 col-sm-12">
              <div class="ui-input__placeholder">
                <p>Клиент</p>
              </div>
              <div class="ui-input ui-input__text">
                <div class="ui-input__cup ui-scroll-horizontal">
                  <div class="grid align-items-center">
                    <div class="col-auto">
                      <p>{{UserInfo.name}}</p>
                    </div>
                    <div class="col-auto" ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mr-2 ml-2' : 'mr-3 ml-3'}}">
                      <p>/</p>
                    </div>
                    <div class="col-auto mr-1">
                      <p><strong>Дата:</strong></p>
                    </div>
                    <div class="col-auto">
                      <p>{{UserInfo.registrationDate | formatDate}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-5 col-sm-12">
              <div class="ui-input__placeholder">
                <p>Комментарий</p>
              </div>
              <div class="ui-input ui-input__text">
                <input type="text" placeholder="Комментарий к покупке" formControlName="comment">
              </div>
            </div>

            <div class="col-3 col-sm-12">
              <div class="ui-input__placeholder">
                <p>Сумма покупки</p>
                <span class="require">*</span>
                <p class="error-holder" *ngIf="ErrorsField.price.status">
                  {{ErrorsField.price.value}}
                </p>
              </div>
              <div class="ui-input ui-input__text">
                <input type="text" placeholder="1 000" formControlName="price" currencyMask
                  [options]="{ prefix: 'RUB ', thousands: ' ', decimal: ',' }">
              </div>
            </div>


          </div>
        </form>
      </div>

      <div class="row">
        <div class="account-shell__content-errors __qr__errors">
          <div class="grid justify-content-center"
            ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'align-items-center text-left' : 'align-items-baseline'}}">

            <span class="mr-icon mr-icon-status-error"></span>
            <p>{{ErrorsText}}</p>
          </div>
        </div>

        <div class="account-shell__content-button">
          <div class="grid align-items-center justify-content-center">
            <div class="col-2 col-sm-6">
              <button class="ui-buttons ui-buttons__blue-large" (click)="onSubmitSalePoints()"
                ngClass="{{QRCodeValid ? '' : 'disabled'}}">

                Отправить
              </button>
            </div>
            <div class="col-2 col-sm-6">
              <button class="ui-buttons ui-buttons__light-large" (click)="onResetQRCode()">
                Отмена
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
