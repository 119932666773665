import { AppStaticURL } from '@app/app.settings';
import { Component, OnInit, Input, Injector, ChangeDetectorRef } from '@angular/core';
import { RoutingConfig } from '@app/routing/routing.config';
import { DevicesClass, DeviceTypes } from '@app/core/classes/component/devices.class';
import { Router } from '@angular/router';
import { AccountEventBus, AccountStoryService } from '@app/modules/account-modules/account.story';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IAccountUserInfo } from '@app/modules/account-modules/account.model';
import { NotificationEventBus, NotificationStoryService } from '@app/modules/notification-modules/notification.story';

export enum AccountMenuRolesTypes {
  User,           // 0
  Admin,          // 1
  Moderator,      // 2
  Partner,        // 2
}

export interface IAccountMenu {
  url: string,
  title: string,
  disabled?: boolean
}

@UntilDestroy()
@Component({
  selector: 'shared-account-menu',
  templateUrl: 'account-menu.component.html',
  styleUrls: [`./account-menu.component.scss`],
})

export class AccountMenuComponent extends DevicesClass implements OnInit {
  public StaticURL = AppStaticURL;
  public Routing = RoutingConfig;

  /**
   * Base component variables.
   */
  public AccountMenuRoles = AccountMenuRolesTypes;
  public AccountMenuUser: IAccountUserInfo = null;
  public AccountMenuNotify: number = 0;

  /**
   * Static URL.
   */
  public SignInURL: string = `/${RoutingConfig.Login}/${RoutingConfig.SignIn}`;
  public SignUpURL: string = `/${RoutingConfig.Login}/${RoutingConfig.SignUp}`;

  @Input() Device: DeviceTypes;
  @Input() Roles: AccountMenuRolesTypes;

  constructor(injector: Injector,
    public cdr: ChangeDetectorRef,
    private router: Router,
    private accountStory: AccountStoryService,
    private notificationStory: NotificationStoryService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.onAccountStoryBus();
    this.onNotificationStoryBus();
  }

  onAccountStoryBus(): void {
    this.accountStory.AccountEventBus$.pipe(untilDestroyed(this)).subscribe(
      emit => this.onAccountStoryListener(emit)
    );
  }

  onAccountStoryListener(emit: AccountEventBus): void {
    if (emit.event === 'onAccountUpdate') {
      this.AccountMenuUser = emit.data ? emit.data : null;
    }

    if (emit.event === 'onAccountLogout' || emit.event === 'onAccountEmpty') {
      this.AccountMenuUser = null;
    }

    this.cdr.markForCheck();
  }

  onNotificationStoryBus(): void {
    this.notificationStory.NotificationEventBus$.pipe(untilDestroyed(this)).subscribe(
      emit => this.onNotificationStoryListener(emit)
    );
  }

  onNotificationStoryListener(emit: NotificationEventBus): void {
    if (emit.event === 'onUpdateCount') {
      this.AccountMenuNotify = emit.data;
      this.cdr.markForCheck();
    }
  }

  onRouteLink($event: Event, url: string, disabled?: boolean): void {
    $event.preventDefault();

    if (!disabled) {
      this.router.navigateByUrl('/' + RoutingConfig.Account + '/' + url).catch();
    }
  }

  onCheckLoginURL(): boolean {
    return location.pathname.split('/').some((item, key) => {
      return (
        item === RoutingConfig.Login ||
        item === RoutingConfig.PageErrors
      );
    });
  }

  onViewLinkActive(menuKey: string): boolean {
    const menuKeyCheckURL =  location.pathname.split('/').some((item, key) => {
      return item === menuKey;
    });

    const profileCheckURL =  location.pathname.split('/').some((item, key) => {
      return item === RoutingConfig.Account;
    });

    return (
      menuKeyCheckURL && profileCheckURL
    );
  }

  onSignInURL($event: Event): void {
    $event.preventDefault();

    this.onCheckLoginURL() || location.pathname === '/' ?
      this.router.navigateByUrl(`${this.SignInURL}`) :
      this.router.navigateByUrl(`${this.SignInURL}?redirectUrl=${location.pathname}`);
  }

  onSignUpURL($event: Event): void {
    $event.preventDefault();

    this.onCheckLoginURL() || location.pathname === '/' ?
      this.router.navigateByUrl(`${this.SignUpURL}`) :
      this.router.navigateByUrl(`${this.SignUpURL}?redirectUrl=${location.pathname}`);
  }

  onAccountMenuNotify(): string {
    return this.AccountMenuNotify > 0 ? '(' + this.AccountMenuNotify + ')' : '';
  }

  onAccountLogOut(): void {
    this.accountStory.AccountEventBus.next({
      event: 'onAccountLogout'
    });
  }

  get onAccountMenuData(): Array<IAccountMenu> {
    if (this.Roles === this.AccountMenuRoles.User) {
      return [
        {
          url: RoutingConfig.AccountUser,
          title: 'Профиль'
        },
        {
          url: RoutingConfig.AccountChangePass,
          title: 'Пароль'
        },
        {
          url: RoutingConfig.AccountNotify,
          title: 'Уведомления' + ' ' + this.onAccountMenuNotify(),
        },
        {
          url: RoutingConfig.AccountFavorites,
          title: 'Избранное'
        },
        {
          url: RoutingConfig.AccountBulletins,
          title: 'Мои объявления'
        },
        {
          url: RoutingConfig.AccountPoints,
          title: 'Карта музыканта'
        },
        {
          url: RoutingConfig.AccountSettings,
          title: 'Настройки'
        }
      ]
    }

    if (this.Roles === this.AccountMenuRoles.Partner) {
      return [
        {
          url: RoutingConfig.AccountPartner,
          title: 'Профиль'
        },
        {
          url: RoutingConfig.AccountChangePass,
          title: 'Пароль'
        },
        // {
        //   url: RoutingConfig.AccountNotify,
        //   title: 'Уведомления' + ' ' + this.onAccountMenuNotify()
        // },
        {
          url: RoutingConfig.AccountStatistics,
          title: 'Статистика'
        },
        {
          url: RoutingConfig.AccountProducts,
          title: 'Товары и услуги'
        },
        {
          url: RoutingConfig.AccountManagement,
          title: 'Настройки и управление страницей',
        },
        {
          url: RoutingConfig.AccountQRScan,
          title: 'Отсканировать QR-код'
        }
      ]
    }

    if (this.Roles === this.AccountMenuRoles.Admin) {
      return [
        {
          url: RoutingConfig.AccountPartner,
          title: 'Профиль'
        },
        {
          url: RoutingConfig.AdminUsers,
          title: 'Пользователи'
        },
        {
          url: RoutingConfig.AdminBulletins,
          title: 'Объявления',
          disabled: true
        }
      ]
    }

    return [];
  }
}
