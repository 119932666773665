import { AppApiURL } from '@app/app.settings';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { TokenService } from '@app/core/services';
import { DialogService } from 'primeng/dynamicdialog';
import { HttpRequestToken } from '@app/core/classes';
import { DeviceTypes } from '@app/core/classes';
import { DialogIncludesTypes, DialogComponentModel } from '@app/core/models/diallog';
import { AuthSignUpRequest, AuthSignUpResponse } from '@app/modules/auth-modules/auth.model'
import { MusicianService } from '@app/modules/musician-modules/musician.service';
import { Observable, throwError } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { _deviceType } from '@app/utils/device';

import {
  AuthResponseSuccess,
  AuthSignInRequest,
  AuthSignInResponse,
  AuthTokensRefreshRequest
} from './auth.model';

/**
 * Components.
 */
import { SignRemindComponent } from '@app/modules/auth-modules/client/sign-remind/sign-remind.component';
import { SignNeedComponent } from '@app/modules/auth-modules/client/sign-need/sign-need.component';
import { SignConfirmedComponent } from '@app/modules/auth-modules/client/sign-confirmed/sign-confirmed.component';

@Injectable()

export class AuthService {
  constructor(
    private http: HttpClient,
    private token: TokenService,
    private dialogService: DialogService,
    private musicianService: MusicianService
  ) {}

  /**
   * Api: v01.
   * Method: Auth SignIn.
   * @param request
   */
  public signIn(request: AuthSignInRequest): Observable<AuthSignInResponse> {
    return this.http.post<AuthSignInRequest>(AppApiURL + '/auth/signIn', request,
      {
        withCredentials: true,
        observe: 'response',
        reportProgress: false,
        params: new HttpRequestToken(true)
      }
    ).pipe(
      timeout(10000),
      map((response: HttpResponse<any>) => response.body),
      catchError((error: HttpErrorResponse) => {
        /**
         * Base error response.
         */
        if (!error.hasOwnProperty('status')) {
          return throwError(`Ошибка авторизации!`);
        }

        /**
         * Error response by status code.
         */
        if (error.status === 400 || error.status === 401) {
          return throwError(`Неверный логин или пароль!`);
        }
        if (error.status === 403) {
          return throwError(`Пользователь заблокирован!`);
        }

        /**
         * Default error response.
         */
        return throwError(`Ошибка авторизации!`);
      })
    );
  }

  /**
   * Method: Auth SignInExternal.
   * @param response
   */
  public SignInExternal(response: AuthSignInResponse): void {
    this.onAuthRequestSuccess(response);
  }

  /**
   * Api: v01.
   * Method: Auth SignUp.
   * @param request
   */
  public signUp(request: AuthSignUpRequest): Observable<AuthSignUpResponse> {
    return this.musicianService.signUp(request);
  }

  /**
   * Api: v01.
   * Method: Auth SignUp External.
   * @param request
   */
  public signUpExternal(request: AuthSignUpRequest): Observable<AuthSignUpResponse> {
    return this.musicianService.finishRegistration(request);
  }

  /**
   * Api: v01.
   * Method: Auth Confirm Email.
   * @param userId
   * @param code
   */
  public confirmEmail(userId: string, code: string): Observable<any> {
    return this.http.get<any>(AppApiURL + '/auth/confirmEmail' + `/${userId}/${code}`,
      {
        withCredentials: true,
        observe: 'response',
        reportProgress: false
      }
    ).pipe(
      map((response: HttpResponse<any>) => response.body),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  /**
   * Api: v01.
   * Method: Auth Tokens Refresh.
   * @param request
   */
  public tokensRefresh(request: AuthTokensRefreshRequest): Observable<any> {
    return this.http.post<AuthTokensRefreshRequest>(AppApiURL + '/auth/tokens/refresh', request,
      {
        params: new HttpRequestToken(true)
      }
    );
  }

  /**
   * Api: v01.
   * Method: Auth get Token by guid.
   * @param guid
   */
  public getToken(guid: string): Observable<AuthResponseSuccess> {
    return this.http.get<AuthResponseSuccess>(AppApiURL + '/auth/getToken/' + guid,
      {
        params: new HttpRequestToken(true)
      }
    );
  }

  /**
   * Api: v01.
   * Method: Auth Logout
   */
  public logout(): Observable<any> {
    return this.http.post<any>(AppApiURL + '/auth/logout', null,
      {
        params: new HttpRequestToken(true)
      }
    );
  }

  /**
   * Method: Callback for API Auth SignIn or SignUp.
   * @param response
   * @param remember
   */
  public onAuthRequestSuccess(response: AuthResponseSuccess, remember: boolean = true): void {
    /**
     * Set of Access Token.
     * @type string
     */
    if (response.accessToken) {
      this.token.onAccessToken(response.accessToken, remember);
    }


    /**
     * Set of Refresh Token.
     * @type string
     */
    if (response.refreshToken) {
      this.token.onRefreshToken(response.refreshToken, remember);
    }


    /**
     * Set of Expires Token.
     * @type string
     */
    if (response.expires) {
      this.token.onExpires(response.expires, remember);
    }
  }

  /**
   * Method: Open popup with Auth Remind Password.
   * @param email
   */
  public onAuthRemindPopup(email?: string): void {
    this.dialogService.open(SignRemindComponent, {
      header: 'Восстановление пароля',
      width: _deviceType() === DeviceTypes.Mobile ? '90%' : '100%',
      styleClass: 'p-dialog__remind',
      data: {
        includeType: DialogIncludesTypes.PopupTemplate,
        includeData: email
      } as DialogComponentModel
    });
  }

  /**
   * Method: Open popup with Auth Remind Password.
   * @param content
   */
  public onAuthNeedPopup(content: string): void {
    this.dialogService.open(SignNeedComponent, {
      header: 'Авторизация',
      width: _deviceType() === DeviceTypes.Mobile ? '90%' : '100%',
      styleClass: 'p-dialog__remind',
      data: {
        includeType: DialogIncludesTypes.PopupTemplate,
        includeData: content
      } as DialogComponentModel
    });
  }

  /**
   * Method: Open popup with Auth Email Not Confirmed.
   * @param content
   */
  public onAuthConfirmedPopup(content: string): void {
    this.dialogService.open(SignConfirmedComponent, {
      header: 'Внимание',
      width: _deviceType() === DeviceTypes.Mobile ? '90%' : '100%',
      styleClass: 'p-dialog__remind',
      data: {
        includeType: DialogIncludesTypes.PopupTemplate,
        includeData: content
      } as DialogComponentModel
    });
  }
}
