import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FundamentalsModule } from '@app/modules/fundamentals-modules/fundamentals.module'

/**
 * Components
 */
import { MapAddressComponent } from './map-address/map-address.component';

/**
 * Services
 */
import { AddressService } from './address.service';

/**
 * Vendors
 */
import { TooltipModule } from 'primeng/tooltip';
import { NgxCurrencyModule } from 'ngx-currency';

/**
 * Pipes
 */
import { PipesCoreModule } from '@app/core/pipes/pipes.module';

@NgModule({
  declarations: [
    MapAddressComponent
  ],
  exports: [
    MapAddressComponent
  ],
  entryComponents: [
    MapAddressComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FundamentalsModule,
    TooltipModule,
    NgxCurrencyModule,
    PipesCoreModule
  ],
  providers: [
    AddressService
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})

export class AddressModule {}
