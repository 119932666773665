<div class="account-shell__loading animated faster fadeIn" *ngIf="ProfileLoading">
  <div class="ui-loader ui-loader__page-light"></div>
</div>

<div class="account-shell__content"
  ngClass="{{ProfileLoading ? 'loading' : ''}}">

  <div class="pg-wrapper" *ngIf="ProfileAccountSubject | async as Profile">

    <div *ngIf="ProfilePartnerSubject | async as Partner">
      <div class="account-shell__content-wrapper" *ngIf="!Partner?.isEnabled"
        ngClass="{{(deviceValue | async) === DTypes.Mobile ? '' : 'pl-3 pr-3'}}">

        <div class="row">
          <div class="profile-partner__confirmed status-warning">
            <div class="grid align-items-center justify-content-center flex-column">
              <div class="col-12 col-sm-12 text-center">
                <span class="mr-icon mr-icon-status-error mt-2 mb-4"></span>
                <h5>Ваша страница не опубликована!</h5>
                <p class="mt-2 mb-6">
                  Вы можете изменять отображение Вашей страницы в поисковых резльтатах программы лояльности.<br/>
                  Для этого необходимо перейти в раздел <strong>"Настройки и управление страницей"</strong>.
                </p>
              </div>
              <div class="col-3 col-sm-6">
                <button class="ui-buttons ui-buttons__blue-large" (click)="onRouteToSettings()">
                  Перейти в настройки
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="account-shell__content-wrapper"
      ngClass="{{(deviceValue | async) === DTypes.Mobile ? '' : 'pl-3 pr-3'}}">

      <div class="account-shell__content-subtitle">
        <h5>Основная информация</h5>
        <div class="grid align-items-start pt-2">
          <p class="txt-require pr-1">*</p>
          <p class="txt-caption__large">— Поля отмеченные звездочкой обязательны для заполнения и не могут быть пустыми.</p>
        </div>
      </div>
    </div>
    <div class="account-shell__content-wrapper">
      <div class="grid align-items-start justify-content-center"
        ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : 'pb-4'}}">

        <div class="col-4 col-sm-12">
          <div class="account-shell__avatar">
            <div class="account-shell__avatar-event">
              <!--<div class="account-shell__avatar-event__clear" *ngIf="ProfileAvatar" (click)="onUploadAvatarClear()">
                <i class="pi pi-times-circle"></i>
              </div>-->

              <div class="account-shell__avatar-event__picture">
                <input type="file" id="avatarUpload" class="account-shell__avatar-event__picture-input"
                  (change)="onUploadAvatarEvent($event.target['files'])"
                  [accept]="fileAcceptString">

                <span class="ui-graphics__astronaut_dark" *ngIf="!ProfileAvatar"></span>
                <img src="{{ProfileAvatar}}" (error)="onAvatarError()" *ngIf="ProfileAvatar">

                <div class="account-shell__avatar-event__picture-link" (click)="onUploadAvatarHandler()">
                  <div class="grid align-items-center justify-content-center">
                    <i class="pi pi-upload"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="account-shell__avatar-heading">
              <h5>{{Profile.username}}</h5>
            </div>
            <div class="account-shell__avatar-helper">
              <p>Размер не должен превышать 1Mb.<br/>Допустимые типы файлов: jpg, png, gif.</p>
            </div>
          </div>
        </div>

        <div class="col-8 col-sm-12">
          <div class="account-shell__form">
            <div class="grid align-items-end"
              ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : 'mb-4'}}">

              <div class="col-6 col-sm-12 no-padding" [formGroup]="ProfilePartnerForm">
                <div class="account-shell__forms-input"
                  ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-4' : 'pl-2 pr-2'}}">

                  <div class="ui-input__placeholder">
                    <p>Название организации</p>
                    <span class="require">*</span>
                    <p class="error-holder" *ngIf="ErrorsField.title.status">
                      {{ErrorsField.title.value}}
                    </p>
                  </div>
                  <div class="ui-input ui-input__text"
                    ngClass="{{ErrorsField.title.status ? 'error' : ''}}">
                    <input type="text" placeholder="Название организации" formControlName="title">
                  </div>
                </div>
              </div>

              <div class="col-6 col-sm-12 no-padding" [formGroup]="ProfileAccountForm">
                <div class="account-shell__forms-input"
                  ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-4' : 'pl-2 pr-2'}}">

                  <div class="ui-input__placeholder">
                    <p>Контактное лицо</p>
                    <span class="require">*</span>
                    <p class="error-holder" *ngIf="ErrorsField.name.status">
                      {{ErrorsField.name.value}}
                    </p>
                  </div>
                  <div class="ui-input ui-input__text"
                    ngClass="{{ErrorsField.name.status ? 'error' : ''}}">
                    <input type="text" placeholder="Контактное лицо" formControlName="name">
                  </div>
                </div>
              </div>
            </div>

            <div class="grid align-items-end"
              ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : 'mb-4'}}">

              <div class="col-6 col-sm-12 no-padding" [formGroup]="ProfileAccountForm">
                <div class="account-shell__forms-input"
                  ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-4' : 'pl-2 pr-2'}}">

                  <div class="ui-input__placeholder">
                    <p>Город</p>
                    <span class="require">*</span>
                    <p class="error-holder" *ngIf="ErrorsField.cityId.status">
                      {{ErrorsField.cityId.value}}
                    </p>
                  </div>
                  <div class="ui-input ui-input__text"
                    ngClass="{{ErrorsField.cityId.status ? 'error' : ''}}">
                    <div class="ui-input__cup cta ui-scroll-horizontal" (click)="onViewSearchCities()">
                      <input type="text" (focus)="onViewSearchCities()" readonly>
                      <span *ngIf="!ProfileCityValue">Выбрать город</span>

                      <p-chip *ngIf="ProfileCityValue"
                        label="{{ProfileCityValue.name}}"
                        styleClass="input__chip">
                      </p-chip>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-6 col-sm-12 no-padding">
                <div class="account-shell__forms-input"
                  ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-4' : 'pl-2 pr-2'}}">

                  <div class="ui-input__placeholder">
                    <p>Вид деятельности</p>
                  </div>

                  <!--<div class="ui-input__cup ui-scroll-horizontal">
                    <p *ngIf="ProfilePartnerType">
                      {{ProfilePartnerType.name}}
                    </p>
                  </div>-->
                  <p-dropdown optionLabel="name" placeholder="Вид деятельности"
                    [options]="ProfilePartnerTypes"
                    [(ngModel)]="ProfilePartnerTypeSelected"
                    [styleClass]="'p-dropdown__base'"
                    (onChange)="onDropdownPartnerTypeCtrl(ProfilePartnerTypeSelected)">
                  </p-dropdown>
                </div>
              </div>
            </div>

            <div class="grid align-items-end"
              ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : 'mb-4'}}">

              <div class="col-6 col-sm-12 no-padding" [formGroup]="ProfileAccountForm">
                <div class="account-shell__forms-input"
                  ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-4' : 'pl-2 pr-2'}}">

                  <div class="ui-input__placeholder">
                    <p>E-mail</p>
                    <span class="require">*</span>
                    <p class="error-holder" *ngIf="ErrorsField.email.status">
                      {{ErrorsField.email.value}}
                    </p>
                  </div>
                  <div class="ui-input ui-input__text"
                    ngClass="{{ErrorsField.email.status ? 'error' : ''}}">

                    <input type="text" placeholder="E-mail" formControlName="email"
                      tooltipStyleClass="p-tooltip__input"
                      pTooltip="При изменении email Вам будет выслано письмо с новым паролем. Потребуется повторная авторизация"
                      tooltipPosition="{{(deviceValue | async) === DTypes.Mobile ? 'bottom' : 'right'}}"
                      hideDelay="0"
                      tooltipEvent="focus">
                  </div>
                </div>
              </div>

              <div class="col-6 col-sm-12 no-padding" [formGroup]="ProfilePartnerForm">
                <div class="account-shell__forms-input"
                  ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-4' : 'pl-2 pr-2'}}">

                  <div class="ui-input__placeholder">
                    <p>Размер скидки</p>
                    <span class="require">*</span>
                    <p class="error-holder" *ngIf="ErrorsField.discountAmount.status">
                      {{ErrorsField.discountAmount.value}}
                    </p>
                  </div>
                  <div class="col-4 no-padding">
                    <div class="ui-input ui-input__text"
                      ngClass="{{ErrorsField.title.status ? 'error' : ''}}">
                      <input class="text-center" type="number" min="2" max="80" formControlName="discountAmount" (blur)="onSetLimitesValues($event)"
                        tooltipStyleClass="p-tooltip__input"
                        pTooltip="Размер скидки в процентах. Не может быть менее 2 и более 80."
                        tooltipPosition="{{(deviceValue | async) === DTypes.Mobile ? 'bottom' : 'right'}}"
                        hideDelay="0"
                        tooltipEvent="focus">
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>


    <div class="account-shell__content-wrapper"
      ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'pt-8' : 'pt-12 pl-3 pr-3'}}">

      <div class="account-shell__content-subtitle">
        <h5>Дополнительная информация</h5>
      </div>
    </div>
    <div class="account-shell__content-wrapper">
      <div class="grid align-items-start justify-content-center"
        ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : ''}}">

        <div class="col-6 col-sm-12 no-padding" [formGroup]="ProfilePartnerForm">
          <div class="account-shell__forms-input"
            ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-4' : 'pl-2 pr-2'}}">

            <div class="ui-input__placeholder">
              <p>Адрес:</p>
              <span class="require">*</span>
              <p class="error-holder" *ngIf="ErrorsField.fullAddress.status">
                {{ErrorsField.fullAddress.value}}
              </p>
            </div>
            <div class="ui-input ui-input__text"
              ngClass="{{ErrorsField.fullAddress.status ? 'error' : ''}}">
              <input type="text" placeholder="Адрес" formControlName="address" maxlength="300">

              <div class="ui-input__suggest ui-scroll ui-scroll-vertical" *ngIf="ProfileSuggestData.length">
                <ng-container *ngFor="let suggest of ProfileSuggestData;">
                  <div class="ui-input__suggest-item" (click)="onApplySuggest(suggest)"
                    ngClass="{{suggest.empty ? 'empty' : ''}}">

                    <p>{{suggest?.value}}</p>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>

        <div class="col-6 col-sm-12 no-padding" [formGroup]="ProfilePartnerForm">
          <div class="account-shell__forms-input"
            ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-4' : 'pl-2 pr-2'}}">

            <div class="ui-input__placeholder">
              <p>Ссылка на интернет ресурс:</p>
              <p class="error-holder" *ngIf="ErrorsField.siteUrl.status">
                {{ErrorsField.siteUrl.value}}
              </p>
            </div>
            <div class="ui-input ui-input__text"
              ngClass="{{ErrorsField.siteUrl.status ? 'error' : ''}}">
              <input type="text" placeholder="URL" formControlName="siteUrl">
            </div>
          </div>
        </div>
      </div>

      <div class="grid align-items-start justify-content-center"
        ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : 'pt-6'}}">

        <div class="col-12 no-padding" [formGroup]="ProfilePartnerForm">
          <div class="account-shell__forms-input"
            ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-4' : 'pl-2 pr-2'}}">

            <div class="ui-input__placeholder">
              <p>Описание:</p>
            </div>
            <div class="ui-input ui-input__text profile-partner__textarea">
              <textarea placeholder="Описание компании" formControlName="description"></textarea>
            </div>
          </div>
        </div>
      </div>

      <div class="grid align-items-start justify-content-center" *ngIf="PartnerType.Record || PartnerType.Base"
        ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : 'pt-6'}}">

        <div class="col-12 no-padding">
          <div class="account-shell__forms-input"
            ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-4' : 'pl-2 pr-2'}}">

            <div class="ui-input__placeholder">
              <p>Дополнительные услуги:</p>
            </div>
          </div>
        </div>
      </div>

      <div class="grid flex-wrap" *ngIf="PartnerType.Record || PartnerType.Base"
        ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column align-items-center mt-4' : 'align-items-end mt-2 mb-4'}}">

        <div class="col-auto col-sm-12"
          [formGroup]="ProfileAdditionalAmenitiesForm"
          ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'pb-2 pl-2 pr-2' : 'pl-2 pr-4'}}">

          <div class="row pb-4">
            <div class="ui-checkbox">
              <input id="coffeeVendingMachine" type="checkbox" formControlName="coffeeVendingMachine">
              <label for="coffeeVendingMachine">
                <span class="ui-checkbox__text">Кофемашина</span>
              </label>
            </div>
          </div>
        </div>

        <div class="col-auto col-sm-12"
          [formGroup]="ProfileAdditionalAmenitiesForm"
          ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'pb-2 pl-2 pr-2' : 'pl-2 pr-6'}}">

          <div class="row pb-4">
            <div class="ui-checkbox">
              <input id="conditioner" type="checkbox" formControlName="conditioner">
              <label for="conditioner">
                <span class="ui-checkbox__text">Кондиционер</span>
              </label>
            </div>
          </div>
        </div>

        <div class="col-auto col-sm-12"
          [formGroup]="ProfileAdditionalAmenitiesForm"
          ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'pb-2 pl-2 pr-2' : 'pl-2 pr-6'}}">

          <div class="row pb-4">
            <div class="ui-checkbox">
              <input id="instrumentsRent" type="checkbox" formControlName="instrumentsRent">
              <label for="instrumentsRent">
                <span class="ui-checkbox__text">Аренда инструмента</span>
              </label>
            </div>
          </div>
        </div>

        <div class="col-auto col-sm-12"
          [formGroup]="ProfileAdditionalAmenitiesForm"
          ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'pb-2 pl-2 pr-2' : 'pl-2 pr-6'}}">

          <div class="row pb-4">
            <div class="ui-checkbox">
              <input id="recording" type="checkbox" formControlName="recording">
              <label for="recording">
                <span class="ui-checkbox__text">Звукозапись</span>
              </label>
            </div>
          </div>
        </div>

        <div class="col-auto col-sm-12"
          [formGroup]="ProfileAdditionalAmenitiesForm"
          ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'pb-2 pl-2 pr-2' : 'pl-2 pr-6'}}">

          <div class="row pb-4">
            <div class="ui-checkbox">
              <input id="smokingRoom" type="checkbox" formControlName="smokingRoom">
              <label for="smokingRoom">
                <span class="ui-checkbox__text">Комната для курения</span>
              </label>
            </div>
          </div>
        </div>

        <div class="col-auto col-sm-12"
          [formGroup]="ProfileAdditionalAmenitiesForm"
          ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'pb-2 pl-2 pr-2' : 'pl-2 pr-6'}}">

          <div class="row pb-4">
            <div class="ui-checkbox">
              <input id="tea" type="checkbox" formControlName="tea">
              <label for="tea">
                <span class="ui-checkbox__text">Бесплатный чай/кофе</span>
              </label>
            </div>
          </div>
        </div>

        <div class="col-auto col-sm-12"
          [formGroup]="ProfileAdditionalAmenitiesForm"
          ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'pb-2 pl-2 pr-2' : 'pl-2 pr-6'}}">

          <div class="row pb-4">
            <div class="ui-checkbox">
              <input id="toilet" type="checkbox" formControlName="toilet">
              <label for="toilet">
                <span class="ui-checkbox__text">Туалет</span>
              </label>
            </div>
          </div>
        </div>

        <div class="col-auto col-sm-12"
          [formGroup]="ProfileAdditionalAmenitiesForm"
          ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'pb-2 pl-2 pr-2' : 'pl-2 pr-6'}}">

          <div class="row pb-4">
            <div class="ui-checkbox">
              <input id="wiFi" type="checkbox" formControlName="wiFi">
              <label for="wiFi">
                <span class="ui-checkbox__text">Бесплатный WiFi</span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="account-shell__content-wrapper"
      ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'pt-8' : 'pt-12 pl-3 pr-3'}}">

      <div class="account-shell__content-subtitle">
        <h5>Фотографии страницы</h5>
      </div>
    </div>
    <div class="account-shell__content-wrapper"
      ngClass="{{(deviceValue | async) === DTypes.Mobile ? '' : 'pl-3 pr-3'}}">

      <div class="grid align-items-start justify-content-center"
        ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : ''}}">

        <div class="col-12 no-padding">
          <div class="ui-gallery-upload__input" (click)="onUploadPhotoHandler()">
            <input type="file" id="photoUpload" (change)="onUploadPhotoEvent($event.target['files'])" [accept]="fileAcceptString">
            <div class="grid align-items-center justify-content-center flex-column">
              <i class="pi pi-camera"></i>
              <h5>Загрузить фотографию</h5>
            </div>
          </div>
          <div class="ui-gallery-upload__preview">
            <div class="grid align-items-center flex-nowrap ui-scroll-horizontal">
              <ng-container *ngFor="let photo of ProfilePhotos; let i = index;">
                <div class="ui-gallery-upload__preview__item">
                  <img src="{{photo.src}}">

                  <div class="ui-gallery-upload__preview__event">
                    <div class="grid align-items-center justify-content-center flex-column h-full">
                      <button class="ui-buttons ui-buttons__red-small" (click)="onRemovePhotoEvent(i)">
                        <p class="pi pi-trash"></p>
                      </button>
                    </div>
                  </div>
                </div>
              </ng-container>

            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="account-shell__content-wrapper"
      ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'pt-8' : 'pt-12 pl-3 pr-3'}}">

      <div class="account-shell__content-subtitle">
        <h5>График работы</h5>
      </div>
    </div>
    <div class="account-shell__content-wrapper">
      <div class="grid align-items-start justify-content-center"
        ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : ''}}">

        <div class="col-12">
          <div class="grid align-items-end"
            ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : ''}}">

            <div class="col-12 col-sm-12 no-padding" [formGroup]="ProfilePartnerForm">
              <div class="account-shell__forms-input"
                ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-4' : 'pl-2 pr-2'}}">

                <div class="grid align-items-end flex-nowrap ui-scroll-horizontal mb-2">
                  <div class="profile-partner__work-hour__col pl-0 pr-2 mb-2" [formGroup]="ProfileWorkingHoursForm">
                    <div class="profile-partner__work-hour__content" *ngIf="onCheckProfileWorkingHoursForm('monday')" formGroupName="monday">
                      <div class="row">
                        <p class="__heading">Понедельник</p>
                      </div>
                      <div class="ui-input__placeholder mt-2">
                        <p>Часы работы:</p>
                      </div>
                      <div class="grid align-items-center">
                        <div class="col-5 pl-0 mr-1 profile-partner__work-hour__input">
                          <div class="ui-input ui-input__text">
                            <input appTimeMask class="text-center" type="text" placeholder="09:00" formControlName="from"/>
                          </div>
                        </div>
                        <div class="col-auto align-items-center text-center">
                          <p>—</p>
                        </div>
                        <div class="col-5 pr-0 ml-1 profile-partner__work-hour__input">
                          <div class="ui-input ui-input__text">
                            <input appTimeMask class="text-center" type="text" placeholder="18:00" formControlName="to"/>
                          </div>
                        </div>
                      </div>
                      <div class="ui-input__placeholder mt-4">
                        <p>Перерыв:</p>
                      </div>
                      <div class="grid align-items-center">
                        <div class="col-5 pl-0 mr-1 profile-partner__work-hour__input">
                          <div class="ui-input ui-input__text">
                            <input appTimeMask class="text-center" type="text" placeholder="09:00" formControlName="breakFrom"/>
                          </div>
                        </div>
                        <div class="col-auto align-items-center text-center">
                          <p>—</p>
                        </div>
                        <div class="col-5 pr-0 ml-1 profile-partner__work-hour__input">
                          <div class="ui-input ui-input__text">
                            <input appTimeMask class="text-center" type="text" placeholder="18:00" formControlName="breakTo"/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="profile-partner__work-hour__col pl-2 pr-2 mb-2" [formGroup]="ProfileWorkingHoursForm">
                    <div class="profile-partner__work-hour__content"  *ngIf="onCheckProfileWorkingHoursForm('tuesday')" formGroupName="tuesday">
                      <div class="row">
                        <p class="__heading">Вторник</p>
                      </div>
                      <div class="ui-input__placeholder mt-2">
                        <p>Часы работы:</p>
                      </div>
                      <div class="grid align-items-center">
                        <div class="col-5 pl-0 mr-1 profile-partner__work-hour__input">
                          <div class="ui-input ui-input__text">
                            <input appTimeMask class="text-center" type="text" placeholder="09:00" formControlName="from"/>
                          </div>
                        </div>
                        <div class="col-auto align-items-center text-center">
                          <p>—</p>
                        </div>
                        <div class="col-5 pr-0 ml-1 profile-partner__work-hour__input">
                          <div class="ui-input ui-input__text">
                            <input appTimeMask class="text-center" type="text" placeholder="18:00" formControlName="to"/>
                          </div>
                        </div>
                      </div>
                      <div class="ui-input__placeholder mt-4">
                        <p>Перерыв:</p>
                      </div>
                      <div class="grid align-items-center">
                        <div class="col-5 pl-0 mr-1 profile-partner__work-hour__input">
                          <div class="ui-input ui-input__text">
                            <input appTimeMask class="text-center" type="text" placeholder="09:00" formControlName="breakFrom"/>
                          </div>
                        </div>
                        <div class="col-auto align-items-center text-center">
                          <p>—</p>
                        </div>
                        <div class="col-5 pr-0 ml-1 profile-partner__work-hour__input">
                          <div class="ui-input ui-input__text">
                            <input appTimeMask class="text-center" type="text" placeholder="18:00" formControlName="breakTo"/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="profile-partner__work-hour__col pl-2 pr-2 mb-2" [formGroup]="ProfileWorkingHoursForm">
                    <div class="profile-partner__work-hour__content" *ngIf="onCheckProfileWorkingHoursForm('wednesday')" formGroupName="wednesday">
                      <div class="row">
                        <p class="__heading">Среда</p>
                      </div>
                      <div class="ui-input__placeholder mt-2">
                        <p>Часы работы:</p>
                      </div>
                      <div class="grid align-items-center">
                        <div class="col-5 pl-0 mr-1 profile-partner__work-hour__input">
                          <div class="ui-input ui-input__text">
                            <input appTimeMask class="text-center" type="text" placeholder="09:00" formControlName="from"/>
                          </div>
                        </div>
                        <div class="col-auto align-items-center text-center">
                          <p>—</p>
                        </div>
                        <div class="col-5 pr-0 ml-1 profile-partner__work-hour__input">
                          <div class="ui-input ui-input__text">
                            <input appTimeMask class="text-center" type="text" placeholder="18:00" formControlName="to"/>
                          </div>
                        </div>
                      </div>
                      <div class="ui-input__placeholder mt-4">
                        <p>Перерыв:</p>
                      </div>
                      <div class="grid align-items-center">
                        <div class="col-5 pl-0 mr-1 profile-partner__work-hour__input">
                          <div class="ui-input ui-input__text">
                            <input appTimeMask class="text-center" type="text" placeholder="09:00" formControlName="breakFrom"/>
                          </div>
                        </div>
                        <div class="col-auto align-items-center text-center">
                          <p>—</p>
                        </div>
                        <div class="col-5 pr-0 ml-1 profile-partner__work-hour__input">
                          <div class="ui-input ui-input__text">
                            <input appTimeMask class="text-center" type="text" placeholder="18:00" formControlName="breakTo"/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="profile-partner__work-hour__col pl-2 pr-2 mb-2" [formGroup]="ProfileWorkingHoursForm">
                    <div class="profile-partner__work-hour__content" *ngIf="onCheckProfileWorkingHoursForm('thursday')" formGroupName="thursday">
                      <div class="row">
                        <p class="__heading">Четверг</p>
                      </div>
                      <div class="ui-input__placeholder mt-2">
                        <p>Часы работы:</p>
                      </div>
                      <div class="grid align-items-center">
                        <div class="col-5 pl-0 mr-1 profile-partner__work-hour__input">
                          <div class="ui-input ui-input__text">
                            <input appTimeMask class="text-center" type="text" placeholder="09:00" formControlName="from"/>
                          </div>
                        </div>
                        <div class="col-auto align-items-center text-center">
                          <p>—</p>
                        </div>
                        <div class="col-5 pr-0 ml-1 profile-partner__work-hour__input">
                          <div class="ui-input ui-input__text">
                            <input appTimeMask class="text-center" type="text" placeholder="18:00" formControlName="to"/>
                          </div>
                        </div>
                      </div>
                      <div class="ui-input__placeholder mt-4">
                        <p>Перерыв:</p>
                      </div>
                      <div class="grid align-items-center">
                        <div class="col-5 pl-0 mr-1 profile-partner__work-hour__input">
                          <div class="ui-input ui-input__text">
                            <input appTimeMask class="text-center" type="text" placeholder="09:00" formControlName="breakFrom"/>
                          </div>
                        </div>
                        <div class="col-auto align-items-center text-center">
                          <p>—</p>
                        </div>
                        <div class="col-5 pr-0 ml-1 profile-partner__work-hour__input">
                          <div class="ui-input ui-input__text">
                            <input appTimeMask class="text-center" type="text" placeholder="18:00" formControlName="breakTo"/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="profile-partner__work-hour__col pl-2 pr-0 mb-2" [formGroup]="ProfileWorkingHoursForm">
                    <div class="profile-partner__work-hour__content" *ngIf="onCheckProfileWorkingHoursForm('friday')" formGroupName="friday">
                      <div class="row">
                        <p class="__heading">Пятница</p>
                      </div>
                      <div class="ui-input__placeholder mt-2">
                        <p>Часы работы:</p>
                      </div>
                      <div class="grid align-items-center">
                        <div class="col-5 pl-0 mr-1 profile-partner__work-hour__input">
                          <div class="ui-input ui-input__text">
                            <input appTimeMask class="text-center" type="text" placeholder="09:00" formControlName="from"/>
                          </div>
                        </div>
                        <div class="col-auto align-items-center text-center">
                          <p>—</p>
                        </div>
                        <div class="col-5 pr-0 ml-1 profile-partner__work-hour__input">
                          <div class="ui-input ui-input__text">
                            <input appTimeMask class="text-center" type="text" placeholder="18:00" formControlName="to"/>
                          </div>
                        </div>
                      </div>
                      <div class="ui-input__placeholder mt-4">
                        <p>Перерыв:</p>
                      </div>
                      <div class="grid align-items-center">
                        <div class="col-5 pl-0 mr-1 profile-partner__work-hour__input">
                          <div class="ui-input ui-input__text">
                            <input appTimeMask class="text-center" type="text" placeholder="09:00" formControlName="breakFrom"/>
                          </div>
                        </div>
                        <div class="col-auto align-items-center text-center">
                          <p>—</p>
                        </div>
                        <div class="col-5 pr-0 ml-1 profile-partner__work-hour__input">
                          <div class="ui-input ui-input__text">
                            <input appTimeMask class="text-center" type="text" placeholder="18:00" formControlName="breakTo"/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="grid align-items-end flex-nowrap ui-scroll-horizontal mb-2">
                  <div class="profile-partner__work-hour__col pl-0 pr-2 mb-2" [formGroup]="ProfileWorkingHoursForm">
                    <div class="profile-partner__work-hour__content" *ngIf="onCheckProfileWorkingHoursForm('sunday')" formGroupName="sunday">
                      <div class="row">
                        <p class="__heading">Суббота</p>
                      </div>
                      <div class="ui-input__placeholder mt-2">
                        <p>Часы работы:</p>
                      </div>
                      <div class="grid align-items-center">
                        <div class="col-5 pl-0 mr-1 profile-partner__work-hour__input">
                          <div class="ui-input ui-input__text">
                            <input appTimeMask class="text-center" type="text" placeholder="09:00" formControlName="from"/>
                          </div>
                        </div>
                        <div class="col-auto align-items-center text-center">
                          <p>—</p>
                        </div>
                        <div class="col-5 pr-0 ml-1 profile-partner__work-hour__input">
                          <div class="ui-input ui-input__text">
                            <input appTimeMask class="text-center" type="text" placeholder="18:00" formControlName="to"/>
                          </div>
                        </div>
                      </div>
                      <div class="ui-input__placeholder mt-4">
                        <p>Перерыв:</p>
                      </div>
                      <div class="grid align-items-center">
                        <div class="col-5 pl-0 mr-1 profile-partner__work-hour__input">
                          <div class="ui-input ui-input__text">
                            <input appTimeMask class="text-center" type="text" placeholder="09:00" formControlName="breakFrom"/>
                          </div>
                        </div>
                        <div class="col-auto align-items-center text-center">
                          <p>—</p>
                        </div>
                        <div class="col-5 pr-0 ml-1 profile-partner__work-hour__input">
                          <div class="ui-input ui-input__text">
                            <input appTimeMask class="text-center" type="text" placeholder="18:00" formControlName="breakTo"/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="profile-partner__work-hour__col pl-2 pr-2 mb-2" [formGroup]="ProfileWorkingHoursForm">
                    <div class="profile-partner__work-hour__content" *ngIf="onCheckProfileWorkingHoursForm('saturday')" formGroupName="saturday">
                      <div class="row">
                        <p class="__heading">Воскресенье</p>
                      </div>
                      <div class="ui-input__placeholder mt-2">
                        <p>Часы работы:</p>
                      </div>
                      <div class="grid align-items-center">
                        <div class="col-5 pl-0 mr-1 profile-partner__work-hour__input">
                          <div class="ui-input ui-input__text">
                            <input appTimeMask class="text-center" type="text" placeholder="09:00" formControlName="from"/>
                          </div>
                        </div>
                        <div class="col-auto align-items-center text-center">
                          <p>—</p>
                        </div>
                        <div class="col-5 pr-0 ml-1 profile-partner__work-hour__input">
                          <div class="ui-input ui-input__text">
                            <input appTimeMask class="text-center" type="text" placeholder="18:00" formControlName="to"/>
                          </div>
                        </div>
                      </div>
                      <div class="ui-input__placeholder mt-4">
                        <p>Перерыв:</p>
                      </div>
                      <div class="grid align-items-center">
                        <div class="col-5 pl-0 mr-1 profile-partner__work-hour__input">
                          <div class="ui-input ui-input__text">
                            <input appTimeMask class="text-center" type="text" placeholder="09:00" formControlName="breakFrom"/>
                          </div>
                        </div>
                        <div class="col-auto align-items-center text-center">
                          <p>—</p>
                        </div>
                        <div class="col-5 pr-0 ml-1 profile-partner__work-hour__input">
                          <div class="ui-input ui-input__text">
                            <input appTimeMask class="text-center" type="text" placeholder="18:00" formControlName="breakTo"/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="account-shell__content-wrapper"
      ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'pt-8' : 'pt-12 pl-3 pr-3'}}">

      <div class="account-shell__content-subtitle">
        <h5>Контактная информация</h5>
      </div>
    </div>
    <div class="account-shell__content-wrapper">
      <div class="grid align-items-start justify-content-center"
        ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : ''}}">

        <div class="col-12" [formGroup]="ProfileContactsForm">
          <div class="grid align-items-start"
            ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : 'mb-4'}}">

            <div class="col-4 col-sm-12 no-padding">
              <div class="account-shell__forms--input"
                ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-4' : 'pl-2 pr-2'}}">

                <div class="ui-input__placeholder">
                  <p>Телефон</p>
                  <p class="error-holder" *ngIf="ErrorsField.contactPhone.status">
                    {{ErrorsField.contactPhone.value}}
                  </p>
                </div>
                <div class="ui-input ui-input__text"
                  ngClass="{{ErrorsField.contactPhone.status ? 'error' : ''}}">
                  <input type="text" placeholder="+7 (911) 111-11-11"
                    formControlName="contactPhone"
                    mask="+0 (000) 000-00-00"
                    (input)="onMaskPhone($event)"
                    (blur)="onCheckPhone()">
                </div>
              </div>
            </div>

            <div class="col-4 col-sm-12 no-padding">
              <div class="account-shell__forms--input"
                ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-4' : 'pl-2 pr-2'}}">

                <div class="ui-input__placeholder">
                  <p>Мессенджеры, привязанные к телефону</p>
                </div>
                <div class="account-shell__forms-messengers"
                  ngClass="{{onProfileMessengerValid ? '' : 'disabled'}}">

                  <div class="grid align-items-center">
                    <div class="col-auto">
                      <span class="mr-icon mr-icon-telegram-color" (click)="onSetMessengerValue('hasTelegram')"
                        ngClass="{{onGetMessengerStatus('hasTelegram') ? 'selected' : ''}}">
                        <span class="path1"></span>
                        <span class="path2"></span>
                      </span>
                    </div>
                    <div class="col-auto">
                      <span class="mr-icon mr-icon-viber-color" (click)="onSetMessengerValue('hasViber')"
                        ngClass="{{onGetMessengerStatus('hasViber') ? 'selected' : ''}}">
                        <span class="path1"></span>
                        <span class="path2"></span>
                      </span>
                    </div>
                    <div class="col-auto">
                      <span class="mr-icon mr-icon-wh-color" (click)="onSetMessengerValue('hasWhatsapp')"
                        ngClass="{{onGetMessengerStatus('hasWhatsapp') ? 'selected' : ''}}">
                        <span class="path1"></span>
                        <span class="path2"></span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="grid align-items-start justify-content-center"
        ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : ''}}">

        <div class="col-12" [formGroup]="ProfileContactsForm">
          <div class="grid align-items-end"
            ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : 'mb-4'}}">

            <div class="col-4 col-sm-12 no-padding">
              <div class="account-shell__forms-input"
                ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-4' : 'pl-2 pr-2'}}">

                <div class="ui-input__placeholder">
                  <p>VK</p>
                  <p class="error-holder" *ngIf="ErrorsField.vkUsername.status">
                    {{ErrorsField.vkUsername.value}}
                  </p>
                </div>
                <div class="ui-input ui-input__text"
                  ngClass="{{ErrorsField.vkUsername.status ? 'error' : ''}}">
                  <div class="ui-input__helper">
                    <div class="ui-input__helper-icon">
                          <span class="mr-icon mr-icon-vk-color">
                            <span class="path1"></span>
                            <span class="path2"></span>
                          </span>
                    </div>
                  </div>
                  <input type="text" formControlName="vkUsername" placeholder="Ссылка на VK"
                    tooltipStyleClass="p-tooltip__input"
                    pTooltip="Пример ссылки на VK: «vk.com/musicians_russia»"
                    tooltipPosition="bottom"
                    hideDelay="0"
                    tooltipEvent="focus"
                    (blur)="onCheckSocial('vkUsername')">
                </div>
              </div>
            </div>

            <!--<div class="col-4 col-sm-12 no-padding">
              <div class="account-shell__forms-input"
                ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-4' : 'pl-2 pr-2'}}">

                <div class="ui-input__placeholder">
                  <p>Facebook</p>
                  <p class="error-holder" *ngIf="ErrorsField.facebookUsername.status">
                    {{ErrorsField.facebookUsername.value}}
                  </p>
                </div>
                <div class="ui-input ui-input__text"
                  ngClass="{{ErrorsField.facebookUsername.status ? 'error' : ''}}">
                  <div class="ui-input__helper">
                    <div class="ui-input__helper-icon">
                          <span class="mr-icon mr-icon-fb-color">
                            <span class="path1"></span>
                            <span class="path2"></span>
                          </span>
                    </div>
                  </div>
                  <input type="text" formControlName="facebookUsername" placeholder="Ссылка на Facebook"
                    tooltipStyleClass="p-tooltip__input"
                    pTooltip="Пример ссылки на Facebook: «fb.com/rusmuzikant»"
                    tooltipPosition="bottom"
                    hideDelay="0"
                    tooltipEvent="focus"
                    (blur)="onCheckSocial('facebookUsername')">
                </div>
              </div>
            </div>-->

            <div class="col-4 col-sm-12 no-padding">
              <div class="account-shell__forms-input"
                ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-4' : 'pl-2 pr-2'}}">

                <div class="ui-input__placeholder">
                  <p>Instagram</p>
                  <p class="error-holder" *ngIf="ErrorsField.instagramUsername.status">
                    {{ErrorsField.instagramUsername.value}}
                  </p>
                </div>
                <div class="ui-input ui-input__text"
                  ngClass="{{ErrorsField.instagramUsername.status ? 'error' : ''}}">
                  <div class="ui-input__helper">
                    <div class="ui-input__helper-icon">
                      <span class="mr-icon mr-icon-inst-color">
                        <span class="path1"></span>
                        <span class="path2"></span>
                        <span class="path3"></span>
                        <span class="path4"></span>
                      </span>
                    </div>
                  </div>
                  <input type="text" formControlName="instagramUsername" placeholder="Ссылка на Instagram"
                    tooltipStyleClass="p-tooltip__input"
                    pTooltip="Пример ссылки на Instagram: «musicians_of_russia»"
                    tooltipPosition="bottom"
                    hideDelay="0"
                    tooltipEvent="focus"
                    (blur)="onCheckSocial('instagramUsername')">
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="account-shell__content-wrapper">
      <div class="account-shell__content-errors __profile__errors">
        <div class="grid justify-content-center"
          ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'align-items-center text-left' : 'align-items-baseline'}}">

          <span class="mr-icon mr-icon-status-error"></span>
          <p>{{ErrorsText}}</p>
        </div>
      </div>

      <div class="account-shell__content-button">
        <div class="grid align-items-center justify-content-center">
          <div class="col-3 col-sm-6">
            <button class="ui-buttons ui-buttons__blue-large" (click)="onSaveProfile()"
              ngClass="{{ProfileAccountFormModify || ProfilePartnerFormModify || ProfilePhotosFormModify || ProfilePartnerTypeModify ? '' : 'disabled'}}">
              Сохранить
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<p-confirmDialog #default [key]="'default'" [appendTo]="'body'" [styleClass]="'p-dialog__confirm'">
  <p-footer>
    <button class="ui-buttons ui-buttons__light-small" (click)="default.reject()">Отменить</button>
    <button class="ui-buttons ui-buttons__blue-small" (click)="default.accept()">Продолжить</button>
  </p-footer>
</p-confirmDialog>
