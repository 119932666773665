import { NgModule } from '@angular/core';

/**
 * Common Pipes
 */
import { DatePipe } from '@app/core/pipes/common/date.pipe';
import { DeclinationPipe } from '@app/core/pipes/common/declination.pipe';
import { PricePipe } from '@app/core/pipes/common/price.pipe';
import { SafePipe } from '@app/core/pipes/common/safe.pipe';
import { WeekdayPipe } from '@app/core/pipes/common/weekday.pipe';

/**
 * Bulletins Pipes
 */
import { BulletinHeadingPipe } from '@app/core/pipes/bulletins/heading.pipe';
import { BulletinGenrePipe } from '@app/core/pipes/bulletins/genre.pipe';
import { BulletinCityPipe } from '@app/core/pipes/bulletins/city.pipe';
import { BulletinPreviewPipe } from '@app/core/pipes/bulletins/preview.pipe';
import {
  BulletinAboutExperiencePipe,
  BulletinSearchExperiencePipe,
  BulletinAboutConcertExperiencePipe,
  BulletinSearchConcertExperiencePipe
} from '@app/core/pipes/bulletins//experiences.pipe';

import { BulletinAgePipe } from '@app/core/pipes/bulletins/age.pipe';
import { BulletinPerformerPipe } from '@app/core/pipes/bulletins/performer.pipe';
import { BulletinInstrumentPipe } from '@app/core/pipes/bulletins/instrument.pipe';

/**
 * Partners Pipes
 */
import { PartnerHeadingPipe } from './partners/heading.pipe';

/**
 * Address Pipes
 */
import { MetroLineAliasPipe } from './address/metro-lines.pipe';
import { MetroDistancePipe } from './address/metro-distance.pipe';

@NgModule({
  declarations: [
    DatePipe,
    DeclinationPipe,
    PricePipe,
    SafePipe,
    WeekdayPipe,
    BulletinHeadingPipe,
    BulletinGenrePipe,
    BulletinCityPipe,
    BulletinPreviewPipe,
    BulletinAboutExperiencePipe,
    BulletinSearchExperiencePipe,
    BulletinAboutConcertExperiencePipe,
    BulletinSearchConcertExperiencePipe,
    BulletinAgePipe,
    BulletinPerformerPipe,
    BulletinInstrumentPipe,
    PartnerHeadingPipe,
    MetroLineAliasPipe,
    MetroDistancePipe
  ],
  exports: [
    DatePipe,
    DeclinationPipe,
    PricePipe,
    SafePipe,
    WeekdayPipe,
    BulletinHeadingPipe,
    BulletinGenrePipe,
    BulletinCityPipe,
    BulletinPreviewPipe,
    BulletinAboutExperiencePipe,
    BulletinSearchExperiencePipe,
    BulletinAboutConcertExperiencePipe,
    BulletinSearchConcertExperiencePipe,
    BulletinAgePipe,
    BulletinPerformerPipe,
    BulletinInstrumentPipe,
    PartnerHeadingPipe,
    MetroLineAliasPipe,
    MetroDistancePipe
  ],
  providers: [
    DatePipe,
    DeclinationPipe,
    PricePipe,
    SafePipe,
    WeekdayPipe,
    BulletinHeadingPipe,
    BulletinGenrePipe,
    BulletinCityPipe,
    BulletinPreviewPipe,
    BulletinAboutExperiencePipe,
    BulletinSearchExperiencePipe,
    BulletinAboutConcertExperiencePipe,
    BulletinSearchConcertExperiencePipe,
    BulletinAgePipe,
    BulletinPerformerPipe,
    BulletinInstrumentPipe,
    PartnerHeadingPipe,
    MetroLineAliasPipe,
    MetroDistancePipe
  ]
})

export class PipesCoreModule {
  constructor() {}
}
