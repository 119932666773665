<div class="news-preview__item" *ngIf="NewsView === NTypes.Preview">
  <div class="grid align-items-start"
    ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : ''}}">
    <div class="news-preview__item-picture col-5 col-sm-12 col-md-4 no-padding">
      <a routerLink="/{{NRouting.News}}/{{NewsItem.id}}" target="{{onViewDetailNews()}}">
        <img src="{{StaticURL}}{{NewsItem.thumbnailPath}}" *ngIf="!NewsThumbnailError" (error)="NewsThumbnailError = true">

        <div class="grid h-full align-items-center" *ngIf="NewsThumbnailError">
          <div class="col-12">
            <div class="ui-logo__footer"></div>
          </div>
        </div>
      </a>
    </div>
    <div class="news-preview__item-content col-7 col-sm-12 col-md-8 no-padding">
      <div class="news-preview__item-content-value">
        <div class="news-preview__item-content-heading" [ngStyle]="{'-webkit-box-orient': 'vertical'}">
          <a routerLink="/{{NRouting.News}}/{{NewsItem.id}}" target="{{onViewDetailNews()}}">{{NewsItem.title}}</a>
        </div>
        <div class="news-preview__item-content-text" [ngStyle]="{'-webkit-box-orient': 'vertical'}">
          <p>{{onTextParser(NewsItem.text)}}</p>
        </div>
      </div>

      <div class="news-preview__item-content-info">
        <div class="grid h-full align-items-center"
          ngClass="{{(deviceValue | async) === DTypes.Desktop ? '' : 'flex-wrap'}}">

          <div class="news-preview__item-content-date col-auto">
            <span class="mr-icon mr-icon-clock"></span>
            <span>{{NewsItem.created | formatDate}}</span>
          </div>
          <div class="news-preview__item-content-date col-auto">
            <span class="mr-icon mr-icon-view"></span>
            <span>Просмотров: {{NewsItem.viewCount}}</span>
          </div>
          <div class="col-auto news-preview__item-content-favorites animated fast zoomIn" *ngIf="NewsFavLoading">
            <div class="ui-loader__base"></div>
          </div>
          <div class="col-auto news-preview__item-content-favorites" *ngIf="!NewsFavLoading && !NewsFavActive" (click)="onAddToFavorites()">
            <span class="mr-icon mr-icon-favorites"></span>
            <span>В избранное</span>
          </div>
          <div class="col-auto news-preview__item-content-favorites" *ngIf="!NewsFavLoading && NewsFavActive" (click)="onRemoveFromFavorites()">
            <span class="mr-icon mr-icon-favorites-active"></span>
            <span>В избранном</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="news-profile__item" *ngIf="NewsView === NTypes.Profile">
  <div class="news-profile__item-content">
    <a class="news-profile__item-heading"
      routerLink="/{{NRouting.News}}/{{NewsItem.itemId}}" target="{{onViewDetailNews()}}"
      ngClass="{{(deviceValue | async) === DTypes.Desktop ? 'pb-3' : 'pb-2'}}">

      <p>{{NewsItem.title}}</p>
    </a>
    <div class="news-profile__item-text">
      <p>{{onTextParser(NewsItem.text)}}</p>
    </div>
  </div>
  <div class="grid news-profile__item-event"
    ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : 'align-items-start justify-content-center flex-column'}}">

    <div class="col-auto news-profile__item-event__date">
      <span class="mr-icon mr-icon-clock"></span>
      <span>{{NewsItem.created | formatDate}}</span>
    </div>

    <div class="col-auto news-profile__item-event__favorites animated fast zoomIn" *ngIf="NewsFavLoading">
      <div class="ui-loader__base"></div>
    </div>
    <div class="col-auto news-profile__item-event__favorites" *ngIf="!NewsFavLoading && !NewsFavActive" (click)="onAddToFavorites()">
      <span class="mr-icon mr-icon-favorites"></span>
      <span>В избранное</span>
    </div>
    <div class="col-auto news-profile__item-event__favorites" *ngIf="!NewsFavLoading && NewsFavActive" (click)="onRemoveFromFavorites()">
      <span class="mr-icon mr-icon-favorites-active"></span>
      <span>В избранном</span>
    </div>
  </div>
</div>


<div class="news-preview__item" *ngIf="NewsView === NTypes.ProfileBlock">
  <div class="grid align-items-start"
    ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : ''}}">
    <div class="news-preview__item-picture col-5 col-sm-12 col-md-4 no-padding">
      <a routerLink="/{{NRouting.News}}/{{NewsItem.id}}" target="{{onViewDetailNews()}}">
        <img src="{{StaticURL}}{{NewsItem.thumbnail}}" *ngIf="!NewsThumbnailError" (error)="NewsThumbnailError = true">

        <div class="grid h-full align-items-center" *ngIf="NewsThumbnailError">
          <div class="col-12">
            <div class="ui-logo__footer"></div>
          </div>
        </div>
      </a>
    </div>
    <div class="news-preview__item-content col-7 col-sm-12 col-md-8 no-padding">
      <div class="news-preview__item-content-value">
        <div class="news-preview__item-content-heading" [ngStyle]="{'-webkit-box-orient': 'vertical'}">
          <a routerLink="/{{NRouting.News}}/{{NewsItem.id}}" target="{{onViewDetailNews()}}">{{NewsItem.title}}</a>
        </div>
        <div class="news-preview__item-content-text" [ngStyle]="{'-webkit-box-orient': 'vertical'}">
          <p>{{onTextParser(NewsItem.text)}}</p>
        </div>
      </div>

      <div class="news-preview__item-content-info">
        <div class="grid h-full align-items-center">
          <div class="news-preview__item-content-date col-auto">
            <span class="mr-icon mr-icon-clock"></span>
            <span>{{NewsItem.created | formatDate}}</span>
          </div>
          <!--<div class="news-preview__item-content-favorites col-auto">
            <span class="mr-icon mr-icon-favorites"></span>
            <span>В избранное</span>
          </div>-->

          <div class="col-auto news-preview__item-content-favorites animated fast zoomIn" *ngIf="NewsFavLoading">
            <div class="ui-loader__base"></div>
          </div>
          <div class="col-auto news-preview__item-content-favorites" *ngIf="!NewsFavLoading && !NewsFavActive" (click)="onAddToFavorites()">
            <span class="mr-icon mr-icon-favorites"></span>
            <span>В избранное</span>
          </div>
          <div class="col-auto news-preview__item-content-favorites" *ngIf="!NewsFavLoading && NewsFavActive" (click)="onRemoveFromFavorites()">
            <span class="mr-icon mr-icon-favorites-active"></span>
            <span>В избранном</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="news-detail__item" *ngIf="NewsView === NTypes.Detail">
  <div class="news-detail__item-favorites" *ngIf="!NewsFavActive"
    (click)="onAddToFavorites()">

    <div class="news-detail__item-favorites__background">
      <span class="mr-icon mr-icon-ribbon"></span>
    </div>
    <div class="news-detail__item-favorites__event">
      <span class="mr-icon mr-icon-favorites" *ngIf="!NewsFavLoading"></span>
      <div class="ui-loader__base" *ngIf="NewsFavLoading"></div>
    </div>
    <div class="news-detail__item-favorites__text"
      ngClass="{{NewsFavLoading ? 'hide' : 'view'}}">
      <span class="pt-1">В избранное</span>
    </div>
  </div>

  <div class="news-detail__item-favorites" *ngIf="NewsFavActive"
    (click)="onRemoveFromFavorites()">

    <div class="news-detail__item-favorites__background">
      <span class="mr-icon mr-icon-ribbon"></span>
    </div>
    <div class="news-detail__item-favorites__event">
      <span class="mr-icon mr-icon-favorites-active" *ngIf="!NewsFavLoading"></span>
      <div class="ui-loader__base" *ngIf="NewsFavLoading"></div>
    </div>
    <div class="news-detail__item-favorites__text"
      ngClass="{{NewsFavLoading ? 'hide' : 'view'}}">
      <span class="pt-1">В избранном</span>
    </div>
  </div>

  <div class="row" ngClass="{{(deviceValue | async) === DTypes.Desktop ? 'pt-6 pb-2' : 'pt-4 pb-2'}}">
    <div class="news-detail__item-heading">
      <h1>{{NewsItem.title}}</h1>
    </div>
  </div>

  <div class="row">
    <div class="grid align-items-center justify-content-center"
      ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : ''}}">
      <div class="news-detail__item-icons col-auto">
        <span class="mr-icon mr-icon-clock"></span>
        <span>{{NewsItem.created | formatDate}}</span>
      </div>
      <div class="news-detail__item-icons col-auto">
        <span class="mr-icon mr-icon-view"></span>
        <span>Просмотры: {{NewsItem.viewCount}}</span>
      </div>
      <div class="news-detail__item-icons col-auto">
        <span class="mr-icon mr-icon-favorites"></span>
        <span>В избранном: {{NewsItem.inFavoritesCount}}</span>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="onAdminRole || onModeratorRole">
    <div class="grid h-full align-items-center justify-content-center"
      ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : ''}}">

      <div class="col-auto col-sm-8"
        ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-4 mb-2' : 'mt-4 mb-2 pl-2 pr-2'}}">

        <button class="ui-buttons ui-buttons__gray-small w-full" (click)="onEditNews()">Редактировть</button>
      </div>
      <div class="col-auto col-sm-8"
        ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-2 mb-2' : 'mt-4 mb-2 pl-2 pr-2'}}">

        <button class="ui-buttons ui-buttons__gray-small delete w-full" (click)="onDeleteNews()">Удалить</button>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="news-detail__item-content">
      <div class="ui-wysiwyg" [innerHTML]="onTextSanitize(NewsItem.text)"></div>
    </div>
    <div class="news-detail__item-content" *ngIf="NewsMainPicture">
      <div class="ui-wysiwyg">
        <img src="{{StaticURL}}{{NewsItem.picUrl}}" *ngIf="!NewsThumbnailError" (error)="NewsThumbnailError = true">
      </div>
    </div>
  </div>

  <div class="row">
    <div class="grid align-items-center justify-content-center news-detail__item-events">
      <div class="col-auto news-detail__item-events_element" (click)="onLikeNews()">
        <div class="grid align-items-center" title="Нравится">
          <span class="ui-graphics ui-graphics__like ui-graphics__cta"></span>
          <p>{{NewsItem.likes}}</p>
        </div>
      </div>
      <div class="col-auto news-detail__item-events_element" (click)="onDislikeNews()">
        <div class="grid align-items-center" title="Не нравится">
          <span class="ui-graphics ui-graphics__dislike ui-graphics__cta"></span>
          <p>{{NewsItem.dislikes}}</p>
        </div>
      </div>
    </div>
  </div>
</div>

<p-confirmDialog #default [key]="'default'" [appendTo]="'body'" [styleClass]="'p-dialog__confirm'"
  *ngIf="NewsView === NTypes.Detail && onAdminRole || onModeratorRole">
  <p-footer>
    <button class="ui-buttons ui-buttons__light-small" (click)="default.reject()">Отменить</button>
    <button class="ui-buttons ui-buttons__blue-small" (click)="default.accept()">Удалить</button>
  </p-footer>
</p-confirmDialog>
