import { AppStaticURL, AppPROD } from '@app/app.settings';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RoutingConfig } from '@app/routing/routing.config';
import { DevicesClass } from '@app/core/classes/component/devices.class';
import { INotificationType, INotificationItem } from '@app/modules/notification-modules/notification.model';
import { NotificationService } from '@app/modules/notification-modules/notification.service';

export enum NotificationItemViewTypes {
  MusicianSearchAccepted,     // 0
  MusicianSearchMatch,        // 1
}

@Component({
  selector: 'shared-notification-item',
  templateUrl: 'notification-item.component.html',
  styleUrls: [`./notification-item.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class NotificationItemComponent extends DevicesClass implements OnInit {
  public NIRouting = RoutingConfig;
  public NITypes = NotificationItemViewTypes;
  public StaticURL = AppStaticURL;

  @Input() NotificationItem: INotificationItem;

  constructor(injector: Injector,
    public cdr: ChangeDetectorRef,
    private router: Router,
    private notificationService: NotificationService
  ) {
    super(injector);
  }

  ngOnInit(): void {
  }

  onViewDetailNotification(): string {
    return AppPROD ? '_blank' : '_self';
  }

  onSetReadNotification(): void {
    if (this.NotificationItem.isRead) {
      return;
    }
    this.NotificationItem.isRead = true;

    this.notificationService.markAsReadedNotification(this.NotificationItem.id).subscribe(
      response => this.notificationService.onIniUnreadedCount()
    );
  }

  get notificationType(): number {
    if (this.NotificationItem.type === 'MusicianSearchAccepted' || this.NotificationItem.type === 1) {
      return this.NITypes.MusicianSearchAccepted
    }

    if (this.NotificationItem.type === 'MusicianSearchMatch' || this.NotificationItem.type === 0) {
      return this.NITypes.MusicianSearchMatch
    }
  }
}
