import { AppApiURL } from '@app/app.settings';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { DependenciesResponseModel } from '@app/modules/fundamentals-modules/fundamentals.models';
import { StorageService } from '@app/core/services';
import { HttpRequestToken } from '@app/core/classes';
import { StorageFundamentals } from '@app/core/constants';
import { take, map, timeout } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { PartnerTypeEnum } from '@app/modules/partner-modules/partner.model';
import {
  CitiesModel,
  InstrumentsModel,
  GenresModel,
  PositionsModel,
  ExperiencesModel,
  SwitchersModel,
  PartnersTypeModel
} from '@app/modules/fundamentals-modules/fundamentals.models';


@Injectable()

export class FundamentalsService {
  constructor(
    private http: HttpClient,
    private storage: StorageService
  ) {}


  /**
   * Api: v01.
   * Method: fundamentals
   */
  public Fundamentals(): Observable<DependenciesResponseModel> {
    return this.http.get<DependenciesResponseModel>(AppApiURL + '/fundamentals',
      {
        withCredentials: true,
        observe: 'response',
        reportProgress: false,
        params: new HttpRequestToken(true)
      }
    ).pipe(
      timeout(10000),
      map((response: HttpResponse<any>) => response.body)
    )
  }

  /**
   * Processing of fundamentals dependencies.
   * Save getting data to LocalStorage.
   */
  onUploadDependencies(): Observable<any> {
    return this.Fundamentals().pipe(
      take(1),
      map(response => {
        const SaveDependencies = (key: string, value: any) => {
          this.storage.setStorage({
            key: key,
            value: value
          }, 'session');
        };

        for (const dep of Object.keys(response)) {
          switch (dep) {
            case (StorageFundamentals.instruments) :
              SaveDependencies(dep, response[dep]);
              break;

            case (StorageFundamentals.positions) :
              SaveDependencies(dep, response[dep]);
              break;

            case (StorageFundamentals.genres) :
              SaveDependencies(dep, response[dep]);
              break;

            case (StorageFundamentals.cities) :
              SaveDependencies(dep, response[dep]);
              break;
          }
        }
      })
    )
  }

  /**
   * Get of all Cities Values.
   */
  get citiesDependencies(): CitiesModel {
    const citiesStorage = (
      this.storage.getStorage({ key: StorageFundamentals.cities }, 'local') ||
      this.storage.getStorage({ key: StorageFundamentals.cities }, 'session') ||
      null
    );

    return JSON.parse(citiesStorage);
  }

  /**
   * Get of recommendations Cities Values.
   */
  get citiesRecommendations(): CitiesModel {
    return [
      {name: 'Москва', id: 604},
      {name: 'Санкт-Петербург', id: 825},
      {name: 'Воронеж', id: 182},
      {name: 'Волгоград', id: 169},
      {name: 'Екатеринбург', id: 275},
      {name: 'Казань', id: 352},
      {name: 'Краснодар', id: 465},
      {name: 'Красноярск', id: 477},
      {name: 'Нижний Новгород', id: 646},
      {name: 'Новосибирск', id: 671},
      {name: 'Омск', id: 708},
      {name: 'Пермь', id: 740},
      {name: 'Ростов-на-Дону', id: 806},
      {name: 'Самара', id: 824},
      {name: 'Уфа', id: 1011},
      {name: 'Челябинск', id: 1045}
    ]
  }


  /**
   * Get of all Instruments Values.
   */
  get instrumentsDependencies(): InstrumentsModel {
    const citiesStorage = (
      this.storage.getStorage({ key: StorageFundamentals.instruments }, 'local') ||
      this.storage.getStorage({ key: StorageFundamentals.instruments }, 'session') ||
      null
    );

    return JSON.parse(citiesStorage);
  }

  /**
   * Get of recommendations Instruments Values.
   */
  get instrumentsRecommendations(): InstrumentsModel {
    return [
      {title: 'Акустическая гитара', 'picUrl': null, 'id': 3},
      {title: 'Вокал', 'picUrl': null, 'id': 87},
      {title: 'Пианино', 'picUrl': null, 'id': 46},
      {title: 'Электрогитара', 'picUrl': null, 'id': 63},
      {title: 'Труба', 'picUrl': null, 'id': 120},
      {title: 'Скрипка', 'picUrl': null, 'id': 50},
      {title: 'Бас-гитара', 'picUrl': null, 'id': 78},
      {title: 'Барабаны', 'picUrl': null, 'id': 76},
      {title: 'DJ', 'picUrl': null, 'id': 66},
    ]
  }


  /**
   * Get of all Genres Values.
   */
  get genresDependencies(): GenresModel {
    const citiesStorage = (
      this.storage.getStorage({ key: StorageFundamentals.genres }, 'local') ||
      this.storage.getStorage({ key: StorageFundamentals.genres }, 'session') ||
      null
    );

    return JSON.parse(citiesStorage);
  }

  /**
   * Get of recommendations Genres Values.
   */
  get genresRecommendations(): GenresModel {
    return [
      {id: 804, name: 'Rock', additionalName: 'Рок'},
      {id: 601, name: 'Фолк', additionalName: 'Folk'},
      {id: 648, name: 'Jazz', additionalName: 'Джаз'},
      {id: 680, name: 'Blues', additionalName: 'Блюз'},
      {id: 698, name: 'Funk', additionalName: 'Фанк'},
      {id: 704, name: 'Rap (hip-hop)', additionalName: 'Рэп (хип-хоп)'},
      {id: 814, name: 'Alternative rock', additionalName: 'Альтернативный рок'},
      {id: 836, name: 'Alternative metal', additionalName: 'Альтернативный метал'},
      {id: 847, name: 'NU metal', additionalName: 'Нью-метал'},
      {id: 858, name: 'Punk rock', additionalName: 'Панк рок'},
      {id: 867, name: 'Pop-rock', additionalName: 'Поп-рок'}
    ]
  }


  /**
   * Get of all Positions Values.
   */
  get positionsDependencies(): PositionsModel {
    const positionsStorage = (
      this.storage.getStorage({ key: StorageFundamentals.positions }, 'local') ||
      this.storage.getStorage({ key: StorageFundamentals.positions }, 'session') ||
      null
    );

    const ParsePosition = JSON.parse(positionsStorage);
    return ParsePosition ? ParsePosition.filter(position => position.title !== 'string').sort((a, b) => a.id - b.id) : null
  }

  /**
   * Get of recommendations Experience Values.
   */
  get experienceRecommendations(): ExperiencesModel {
    return [
      {name: '1 год', id: 1},
      {name: '2 года', id: 2},
      {name: '3 года', id: 3},
      {name: 'более 5 лет', id: 5},
      {name: 'более 10 лет', id: 10}
    ]
  }

  /**
   * Get of recommendations Switcher Values.
   */
  get switcherRecommendations(): SwitchersModel {
    return [
      {name: 'Не важно', value: null},
      {name: 'Да', value: true},
      {name: 'Нет', value: false},
    ]
  }

  /**
   * Get of recommendations StartExperience Values.
   */
  get startExperienceRecommendations(): ExperiencesModel {
    return [
      {name: '2000', id: 2000},
      {name: '2001', id: 2001},
      {name: '2002', id: 2002},
      {name: '2003', id: 2003},
      {name: '2004', id: 2004},
      {name: '2005', id: 2005},
      {name: '2006', id: 2006},
      {name: '2007', id: 2007},
      {name: '2008', id: 2008},
      {name: '2009', id: 2009},
      {name: '2010', id: 2010},
      {name: '2011', id: 2011},
      {name: '2012', id: 2012},
      {name: '2013', id: 2013},
      {name: '2014', id: 2014},
      {name: '2015', id: 2015},
      {name: '2016', id: 2016},
      {name: '2017', id: 2017},
      {name: '2018', id: 2018},
      {name: '2019', id: 2019}
    ]
  }

  /**
   * Get of recommendations PartnersTypes Values.
   */
  get partnersTypeRecommendations(): PartnersTypeModel {
    return [
      {name: 'Репетиционная база', namePlural: 'Репетиционные базы', value: PartnerTypeEnum.Base},
      {name: 'Студия звукозаписи', namePlural: 'Студии звукозаписи', value: PartnerTypeEnum.Record},
      {name: 'Музыкальный магазин', namePlural: 'Музыкальные магазины', value: PartnerTypeEnum.Shop},
      {name: 'Музыкальная школа', namePlural: 'Музыкальные школы', value: PartnerTypeEnum.School},
      {name: 'Музыкальная мастерская', namePlural: 'Музыкальные мастерские', value: PartnerTypeEnum.Workshop},
    ]
  }
}
