import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DevicesClass, DeviceTypes } from '@app/core/classes';
import { ReplaySubject } from 'rxjs';
import { IPartnerProfile, IPartnerPublicProduct } from '@app/modules/partner-modules/partner.model';
import { PartnerService } from '@app/modules/partner-modules/partner.service';
import { _deviceType } from '@app/utils/device';
import { DialogIncludesTypes, DialogComponentModel } from '@app/core/models/diallog';
import { DialogService } from 'primeng/dynamicdialog';
import { PartnerEventBus, PartnerStoryService } from '@app/modules/partner-modules/partner.story';
import { NotificationService } from '@app/modules/notification-modules/notification.service';
import { ProductItemViewTypes } from '@app/shared/product-item/product-item.component';
import { ConfirmationService } from 'primeng/api';

/**
 * Components
 */
import { CrudProductComponent } from '@app/modules/partner-modules/crud-product/crud-product.component';


@UntilDestroy()
@Component({
  selector: 'app-account-groups',
  templateUrl: 'products.component.html',
  styleUrls: [`./products.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ProductsComponent extends DevicesClass implements OnInit {
  public PIVTypes = ProductItemViewTypes;
  /**
   * Base component variables.
   */
  public ProductsLoading: boolean;
  public ProductEmpty: boolean;
  public ProductArray: Array<any>;
  public ProductsPartnerSubject: ReplaySubject<IPartnerProfile> = new ReplaySubject<IPartnerProfile>(1);


  constructor(injector: Injector,
    public cdr: ChangeDetectorRef,
    private partnerService: PartnerService,
    private dialogService: DialogService,
    private partnerStory: PartnerStoryService,
    private notificationService: NotificationService,
    private confirmationService: ConfirmationService,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.getPartnerProfile();

    /**
     * Set on partnerStory emit changes.
     */
    this.onPartnerStoryBus();
  }

  onPartnerStoryBus(): void {
    this.partnerStory.PartnerEventBus$.pipe(untilDestroyed(this)).subscribe(
      emit => this.onPartnerStoryListener(emit)
    );
  }

  onPartnerStoryListener(emit: PartnerEventBus): void {
    if (emit.event === 'onProductDelete') {
      this.confirmationService.confirm({
        key: 'default',
        header: 'Удаление',
        message: 'Вы действительно хотите удалить товар?<br/> Восстановить его будет невозможно.',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.onDeleteProductAccept(emit.data);
        }
      });
    }

    if (emit.event === 'onProductEdit') {
      this.onEditProduct(emit.data);
    }

    if (emit.event === 'onProductAddSuccess') {
      this.notificationService.onDialog({
        type: DialogIncludesTypes.PopupSuccessTemplate,
        header: 'Готово',
        content: 'Товар или услуга успешно добавлены.'
      });
      this.getPartnerProfile();
    }

    if (emit.event === 'onProductUpdateSuccess') {
      this.notificationService.onDialog({
        type: DialogIncludesTypes.PopupSuccessTemplate,
        header: 'Готово',
        content: 'Товар или услуга успешно обновлены.'
      });
      this.getPartnerProfile();
    }

    if (emit.event === 'onProductAddError') {
      this.notificationService.onDialog({
        type: DialogIncludesTypes.PopupErrorTemplate,
        header: 'Ошибка',
        content: 'Не удалось добавить товар или услугу.'
      });
    }

    if (emit.event === 'onProductUpdateError') {
      this.notificationService.onDialog({
        type: DialogIncludesTypes.PopupErrorTemplate,
        header: 'Ошибка',
        content: 'Не удалось обновить товар или услугу.'
      });
    }
  }

  getPartnerProfile(): void {
    this.partnerService.partnerProfile().subscribe(
      response => {
        this.ProductsPartnerSubject.next(response);
        this.ProductArray = response.products.reverse();

        this.onToggleLoading(false, 500);
      }
    )
  }

  onToggleLoading(status: boolean, delay: number = 0) {
    setTimeout(() => {
      this.ProductsLoading = status;
      this.cdr.detectChanges();
    }, delay);
  }

  onProductIsEmpty(): boolean {
    return this.ProductArray.length === 0;
  }

  onAddProduct(): void {
    this.dialogService.open(CrudProductComponent, {
      header: 'Добавить товар или услугу',
      width: _deviceType() === DeviceTypes.Mobile ? '90%' : '100%',
      styleClass: 'p-dialog__products',
      data: {
        includeType: DialogIncludesTypes.ComponentTemplate,
        includeData: null
      } as DialogComponentModel
    });
  }

  onEditProduct(product: IPartnerPublicProduct): void {
    this.dialogService.open(CrudProductComponent, {
      header: 'Добавить товар или услугу',
      width: _deviceType() === DeviceTypes.Mobile ? '90%' : '100%',
      styleClass: 'p-dialog__products',
      data: {
        includeType: DialogIncludesTypes.ComponentTemplate,
        includeData: product
      } as DialogComponentModel
    });
  }

  onDeleteProductAccept(product: IPartnerPublicProduct): void {
    this.partnerService.deletePartnerProducts(product.id).subscribe(
      response => {
        this.notificationService.onDialog({
          type: DialogIncludesTypes.PopupSuccessTemplate,
          header: 'Готово',
          content: 'Товар или услуга успешно удалены.'
        });
        this.getPartnerProfile();
      }
    )
  }
}
