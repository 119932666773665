import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DevicesClass } from '@app/core/classes';

@UntilDestroy()
@Component({
  selector: 'app-account-discount',
  templateUrl: 'discount.component.html',
  styleUrls: [`./discount.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class DiscountComponent extends DevicesClass implements OnInit {
  constructor(injector: Injector,
    public cdr: ChangeDetectorRef
  ) {
    super(injector);
  }

  ngOnInit(): void {
  }
}
