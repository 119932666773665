import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit, ViewEncapsulation } from '@angular/core';
import { DevicesClass } from '@app/core/classes';
import { BreadcrumbsDataModel } from '@app/core/models/breadcrumbs';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RoutingConfig } from '@app/routing/routing.config';
import { AccountEventBus, AccountStoryService } from '@app/modules/account-modules/account.story';
import { IAccountUserInfo } from '@app/modules/account-modules/account.model';
import { AccountMenuRolesTypes } from '@app/shared/account-menu/account-menu.component';
import { RolesService } from '@app/core/services';

@UntilDestroy()
@Component({
  selector: 'app-account-shell',
  templateUrl: 'account-shell.component.html',
  styleUrls: [`./account-shell.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})

export class AccountShellComponent extends DevicesClass implements OnInit {
  public AccountMenuRoles = AccountMenuRolesTypes;
  public AccountHeading: string = 'Личный кабинет';
  public BreadcrumbsData: BreadcrumbsDataModel = [
    {
      title: 'Личный кабинет',
      url: '/' + RoutingConfig.Account
    },
    {
      title: 'Профиль пользователя',
      url: '/' + RoutingConfig.AccountUser,
      active: true
    }
  ];

  /**
   * Base component variables.
   */
  public ASUser: IAccountUserInfo = null;

  constructor(injector: Injector,
    public cdr: ChangeDetectorRef,
    public route: ActivatedRoute,
    private router: Router,
    private accountStory: AccountStoryService,
    private rolesService: RolesService,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.onAccountStoryBus();
    this.onSetAccountConfig();

    this.router.events.pipe(untilDestroyed(this)).subscribe((e: RouterEvent) => {
      if (e instanceof NavigationEnd) {
        this.onSetAccountConfig();
      }
    });
  }

  onAccountStoryBus(): void {
    this.accountStory.AccountEventBus$.pipe(untilDestroyed(this)).subscribe(
      emit => this.onAccountStoryListener(emit)
    );
  }

  onAccountStoryListener(emit: AccountEventBus): void {
    if (emit.event === 'onAccountUpdate') {
      this.ASUser = emit.data;
      this.cdr.markForCheck();
    }

    if (emit.event === 'onAccountLogout') {
      this.router.navigateByUrl(
        `/${RoutingConfig.Login}/${RoutingConfig.SignIn}?redirectUrl=${location.pathname}`
      ).catch();
    }
  }

  onSetAccountConfig(): void {
    if (this.onCheckURL(RoutingConfig.AccountUser)) {
      this.onSetBreadcrumbs('Профиль пользователя', RoutingConfig.AccountUser)
    }

    if (this.onCheckURL(RoutingConfig.AccountPartner)) {
      this.onSetBreadcrumbs('Профиль партнера', RoutingConfig.AccountPartner)
    }

    if (this.onCheckURL(RoutingConfig.AccountChangePass)) {
      this.onSetBreadcrumbs('Изменение пароля', RoutingConfig.AccountChangePass)
    }

    if (this.onCheckURL(RoutingConfig.AccountRecoveryPass)) {
      this.onSetBreadcrumbs('Восстановление пароля', RoutingConfig.AccountRecoveryPass)
    }

    if (this.onCheckURL(RoutingConfig.AccountNotify)) {
      this.onSetBreadcrumbs('Уведомления', RoutingConfig.AccountNotify)
    }

    if (this.onCheckURL(RoutingConfig.AccountFavorites)) {
      this.onSetBreadcrumbs('Избранное', RoutingConfig.AccountFavorites)
    }

    if (this.onCheckURL(RoutingConfig.AccountBulletins)) {
      this.onSetBreadcrumbs('Мои объявления', RoutingConfig.AccountBulletins)
    }

    if (this.onCheckURL(RoutingConfig.AccountPoints)) {
      this.onSetBreadcrumbs('Карта музыканта', RoutingConfig.AccountPoints)
    }

    if (this.onCheckURL(RoutingConfig.AccountSettings)) {
      this.onSetBreadcrumbs('Настройки', RoutingConfig.AccountSettings)
    }

    if (this.onCheckURL(RoutingConfig.AccountStatistics)) {
      this.onSetBreadcrumbs('Статистика', RoutingConfig.AccountStatistics)
    }

    if (this.onCheckURL(RoutingConfig.AccountQRScan)) {
      this.onSetBreadcrumbs('Отсканировать QR-код', RoutingConfig.AccountQRScan)
    }
  }

  onSetBreadcrumbs(title: string, url: string): void {
    this.AccountHeading = title;
    this.BreadcrumbsData = [
      {
        title: 'Личный кабинет',
        url: this.onUserRole ? '/' + RoutingConfig.Account : '/' + RoutingConfig.Account + '/' + RoutingConfig.AccountPartner
      },
      {
        title: title,
        url: '/' + url,
        active: true
      }
    ];

    this.cdr.markForCheck();
  }

  onCheckURL(url: string): boolean {
    return location.pathname.split('/').some(route => route === url);
  }

  get onAdminRole(): boolean {
    return this.rolesService.onAdminRole(this.ASUser) || false;
  }

  get onModeratorRole(): boolean {
    return this.rolesService.onModeratorRole(this.ASUser) || false;
  }

  get onUserRole(): boolean {
    return this.rolesService.onUserRole(this.ASUser) || false;
  }

  get onPartnerRole(): boolean {
    return this.rolesService.onPartnerRole(this.ASUser) || false;
  }
}
