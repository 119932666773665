<div class="account-shell__loading animated faster fadeIn" *ngIf="ManagementLoading">
  <div class="ui-loader ui-loader__page-light"></div>
</div>

<div class="account-shell__content"
  ngClass="{{ManagementLoading ? 'loading' : ''}}">

  <div class="pg-wrapper" *ngIf="ManagementPartnerSubject | async as Partner">
    <div class="account-shell__content-wrapper"
      ngClass="{{(deviceValue | async) === DTypes.Mobile ? '' : 'pt-6 pl-3 pr-3'}}">


      <div class="account-shell__content-subtitle">
        <div class="grid align-items-center"
          ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : ''}}">

          <div class="col-8 col-sm-12">
            <h5>Публичный доступ</h5>
            <p class="txt-caption__large">
              Вы можете включить или отключить отображение Вашей страницы в поисковых резльтатах программы лояльности.
            </p>
          </div>
          <div class="col-4 col-sm-12 management__btn">
            <button class="ui-buttons ui-buttons__blue-small" *ngIf="!Partner.isEnabled"
              (click)="onSaveManagement(Partner.isEnabled)">
              Опубликовать
            </button>
            <button class="ui-buttons ui-buttons__light-small" *ngIf="Partner.isEnabled"
              (click)="onSaveManagement(Partner.isEnabled)">
              Снять с публикации
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
