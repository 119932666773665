<div class="account-shell__content">
  <div class="pg-wrapper">
    <div class="account-shell__content-wrapper"
      ngClass="{{(deviceValue | async) === DTypes.Mobile ? '' : 'pl-3 pr-3'}}">

      <div class="account-shell__content-subtitle">
        <h5>Статистика</h5>
        <div class="grid align-items-start pt-2">
          <p class="txt-caption__large">Вы можете отслеживать статистику по совершенным Вами операциям.</p>
        </div>
      </div>
    </div>
  </div>

  <div class="pg-wrapper">
    <div class="account-shell__content-wrapper"
      ngClass="{{(deviceValue | async) === DTypes.Mobile ? '' : 'pl-3 pr-3'}}">

      <div class="statistics__total">
        <div class="statistics__total-table ui-scroll ui-scroll-horizontal">
          <p-table [value]="StatisticsPartnerLists?.items" [styleClass]="'p-table__statistics'">
            <ng-template pTemplate="header">
              <tr>
                <th>Клиент</th>
                <th>Дата</th>
                <th width="150px">Номер карты</th>
                <th class="text-right" width="150px">Начислено баллов</th>
                <th class="text-right" width="150px">Сумма покупки</th>
                <th width="20%">Комментарий</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
              <tr>
                <td>{{item.clientName}}</td>
                <td>{{item.sellDate | formatDate}}</td>
                <td>{{item.loyaltyCardNumber}}</td>
                <td class="text-right">
                  <strong>{{item.points}}</strong>
                </td>
                <td class="text-right">
                  <strong>{{item.price}} RUB</strong>
                </td>
                <td>{{item.commentary}}</td>
              </tr>
            </ng-template>
          </p-table>
          <div class="statistics__total-table__cup" *ngIf="!StatisticsPartnerLists?.totalCount">
            <div class="grid align-items-baseline justify-content-center"
              ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'pt-4 pb-4' : 'pt-6 pb-6'}}">

              <span class="pi pi-exclamation-circle mr-2"></span>
              <h5>Нет данных для отображения</h5>
            </div>
          </div>
        </div>

        <div class="row statistics__pagination" *ngIf="StatisticsPartnerLists?.totalCount">
          <div *ngFor="let item of [] | paginate: {
                  itemsPerPage: 1,
                  currentPage: StatisticsPartnerLists?.pageIndex,
                  totalItems: StatisticsPartnerLists?.totalPages
              }">
          </div>
          <pagination-controls
            class="ui-pagination"
            (pageChange)="onPageChange($event)"
            responsive="true"
            maxSize="5"
            previousLabel="« Назад"
            nextLabel="Вперед »">
          </pagination-controls>
        </div>

      </div>
    </div>
  </div>
</div>
