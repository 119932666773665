import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, Input, OnInit } from '@angular/core';
import { DevicesClass } from '@app/core/classes';
import { RoutingConfig } from '@app/routing/routing.config';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DialogComponentModel, DialogIncludesTypes } from '@app/core/models/diallog';
import { IPartnerProduct } from '@app/modules/partner-modules/partner.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FileToBase64 } from '@app/utils/converter';
import { NotificationService } from '@app/modules/notification-modules/notification.service';
import { PartnerService } from '@app/modules/partner-modules/partner.service';
import { PartnerStoryService } from '@app/modules/partner-modules/partner.story';
import { AppStaticURL } from '@app/app.settings';

export interface BasePhotoModel {
  rowString: string;
  src: string;
}

export interface BaseErrorModel {
  status: boolean;
  value: string;
}

export interface ProductErrorModels {
  title: BaseErrorModel;
  price: BaseErrorModel;
  description: BaseErrorModel;
}

export enum PartnerProductTypes {
  Create,         // 0
  Edit,           // 1
}

declare var $;

@UntilDestroy()
@Component({
  selector: 'app-partner-crud-product',
  templateUrl: 'crud-product.component.html',
  styleUrls: [`./crud-product.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CrudProductComponent extends DevicesClass implements OnInit {
  public StaticURL = AppStaticURL;
  public Routing = RoutingConfig;
  public ProductTypes = PartnerProductTypes;

  /**
   * Base component variables.
   */
  public Product: IPartnerProduct;
  public ProductLoading: boolean = false;
  public ProductForm: FormGroup;

  /**
   * Profile photos.
   */
  public ProductPhotos: Array<BasePhotoModel> = [];

  /**
   * Errors view variables
   */
  public ErrorsField: ProductErrorModels;
  public ErrorsText: string;

  /**
   * Special component variables.
   */
  public fileAcceptString = 'image/x-png,image/gif,image/jpeg';
  public maskOptions = {
    align: 'left',
    prefix: '',
    thousands: ' ',
    decimal: '',
    precision: 0,
    max: 1000000
  };

  @Input() Data: any;

  constructor(injector: Injector,
    public cdr: ChangeDetectorRef,
    public ref: DynamicDialogRef,
    private formBuilder: FormBuilder,
    private router: Router,
    private dialogConfig: DynamicDialogConfig,
    private notificationService: NotificationService,
    private partnerService: PartnerService,
    private partnerStory: PartnerStoryService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.ProductForm = this.formBuilder.group({
      title: ['', [Validators.required, Validators.minLength(5)]],
      position: [1],
      price: [null, Validators.required],
      description: ['', [Validators.required, Validators.minLength(5)]],
      minimumHours: [0],
      picKeys: [[]]
    });

    /**
     * Set of default errors value.
     */
    this.ErrorsField = {
      title: {status: false, value: ''},
      price: {status: false, value: ''},
      description: {status: false, value: ''}
    };

    /**
     * Set of base popup settings.
     */
    this.onProductInputConfig();

    /**
     * Form changes control.
     */
    this.onProductFormCtrl();

    /**
     * Set input product's data.
     */
    this.onProductSetData();
  }

  onProductInputConfig(): void {
    const InputConfig = this.dialogConfig.data as DialogComponentModel || null;
    this.Product = InputConfig.includeData as any || null;
  }

  onProductFormCtrl(): void {
    for (const field of Object.keys(this.ErrorsField)) {
      this.ProductForm.valueChanges.pipe(untilDestroyed(this)).subscribe(
        value => {
          this.ErrorsField[field].status = false;
          this.ErrorsText = '';
          this.onToggleViewErrors(false);
        }
      );
    }
  }

  onProductSetData(): void {
    if (!this.Product) {
      return;
    }
    for (const key of Object.keys(this.Product)) {
      if (key === 'title') {
        this.productFormCtrl.title.setValue(this.Product[key]);
      }
      if (key === 'description') {
        this.productFormCtrl.description.setValue(this.Product[key]);
      }
      if (key === 'price') {
        this.productFormCtrl.price.setValue(this.Product[key]);
      }
      if (key === 'photos') {
        if (this.Product[key] && this.Product[key].length) {
          this.productFormCtrl.picKeys.setValue(this.Product[key][0].path);
          this.ProductPhotos.unshift({
            rowString: this.Product[key][0].path,
            src: this.StaticURL + this.Product[key][0].path
          });
        }

      }
    }
  }

  /**
   * Upload and remove profile photos handlers.
   */
  onUploadPhotoHandler(): void {
    const UploadInput = document.getElementById('photoUpload');
    UploadInput.click();
  }

  onUploadPhotoEvent(files: FileList): void {
    for (const i of Object.keys(files)) {
      const File: File = files[i];

      if ((File.size / 1024) > 1024) {
        this.ErrorsText = 'Превышен размер загружаемого файла. Ограничение на загрузку - 1Mb.';
        return this.onToggleViewErrors(true);
      }

      FileToBase64(File).then((value: string) => {
        this.partnerService.uploadPic(files).subscribe(
          response => {
            this.ProductPhotos.unshift({
              rowString: response,
              src: value
            });
            this.cdr.markForCheck();
          }
        );
      });
    }
  }

  onRemovePhotoEvent(index: number): void {
    this.ProductPhotos.splice(index, 1);
    this.cdr.markForCheck();
  }


  onToggleLoading(status: boolean, delay: number = 0) {
    setTimeout(() => {
      this.ProductLoading = status;
      this.cdr.detectChanges();
    }, delay);
  }

  onToggleViewErrors(show: boolean): void {
    const FieldErrors = $('.__product__errors');
    show ? FieldErrors.slideDown(150) : FieldErrors.slideUp(150);
  }

  onCloseDialog(): void {
    this.ref.close()
  }

  onErrorsCallback(error): void {
    const dialogContainer = document.querySelectorAll('.p-dialog') || [];

    /**
     * Reset loading spinner.
     */
    this.onToggleLoading(false);

    if (error.statusCode === 400) {
      return this.notificationService.onDialog({
        type: DialogIncludesTypes.PopupErrorTemplate,
        header: 'Ошибка',
        content: error.message
      });
    }

    if (error.hasOwnProperty('failures')) {
      for (const key of Object.keys(error.failures)) {
        const Failures = error.failures[key];

        if (this.ErrorsField.hasOwnProperty(key)) {
          this.ErrorsField[key].status = true;
          this.ErrorsField[key].value = Failures;
        }
      }
    }

    if (error.hasOwnProperty('message')) {
      this.ErrorsText = error.message + '!';
      this.onToggleViewErrors(true);
    }
  }

  onSubmitForm(): void {
    if (this.ProductLoading) {
      return;
    }

    /**
     * Validation of Errors Fields.
     */
    for (const field of Object.keys(this.ErrorsField)) {
      if (this.ErrorsField[field].status) {
        this.ErrorsText = 'Проверьте правильность заполненных полей!';
        return this.onToggleViewErrors(true);
      }
    }

    /**
     * Validation of Base Fields.
     */
    if (!this.ProductForm.valid) {
      for (const control of Object.keys(this.productFormCtrl)) {
        if (this.ErrorsField.hasOwnProperty(control) && !this.productFormCtrl[control].valid) {
          this.ErrorsField[control].status = true;
        }
      }
      this.ErrorsText = 'Проверьте правильность заполненных полей!';
      return this.onToggleViewErrors(true);
    }

    /**
     * Loading
     */
    this.onToggleLoading(true);


    /**
     * Submit request
     */
    const photosArray = this.ProductPhotos.map(item => item.rowString);
    const productData = {
      title: this.ProductForm.getRawValue().title,
      position: this.ProductForm.getRawValue().position,
      price: this.ProductForm.getRawValue().price,
      description: this.ProductForm.getRawValue().description,
      picKeys: photosArray
    } as IPartnerProduct;


    /**
     * Create new product
     */
    if (this.onProductEventType === this.ProductTypes.Create) {
      this.partnerService.addPartnerProducts(productData).subscribe(
        response => this.onProductEventStatus(true),
        error => {
          this.onErrorsCallback(error);
        }
      );
    }

    /**
     * Edit of product
     */
    if (this.onProductEventType === this.ProductTypes.Edit) {
      this.partnerService.editPartnerProducts(Object.assign({id: this.Product.id}, productData)).subscribe(
        response => this.onProductEventStatus(true),
        error => {
          this.onErrorsCallback(error);
        }
      );
    }
  }

  onProductEventStatus(status: boolean): void {
    this.onCloseDialog();

    if (this.onProductEventType === this.ProductTypes.Create) {
      this.partnerStory.PartnerEventBus.next({
        event: status ? 'onProductAddSuccess' : 'onProductAddError',
        data: null
      })
    }

    if (this.onProductEventType === this.ProductTypes.Edit) {
      this.partnerStory.PartnerEventBus.next({
        event: status ? 'onProductUpdateSuccess' : 'onProductUpdateError',
        data: null
      })
    }
  }

  get productFormCtrl(): any {
    return this.ProductForm.controls;
  }

  get onProductEventType(): PartnerProductTypes {
    return this.Product ? this.ProductTypes.Edit : this.ProductTypes.Create;
  }
}
