import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DevicesClass } from '@app/core/classes';
import { LoyaltyService } from '@app/modules/loyalty-modules/loyalty.service';
import { ILoyaltyPurchaseLists } from '@app/modules/loyalty-modules/loyalty.model';
import { AccountEventBus, AccountStoryService } from '@app/modules/account-modules/account.story';
import { IAccountUserInfo } from '@app/modules/account-modules/account.model';
import { RoutingConfig } from '@app/routing/routing.config';


@UntilDestroy()
@Component({
  selector: 'app-account-points',
  templateUrl: 'points.component.html',
  styleUrls: [`./points.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class PointsComponent extends DevicesClass implements OnInit {
  public Routing = RoutingConfig;

  /**
   * Base component variables
   */
  public PUser: IAccountUserInfo;
  public PointsCode: string;
  public PointsQRCode: string;
  public PointsBalance: number;
  public PointsPurchaseLists: ILoyaltyPurchaseLists;

  constructor(injector: Injector,
    public cdr: ChangeDetectorRef,
    private loyaltyService: LoyaltyService,
    private accountStory: AccountStoryService,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.onAccountStoryBus();
    this.onPointsLoyaltyCart();
    this.onPointsPurchaseHistory(1);
  }

  onAccountStoryBus(): void {
    this.accountStory.AccountEventBus$.pipe(untilDestroyed(this)).subscribe(
      emit => this.onAccountStoryListener(emit)
    );
  }

  onAccountStoryListener(emit: AccountEventBus): void {
    if (emit.event === 'onAccountUpdate') {
      this.PUser = emit.data;
    }
    this.cdr.markForCheck();
  }

  onPointsLoyaltyCart(): void {
    this.loyaltyService.loyaltyCart().subscribe(
      response => {
        this.PointsCode = response.code;
        this.PointsQRCode = 'data:image/jpeg;base64,' + response.qrBase64;
        this.PointsBalance = response.balance;

        this.cdr.markForCheck();
      }
    )
  }

  onPointsPurchaseHistory(page: number): void {
    this.loyaltyService.loyaltyPurchaseHistory(page).subscribe(
      response => {
        this.PointsPurchaseLists = response;
        this.cdr.markForCheck();
      }
    )
  }

  onPageChange(event: number): void {
    this.onPointsPurchaseHistory(event);
  }
}
