import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'metroDistance'
})

export class MetroDistancePipe implements PipeTransform {
  constructor() {
  }

  transform(distance: number): string {
    if (distance < 1) {
      return `(${distance * 1000} м)`
    }
    if (distance >= 1) {
      return `(${distance} км)`
    }
  }
}
