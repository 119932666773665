import { AppStaticURL } from '@app/app.settings';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DevicesClass, DeviceTypes } from '@app/core/classes';
import { PartnerService } from '@app/modules/partner-modules/partner.service';
import { AddressService } from '@app/modules/address-modules/address.service';
import { IAccountUserInfo } from '@app/modules/account-modules/account.model';
import { AccountEventBus, AccountStoryService } from '@app/modules/account-modules/account.story';
import { RoutingConfig } from '@app/routing/routing.config';
import { AccountService } from '@app/modules/account-modules/account.service';
import { InputValidators } from '@app/core/validators/input.validator';
import { DialogComponentModel, DialogIncludesTypes } from '@app/core/models/diallog';
import { NotificationService } from '@app/modules/notification-modules/notification.service';
import { CityModel, PartnersTypeModel, PartnerTypeModel } from '@app/modules/fundamentals-modules/fundamentals.models';
import { CitiesService } from '@app/modules/cities-module/cities.service';
import { SearchCityComponent } from '@app/modules/cities-module/search-city/search-city.component';
import { DialogService } from 'primeng/dynamicdialog';
import { FundamentalsService } from '@app/modules/fundamentals-modules/fundamentals.service';
import { FBLinkValidate, INSTLinkValidate, PhoneNumberValidate, VKLinkValidate } from '@app/utils/validator';
import { _deviceType } from '@app/utils/device';
import { ReplaySubject, forkJoin, Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import * as moment from 'moment';
import {
  DateToHoursString,
  FBRequestFormatted,
  FileToBase64,
  ObjectRequestFormatted,
  PhoneRequestFormatted,
  VKRequestFormatted
} from '@app/utils/converter';
import {
  PartnerTypeEnum,
  IPartnerProfile,
  IPartnerUserContacts,
  IPartnerPutProfile,
  IPartnerWorkingHours,
  IPartnerAdditionalAmenities,
  IPartnerPutDiscount
} from '@app/modules/partner-modules/partner.model';
import { ConfirmationService } from 'primeng/api';

declare var $;

export interface BaseErrorModel {
  status: boolean;
  value: string;
}
export interface BaseSuggestModel {
  value: string;
  empty?: boolean
}
export interface BasePhotoModel {
  rowString: string;
  src: string;
}
export interface ProfileErrorModels {
  title: BaseErrorModel;
  email: BaseErrorModel;
  siteUrl: BaseErrorModel;
  fullAddress: BaseErrorModel;
  address: BaseErrorModel;
  name: BaseErrorModel;
  discountAmount: BaseErrorModel;
  cityId: BaseErrorModel;
  contactPhone: BaseErrorModel;
  vkUsername: BaseErrorModel;
  facebookUsername: BaseErrorModel;
  instagramUsername: BaseErrorModel;
}

@UntilDestroy()
@Component({
  selector: 'app-account-profile-partner',
  templateUrl: 'profile-partner.component.html',
  styleUrls: [`./profile-partner.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ProfilePartnerComponent extends DevicesClass implements OnInit {
  public StaticURL = AppStaticURL;
  public PartnerType = PartnerTypeEnum;

  /**
   * Base component variables.
   */
  public ProfileLoading: boolean;
  public ProfileAccountForm: FormGroup;
  public ProfilePartnerForm: FormGroup;
  public ProfileContactsForm: FormGroup;
  public ProfileWorkingHoursForm: FormGroup;
  public ProfileAdditionalAmenitiesForm: FormGroup;

  public ProfileAccountFormModify: boolean = false;
  public ProfilePartnerFormModify: boolean = false;
  public ProfilePartnerTypeModify: boolean = false;
  public ProfilePhotosFormModify: boolean = false;


  public ProfileAccountData: IAccountUserInfo = null;
  public ProfileAccountSubject: ReplaySubject<IAccountUserInfo> = new ReplaySubject<IAccountUserInfo>(1);
  public ProfilePartnerSubject: ReplaySubject<IPartnerProfile> = new ReplaySubject<IPartnerProfile>(1);

  /**
   * Profile city.
   */
  public ProfileCitySubject: ReplaySubject<CityModel> = new ReplaySubject<CityModel>(1);
  public ProfileCityValue: CityModel = null;

  /**
   * Profile avatar.
   */
  public ProfileAvatar: string;
  public ProfileAvatarFile: FileList = null;
  public ProfileAvatarDelete: boolean;

  /**
   * Profile partner type.
   */
  // public ProfilePartnerType: PartnerTypeModel = null;
  public ProfilePartnerTypes: PartnersTypeModel = this.fundamentalsService.partnersTypeRecommendations;
  public ProfilePartnerTypeSelected: PartnerTypeModel = null;
  public ProfilePartnerTypeDefault: PartnerTypeModel = null;

  /**
   * Profile address suggest.
   */
  public ProfileSuggestData: Array<BaseSuggestModel> = [];

  /**
   * Profile photos.
   */
  public ProfilePhotos: Array<BasePhotoModel> = [];

  /**
   * Errors view variables
   */
  public ErrorsField: ProfileErrorModels;
  public ErrorsText: string;

  /**
   * Special component variables.
   */
  public fileAcceptString = 'image/x-png,image/gif,image/jpeg';

  constructor(injector: Injector,
    public cdr: ChangeDetectorRef,
    private router: Router,
    private formBuilder: FormBuilder,
    private inputValidators: InputValidators,
    private partnerService: PartnerService,
    private addressService: AddressService,
    private accountService: AccountService,
    private accountStory: AccountStoryService,
    private citiesService: CitiesService,
    private notificationService: NotificationService,
    private dialogService: DialogService,
    private fundamentalsService: FundamentalsService,
    private confirmationService: ConfirmationService,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    /**
     * Build of profile account form.
     */
    this.ProfileAccountForm = this.formBuilder.group({
      id : ['', Validators.required],
      name: ['', Validators.required],
      email : ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])],
      // picPath : [''],
      cityId : [0, Validators.required],
    });

    /**
     * Build of profile partner form.
     */
    this.ProfilePartnerForm = this.formBuilder.group({
      title: ['', Validators.required],
      address: ['', Validators.required],
      description: ['', Validators.required],
      discountAmount: ['', Validators.required],
      siteUrl: ['', {validators: [this.inputValidators.checkURLValue]}]
    });

    /**
     * Build of profile partner form.
     */
    this.ProfileContactsForm = this.formBuilder.group({
      vkUsername: ['', {validators: [this.inputValidators.checkVKValue]}],
      facebookUsername: ['', {validators: [this.inputValidators.checkFBValue]}],
      instagramUsername: ['', {validators: [this.inputValidators.checkINSTValue]}],
      contactPhone: [null],
      hasTelegram: [false],
      hasViber: [false],
      hasWhatsapp: [false]
    });

    /**
     * Build of profile additional amenities.
     */
    this.ProfileAdditionalAmenitiesForm = this.formBuilder.group({
      coffeeVendingMachine: [false],
      conditioner: [false],
      instrumentsRent: [false],
      recording: [false],
      smokingRoom: [false],
      tea: [false],
      toilet: [false],
      wiFi: [false]
    });

    /**
     * Build of profile partner form.
     */
    const workingHourGroup = {
      from: [null],
      to: [null],
      breakFrom: [null], breakTo: [null]
    };
    this.ProfileWorkingHoursForm = this.formBuilder.group({
      monday: this.formBuilder.group(workingHourGroup),
      tuesday: this.formBuilder.group(workingHourGroup),
      wednesday: this.formBuilder.group(workingHourGroup),
      thursday: this.formBuilder.group(workingHourGroup),
      friday: this.formBuilder.group(workingHourGroup),
      sunday: this.formBuilder.group(workingHourGroup),
      saturday: this.formBuilder.group(workingHourGroup),
    });

    /**
     * Set of default errors value.
     */
    this.ErrorsField = {
      title: {status: false, value: ''},
      email: {status: false, value: ''},
      siteUrl: {status: false, value: ''},
      fullAddress: {status: false, value: ''},
      address: {status: false, value: ''},
      name: {status: false, value: ''},
      discountAmount: {status: false, value: ''},
      cityId: {status: false, value: ''},
      contactPhone: {status: false, value: 'Неправильный формат телефона'},
      vkUsername: {status: false, value: 'Неправильный формат ссылки'},
      facebookUsername: {status: false, value: 'Неправильный формат ссылки'},
      instagramUsername: {status: false, value: 'Неправильный формат ссылки'}
    };

    /**
     * Get of profile data.
     */
    this.onAccountStoryBus();

    /**
     * Form changes control.
     */
    this.onProfileAccountCtrl();
    this.onProfilePartnerCtrl();
    this.onProfileContactsCtrl();
    this.onProfileWorkingHoursCtrl();
    this.onProfileAdditionalAmenities();
    this.onCityCtrl();
  }

  onAccountStoryBus(): void {
    this.accountStory.AccountEventBus$.pipe(untilDestroyed(this)).subscribe(
      emit => this.onAccountStoryListener(emit)
    );
  }

  onAccountStoryListener(emit: AccountEventBus): void {
    if (emit.event === 'onAccountUpdate') {
      this.onSetProfilePartner();
      this.onSetProfileUserInfo();
      this.onSetProfileAccount(emit.data as IAccountUserInfo);
    }

    if (emit.event === 'onAccountLogout' || emit.event === 'onAccountEmpty') {
      this.router.navigateByUrl(`/${RoutingConfig.Login}/${RoutingConfig.SignIn}?redirectUrl=${location.pathname}`).catch();
    }
  }


  /**
   * Set of profile data
   */
  onSetProfilePartner(): void {
    this.partnerService.partnerProfile().subscribe(
      response => {
        this.ProfilePartnerSubject.next(response);

        for (const key of Object.keys(response)) {
          switch (key) {
            case ('title') :
              this.ProfilePartnerForm.controls['title'].setValue(response[key], { emitEvent: false });
            break;

            case ('siteUrl') :
              this.ProfilePartnerForm.controls['siteUrl'].setValue(response[key], { emitEvent: false });
            break;

            case ('discountAmount') :
              this.ProfilePartnerForm.controls['discountAmount'].setValue(response[key], { emitEvent: false });
            break;

            case ('contacts') :
              for (const contact of Object.keys(response[key])) {
                if (this.onContactsFormControl[contact]) {
                  if (!response[key][contact]) {
                    continue;
                  }

                  if (contact === 'contactPhone') {
                    if (Number(response[key][contact]) === 0) {
                      continue;
                    }
                    this.onContactsFormControl[contact].setValue(
                      '7' + response[key][contact], { emitEvent: false }
                    );
                  }

                  if (contact === 'vkUsername') {
                    this.onContactsFormControl[contact].setValue(
                      'vk.com/' + response[key][contact], { emitEvent: false }
                    );
                  }

                  if (contact === 'facebookUsername') {
                    this.onContactsFormControl[contact].setValue(
                      'fb.com/' + response[key][contact], { emitEvent: false }
                    );
                  }

                  if (contact === 'instagramUsername') {
                    this.onContactsFormControl[contact].setValue(
                      response[key][contact], { emitEvent: false }
                    );
                  }

                  if (contact === 'hasTelegram') {
                    this.onContactsFormControl[contact].setValue(
                      response[key][contact], { emitEvent: false }
                    );
                  }

                  if (contact === 'hasViber') {
                    this.onContactsFormControl[contact].setValue(
                      response[key][contact], { emitEvent: false }
                    );
                  }

                  if (contact === 'hasWhatsapp') {
                    this.onContactsFormControl[contact].setValue(
                      response[key][contact], { emitEvent: false }
                    );
                  }
                }
              }
          break;

            case('additionalInfoVm'):
              if (response[key].workingHours) {
                this.onSetWorkingHoursForm(response[key].workingHours.timetable);
              }
              if (response[key].description) {
                this.ProfilePartnerForm.controls['description'].setValue(response[key].description, { emitEvent: false });
              }
              if (response[key].address) {
                this.ProfilePartnerForm.controls['address'].setValue(response[key].address.fullAddress, { emitEvent: false });
              }
              if (response[key].logoPath) {
                this.ProfileAvatar = response[key].logoPath ? AppStaticURL + response[key].logoPath : null;
              }
              if (response[key].additionalAmenities) {
                this.onSetAdditionalAmenities(response[key].additionalAmenities);
              }
              if (response[key].picPaths) {
                this.onSetPhotoHandler(response[key].picPaths);
              }
              this.cdr.markForCheck();
            break;
          }
        }
      }
    );
  }

  onSetProfileUserInfo(): void {
    this.partnerService.partnerUserInfo().subscribe(
      response => {
        this.ProfilePartnerTypeSelected = this.fundamentalsService.partnersTypeRecommendations[response.partnerType];
        this.ProfilePartnerTypeDefault = this.fundamentalsService.partnersTypeRecommendations[response.partnerType];
        this.cdr.markForCheck();
      }
    )
  }

  onSetProfileAccount(response: IAccountUserInfo): void {
    this.ProfileAccountData = response;
    this.ProfileAccountSubject.next(response);

    for (const key of Object.keys(response)) {
      switch (key) {
        case ('name') :
          this.ProfileAccountForm.controls['name'].setValue(response[key], { emitEvent: false });
        break;

        case ('cityId') :
          this.onCityApply(response[key]);
        break;

        default:
          if (this.ProfileAccountForm.controls.hasOwnProperty(key)) {
            this.ProfileAccountForm.controls[key].setValue(response[key], { emitEvent: false });
          }
      }
    }
  }


  /**
   * Profile form changes
   */
  onProfileAccountCtrl(): void {
    this.ProfileAccountForm.valueChanges.pipe(untilDestroyed(this)).subscribe(
      v => {
        this.onResetErrorFields();
        this.onToggleViewErrors(false);
        this.ProfileAccountFormModify = true;
        this.cdr.markForCheck();
      }
    );
  }
  
  onProfilePartnerCtrl(): void {
    this.ProfilePartnerForm.valueChanges.pipe(untilDestroyed(this)).subscribe(
      v => {
        this.onResetErrorFields();
        this.onToggleViewErrors(false);
        this.ProfilePartnerFormModify = true;
        this.cdr.markForCheck();
      }
    );
    this.ProfilePartnerForm.controls.address.valueChanges.pipe(
      untilDestroyed(this),
      debounceTime(500)
    ).subscribe(
      value => {
        this.addressService.getAddressSugges(value).subscribe(
          response => {
            !response.suggestions.length ?
              this.ProfileSuggestData.push({
                value: 'Ничего не найдено. Проверьте правильность адреса.',
                empty: true
              }) :
              this.ProfileSuggestData = response.suggestions.map(suggest => {
                return {
                  value: suggest.value
                }
              });

            this.cdr.markForCheck();
          }
        );
      }
    )
  }

  onProfileContactsCtrl(): void {
    this.ProfileContactsForm.valueChanges.pipe(untilDestroyed(this)).subscribe(
      v => {
        this.onResetErrorFields();
        this.onToggleViewErrors(false);
        this.ProfilePartnerFormModify = true;
        this.cdr.markForCheck();
      }
    );
  }

  onProfileWorkingHoursCtrl(): void {
    this.ProfileWorkingHoursForm.valueChanges.pipe(untilDestroyed(this)).subscribe(
      v => {
        this.onResetErrorFields();
        this.onToggleViewErrors(false);
        this.ProfilePartnerFormModify = true;
        this.cdr.markForCheck();
      }
    );
  }

  onProfileAdditionalAmenities(): void {
    this.ProfileAdditionalAmenitiesForm.valueChanges.pipe(untilDestroyed(this)).subscribe(
      v => {
        this.onResetErrorFields();
        this.onToggleViewErrors(false);
        this.ProfilePartnerFormModify = true;
        this.cdr.markForCheck();
      }
    );
  }


  /**
   * Cities handler
   */
  onCityCtrl(): void {
    this.ProfileCitySubject.pipe(untilDestroyed(this)).subscribe(
      value => {
        this.ProfileCityValue = value;
        this.ProfileAccountForm.controls.cityId.setValue(value.id);

        this.cdr.markForCheck();
      }
    );
  }

  onCityApply(id: number): void {
    const CiyValue = this.citiesService.onGetCityByDictionary(id) || null;

    if (CiyValue) {
      this.ProfileCityValue = CiyValue;
      this.ProfileAccountForm.controls.cityId.setValue(CiyValue.id, { emitEvent: false });
    }
  }

  onDropdownPartnerTypeCtrl(partnerType: PartnerTypeModel): void {
    this.confirmationService.confirm({
      key: 'default',
      header: 'Изменить вид деятельности?',
      message: 'После сохранения нового вида деятельности, все созданные ранее продукты будут удалены!',
      icon: 'pi pi-exclamation-triangle',
      reject: () => {
        this.ProfilePartnerTypeSelected = this.ProfilePartnerTypeDefault;
        this.cdr.markForCheck();
      },
      accept: () => {
        this.ProfilePartnerTypeModify = true;
        this.cdr.markForCheck();
      }
    });
  }

  onViewSearchCities(): void {
    this.dialogService.open(SearchCityComponent, {
      header: 'Выбрать город',
      width: _deviceType() === DeviceTypes.Mobile ? '90%' : '100%',
      styleClass: 'p-dialog__city-search',
      data: {
        includeType: DialogIncludesTypes.ComponentTemplate,
        includeData: {
          dataInitial: this.ProfileCityValue,
          dataSubject: this.ProfileCitySubject
        }
      } as DialogComponentModel
    });
  }


  /**
   * Suggest handler
   */
  onApplySuggest(suggest: BaseSuggestModel): void {
    if (suggest.empty) {
      return;
    }
    this.ProfilePartnerForm.controls.address.patchValue(suggest.value, { emitEvent: false });
    this.ProfileSuggestData = [];
  }


  /**
   * Upload and remove avatar handlers.
   */
  onUploadAvatarHandler(): void {
    const UploadInput = document.getElementById('avatarUpload');
    UploadInput.click();
  }

  onUploadAvatarEvent(files: FileList): void {
    for (const i of Object.keys(files)) {
      const File: File = files[i];

      if ((File.size / 1024) > 1024) {
        return this.notificationService.onDialog({
          type: DialogIncludesTypes.PopupErrorTemplate,
          header: 'Ошибка загрузки',
          content: 'Превышен размер загружаемого файла. Ограничение на загрузку - 1Mb.',
        });
      }

      FileToBase64(File).then((value: string) => {
        this.ProfileAvatarFile = files;
        this.ProfileAccountFormModify = true;
        this.ProfileAvatar = value;
        this.cdr.markForCheck();
      });
    }
  }

  onUploadAvatarClear(): void {
    this.ProfileAvatarDelete = true;
    this.ProfileAvatar = null;
    this.ProfileAccountFormModify = true;
    this.cdr.markForCheck();
  }

  onAvatarError() {
    this.ProfileAvatar = null;
    this.cdr.markForCheck();
  }


  /**
   * Upload and remove profile photos handlers.
   */
  onUploadPhotoHandler(): void {
    const UploadInput = document.getElementById('photoUpload');
    UploadInput.click();
  }

  onUploadPhotoEvent(files: FileList): void {
    for (const i of Object.keys(files)) {
      const File: File = files[i];

      if ((File.size / 1024) > 1024) {
        return this.notificationService.onDialog({
          type: DialogIncludesTypes.PopupErrorTemplate,
          header: 'Ошибка загрузки',
          content: 'Превышен размер загружаемого файла. Ограничение на загрузку - 1Mb.',
        });
      }

      FileToBase64(File).then((value: string) => {
        this.partnerService.uploadPic(files).subscribe(
          response => {
            this.ProfilePhotosFormModify = true;
            this.ProfilePhotos.unshift({
              rowString: response,
              src: value
            });
            this.cdr.markForCheck();
          }
        );
      });
    }
  }

  onRemovePhotoEvent(index: number): void {
    this.ProfilePhotos.splice(index, 1);
    this.ProfilePhotosFormModify = true;
    this.cdr.markForCheck();
  }

  onSetPhotoHandler(picPaths: Array<string>): void {
    this.ProfilePhotos = [];

    for (const i of Object.keys(picPaths)) {
      this.ProfilePhotos.unshift({
        rowString: picPaths[i],
        src: AppStaticURL + picPaths[i]
      });
    }
    this.cdr.markForCheck();
  }


  /**
   * WorkHour information handler
   */
  onSetWorkingHoursForm(timetable: any): void {
    for (const ctrl of Object.keys(timetable)) {
      const _timetable = timetable[ctrl];

      if (this.ProfileWorkingHoursForm.controls[ctrl]) {
        this.ProfileWorkingHoursForm.controls[ctrl]['controls'].from.patchValue(
          moment(_timetable.from, 'HH:mm:ss').toDate(), { emitEvent: false }
        );

        this.ProfileWorkingHoursForm.controls[ctrl]['controls'].to.patchValue(
          moment(_timetable.to, 'HH:mm:ss').toDate(), { emitEvent: false }
        );

        this.ProfileWorkingHoursForm.controls[ctrl]['controls'].breakFrom.patchValue(
          _timetable.breakFrom ? moment(_timetable.breakFrom, 'HH:mm:ss').toDate() : null, {
            emitEvent: false
          }
        );

        this.ProfileWorkingHoursForm.controls[ctrl]['controls'].breakTo.patchValue(
          _timetable.breakTo ? moment(_timetable.breakTo, 'HH:mm:ss').toDate() : null, {
            emitEvent: false
          }
        );
      }
    }
  }

  onCheckProfileWorkingHoursForm(control: string): boolean {
    return !!this.ProfileWorkingHoursForm.controls[control] || null;
  }

  /**
   * WorkHour information handler
   */
  onSetLimitesValues(event: Event): void {
    const $target: any = event.target;

    try {
      if (Number($target.value) > 80) {
        this.ProfilePartnerForm.controls['discountAmount'].setValue(80);
      }
      if (Number($target.value) < 2) {
        this.ProfilePartnerForm.controls['discountAmount'].setValue(2);
      }
    } catch (e) {}
  }

  /**
   * AdditionalAmenities handler
   */
  onSetAdditionalAmenities(additionalAmenities: IPartnerAdditionalAmenities) {
    for (const key of Object.keys(additionalAmenities)) {
      if (this.ProfileAdditionalAmenitiesForm.controls[key]) {
        this.ProfileAdditionalAmenitiesForm.controls[key].patchValue(
          additionalAmenities[key], { emitEvent: false }
        )
      }
    }
  }

  /**
   * Contacts information handler
   */
  onCheckSocial(type: string): void {
    if (type === 'vkUsername') {
      const ValueVK = this.onContactsFormControl[type].value;

      if (ValueVK && ValueVK !== '') {
        this.ErrorsField[type].status = !VKLinkValidate(ValueVK)
      }
    }

    if (type === 'facebookUsername') {
      const ValueFB = this.onContactsFormControl[type].value;

      if (ValueFB && ValueFB !== '') {
        this.ErrorsField[type].status = !FBLinkValidate(ValueFB);
      }
    }

    if (type === 'instagramUsername') {
      const ValueINST = this.onContactsFormControl[type].value;

      if (ValueINST && ValueINST !== '') {
        this.ErrorsField[type].status = !INSTLinkValidate(ValueINST);
      }
    }
  }

  onCheckPhone(): void {
    this.ErrorsField.contactPhone.status = !this.onProfileContactPhoneValid;
  }

  onMaskPhone($event: any, backspace?: boolean): void {
    const contactsCtrl = this.onContactsFormControl;
    const contactPhone: string = contactsCtrl.contactPhone.value || null;

    if (!contactPhone || contactPhone === '') {
      return;
    }
    if (contactPhone.split('').length === 1) {
      if ($event.inputType === 'deleteContentBackward') {
        contactsCtrl.contactPhone.setValue(contactPhone.split('').slice(1).join(''));
      } else {
        contactsCtrl.contactPhone.setValue('7' + contactPhone);
      }
    }
  }

  onSetMessengerValue(key: string): void {
    if (!this.onProfileMessengerValid) {
      return;
    }
    const MessengerValue = this.onContactsFormControl[key].value;

    this.onContactsFormControl[key].patchValue(!MessengerValue);
  }

  onGetMessengerStatus(key: string): boolean {
    return this.onContactsFormControl[key].value
  }

  get onProfileContactPhoneValid(): boolean {
    const contactsCtrl = this.onContactsFormControl;
    const contactPhone: string = contactsCtrl.contactPhone.value || '';

    return PhoneNumberValidate(contactPhone);
  }

  get onProfileMessengerValid(): boolean {
    const contactsCtrl = this.onContactsFormControl;
    const contactPhone: string = contactsCtrl.contactPhone.value || '';

    return contactPhone !== '' && PhoneNumberValidate(contactPhone);
  }

  get onContactsFormControl(): any {
    return this.ProfileContactsForm.controls;
  }


  /**
   * Errors and messages handler
   */
  onToggleViewErrors(show: boolean): void {
    const FieldErrors = $('.__profile__errors');
    show ? FieldErrors.slideDown(150) : FieldErrors.slideUp(150);
  }

  onResetErrorFields(): void {
    for (const key of Object.keys(this.ErrorsField)) {
      this.ErrorsField[key].status = false;
      this.ErrorsField[key].value = '';
      this.cdr.markForCheck();
    }
  }

  onToggleLoading(status: boolean, delay: number = 0) {
    setTimeout(() => {
      this.ProfileLoading = status;
      this.cdr.detectChanges();
    }, delay);
  }

  onErrorsCallback(error): void {
    const dialogContainer = document.querySelectorAll('.p-dialog') || [];

    /**
     * Reset loading spinner.
     */
    this.onToggleLoading(false);

    if (error.statusCode === 400) {
      return this.notificationService.onDialog({
        type: DialogIncludesTypes.PopupErrorTemplate,
        header: 'Ошибка',
        content: error.message
      });
    }

    if (error.hasOwnProperty('failures')) {
      for (const key of Object.keys(error.failures)) {
        const Failures = error.failures[key];

        if (this.ErrorsField.hasOwnProperty(key)) {
          this.ErrorsField[key].status = true;
          this.ErrorsField[key].value = Failures;
        }
      }
    }

    if (error.hasOwnProperty('message')) {
      this.ErrorsText = error.message + '!';
      this.onToggleViewErrors(true);
    }
  }

  onSuccessCallback(): void {
    const dialogContainer = document.querySelectorAll('.p-dialog') || [];
    /**
     * Reset loading spinner.
     */
    this.onToggleLoading(false, 500);

    /**
     * Notification message
     */
    if (dialogContainer.length === 0) {
      this.notificationService.onDialog({
        type: DialogIncludesTypes.PopupSuccessTemplate,
        header: 'Обновление профиля',
        content: 'Данные Вашего профиля успешно обновлены.',
        icons: 'pi pi-user-edit'
      });

      /**
       * Update of profile data.
       */
      this.accountService.onIniGetMyInfo();
    }

    /**
     * Reset button state.
     */
    this.ProfileAccountFormModify = false;
    this.ProfilePartnerFormModify = false;
    this.ProfilePhotosFormModify = false;
  }


  /**
   * Save profile changes
   */

  onSaveProfile(): void {
    if (this.ProfileLoading) {
      return;
    }
    if (
      !this.ProfileAccountFormModify &&
      !this.ProfilePartnerFormModify &&
      !this.ProfilePhotosFormModify &&
      !this.ProfilePartnerTypeModify
    ) {
      return;
    }

    /**
     * Validation of Errors Fields.
     */
    for (const field of Object.keys(this.ErrorsField)) {
      if (this.ErrorsField[field].status) {
        this.ErrorsText = 'Проверьте правильность заполненных полей!';
        return this.onToggleViewErrors(true);
      }
    }

    /**
     * Social Link validation.
     */
    if (
      this.ErrorsField.vkUsername.status ||
      this.ErrorsField.facebookUsername.status ||
      this.ErrorsField.instagramUsername.status
    ) {
      this.ErrorsText = 'Проверьте правильность ссылок на социальные сети!';
      return this.onToggleViewErrors(true);
    }

    /**
     * Loading.
     */
    this.onToggleLoading(true);


    /**
     * Update of account avatar.
     */
    if (this.ProfileAvatarFile) {
      forkJoin(
        this.accountService.uploadAvatar(this.ProfileAvatarFile),
        this.partnerService.uploadLogo(this.ProfileAvatarFile)
      ).subscribe(
       r => this.onSuccessCallback(),
       e => {
         this.onToggleLoading(false);

         this.notificationService.onDialog({
           type: DialogIncludesTypes.PopupErrorTemplate,
           header: 'Ошибка загрузки',
           content: 'Не удалось загрузить логотип профиля. Попробуйте использовать другой файл.',
         });
       }
      );
    }

    /**
     * Pre-load form request.
     */
    const ProfileRequestZIP: Array<Observable<any>> = [];


    /**
     * Save new partner type.
     */
    if (this.ProfilePartnerTypeModify) {
      ProfileRequestZIP.push(
        this.partnerService.partnerPutType({
          partnerType: this.ProfilePartnerTypeSelected.value
        })
      );
    }

    /**
     * Save partner account
     */
    if (this.ProfileAccountFormModify) {
      const AccountRawForm = this.ProfileAccountForm.getRawValue() as IAccountUserInfo;
      /**
       * Collection data of account profile.
       */
      const AccountUserInfo = {
        id: this.ProfileAccountData.id,
        name: AccountRawForm.name,
        lastName: this.ProfileAccountData.lastName,
        username: this.ProfileAccountData.username,
        email: this.ProfileAccountData.email,
        picPath: this.ProfileAccountData.picPath,
        cityId: AccountRawForm.cityId,
        emailConfirmed: this.ProfileAccountData.emailConfirmed,
        birthday: this.ProfileAccountData.birthday,
        contacts: {
          vkUsername: this.ProfileAccountData.contacts.vkUsername,
          facebookUsername: this.ProfileAccountData.contacts.facebookUsername,
          instagramUsername: this.ProfileAccountData.contacts.instagramUsername,
          contactPhone: this.ProfileAccountData.contacts.contactPhone,
          hasTelegram: this.ProfileAccountData.contacts.hasTelegram,
          hasViber: this.ProfileAccountData.contacts.hasViber,
          hasWhatsapp: this.ProfileAccountData.contacts.hasWhatsapp
        }
      } as IAccountUserInfo;

      ProfileRequestZIP.push(
        this.accountService.updateMyInfo(ObjectRequestFormatted(AccountUserInfo))
      );
    }

    /**
     * Save partner form
     */
    if (this.ProfilePartnerFormModify) {
      const ContactsRawForm = this.ProfileContactsForm.getRawValue() as IPartnerUserContacts;
      const ProfilePartnerRawForm = this.ProfilePartnerForm.getRawValue() as IPartnerPutProfile;
      const ProfileDiscountRawForm = this.ProfilePartnerForm.getRawValue() as IPartnerPutDiscount;
      const WorkingHoursRawForm = this.ProfileWorkingHoursForm.getRawValue() as IPartnerWorkingHours;

      for (const i of Object.keys(WorkingHoursRawForm)) {
        WorkingHoursRawForm[i] = DateToHoursString(WorkingHoursRawForm[i]);
      }

      const PartnerRequestData = {
        title: ProfilePartnerRawForm.title,
        siteUrl: ProfilePartnerRawForm.siteUrl,
        description: this.ProfilePartnerForm.getRawValue().description,
        discount: ProfileDiscountRawForm.discountAmount,
        contacts: {
          contactPhone: PhoneRequestFormatted(ContactsRawForm.contactPhone),
          vkUsername: VKRequestFormatted(ContactsRawForm.vkUsername),
          facebookUsername: FBRequestFormatted(ContactsRawForm.facebookUsername),
          instagramUsername: ContactsRawForm.instagramUsername,
          hasViber: ContactsRawForm.hasViber,
          hasTelegram: ContactsRawForm.hasTelegram,
          hasWhatsapp: ContactsRawForm.hasWhatsapp
        } as IPartnerUserContacts,
        workingHours: WorkingHoursRawForm,
        additionalAmenities: this.ProfileAdditionalAmenitiesForm.getRawValue() as IPartnerAdditionalAmenities,
        address: {
          fullAddress: this.ProfilePartnerForm.getRawValue().address,
          description: null
        }
      };

      ProfileRequestZIP.push(
        this.partnerService.partnerPutProfile(PartnerRequestData as any)
      );
    }


    /**
     * Save profile photos
     */
    if (this.ProfilePhotosFormModify) {
      const photosArray = this.ProfilePhotos.map(item => item.rowString);

      ProfileRequestZIP.push(
        this.partnerService.partnerPutPhotos(photosArray)
      );
    }

    /**
     * Send requests.
     */
    ProfileRequestZIP.forEach(req => {
      req.subscribe(
        response => this.onSuccessCallback(),
        error => this.onErrorsCallback(error.error)
      )
    })
  }

  onRouteToSettings(): void {
    this.router.navigateByUrl(
      `/${RoutingConfig.Account}/${RoutingConfig.AccountManagement}`
    ).catch();
  }
}
