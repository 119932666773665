import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'metroLineAlias'
})

export class MetroLineAliasPipe implements PipeTransform {
  constructor() {
  }

  transform(line: string): string {
    switch (line) {
      case ('Сокольническая'):
        return 'red';
        break;
      case ('Кировско-Выборгская'):
        return 'red';
        break;
      case ('Замоскворецкая'):
        return 'green';
        break;
      case ('Невско-Василеостровская'):
        return 'green';
        break;
      case ('Арбатско-Покровская'):
        return 'blue';
        break;
      case ('Московско-Петроградская'):
        return 'blue';
        break;
      case ('Филёвская'):
        return 'light-blue';
        break;
      case ('Кольцевая'):
        return 'brown';
        break;
      case ('Калужско-Рижская'):
        return 'orange';
        break;
      case ('Правобережная'):
        return 'orange';
        break;
      case ('Таганско-Краснопресненская'):
        return 'purple';
        break;
      case ('Фрунзенско-Приморская'):
        return 'purple';
        break;
      case ('Калининская'):
        return 'yellow';
        break;
      case ('Солнцевская'):
        return 'yellow';
        break;
      case ('Серпуховско-Тимирязевская'):
        return 'grey';
        break;
      case ('Люблинско-Дмитровская'):
        return 'lime';
        break;
      case ('Большая кольцевая'):
        return 'teal';
        break;
      case ('Каховская'):
        return 'teal';
        break;
      case ('Бутовская'):
        return 'blue-grey';
        break;
      case ('Некрасовская'):
        return 'pink';
        break;
    }
  }
}


