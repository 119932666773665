<div class="sign-need__popup">
  <div class="row">
    <div class="sign-need__row-text">
      <div class="grid align-items-center flex-column justify-content-center text-center">
        <span class="pi pi-lock"></span>
        <h5 class="mt-4">Необходимо подтвердить учетную запись</h5>
        <p class="mt-2 mb-2">
          Для этого пройдите по <strong>ссылке в письме</strong>, отправленного Вам на почту при регистрации.
        </p>
        <p class="mb-4">
          Если Вам не пришло письмо, Вы можете отправить его повторно в <a (click)="onCloseDialog()" routerLink="/{{Routing.Account}}/{{Routing.AccountUser}}">Личном кабинете</a>.
        </p>
      </div>
    </div>

    <div class="sign-need__row-links">
      <button class="ui-buttons ui-buttons__blue-large" (click)="onCloseDialog()" pRipple>
        Закрыть
      </button>
    </div>
  </div>
</div>
