import { Injectable } from '@angular/core';
import { StorageService } from '@app/core/services/storage/storage.service';
import { StorageRoles } from '@app/core/constants';
import { AuthRoles } from '@app/modules/auth-modules/auth.model';
import { IAccountUserInfo, IAccountUserTypes } from '@app/modules/account-modules/account.model';

@Injectable()

export class RolesService {
  constructor(
    private storage: StorageService
  ) {}

  /**
   * Setter for current roles.
   * @param value
   * @param remember
   */
  onRoles(value: Array<AuthRoles>, remember: boolean = true) {
    this.storage.setStorage({
      key: StorageRoles.roles,
      value: value
    }, remember ? 'local' : 'session');
  }

  /**
   * Getter for current roles.
   */
  get roles(): Array<AuthRoles> {
    const rolesStorage  = (
      this.storage.getStorage({ key: StorageRoles.roles }, 'local') ||
      this.storage.getStorage({ key: StorageRoles.roles }, 'session') ||
      '[]'
    );
    return JSON.parse(rolesStorage);
  }


  /**
   * Checking of UserRole on User
   * @param user
   */
  onUserRole(user: IAccountUserInfo): boolean {
    return user && user.roles.some((role: IAccountUserTypes) => role === 'user');
  }


  /**
   * Checking of UserRole on Admin
   * @param user
   */
  onAdminRole(user: IAccountUserInfo): boolean {
    return user && user.roles.some((role: IAccountUserTypes) => role === 'admin');
  }

  /**
   * Checking of UserRole on Moderator
   * @param user
   */
  onModeratorRole(user: IAccountUserInfo): boolean {
    return user && user.roles.some((role: IAccountUserTypes) => role === 'moderator');
  }

  /**
   * Checking of UserRole on Partner
   * @param user
   */
  onPartnerRole(user: IAccountUserInfo): boolean {
    return user && user.roles.some((role: IAccountUserTypes) => role === 'partner');
  }
}
