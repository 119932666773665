/**
 * Angular.
 */
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';


/**
 * Modules.
 */
import { RoutingModule } from '@app/routing/client/routing.module';
import { CoreModule } from '@app/core/modules/core.module';
import { StorageModule } from '@app/core/lib/storage';
import { AuthModule } from '@app/modules/auth-modules/client/auth.module';
import { AccountModule } from '@app/modules/account-modules/client/account.module';
import { FundamentalsModule } from '@app/modules/fundamentals-modules/fundamentals.module';
import { CitiesModule } from '@app/modules/cities-module/cities.module';
import { ActivityModule } from '@app/modules/activity-modules/activity.module';
import { MusicianModule } from '@app/modules/musician-modules/musician.module';
import { FavoritesModule } from '@app/modules/favorites-modules/favorites.module';
import { NotificationModule } from '@app/modules/notification-modules/notification.module';
import { PartnerModule } from '@app/modules/partner-modules/partner.module';
import { AddressModule } from '@app/modules/address-modules/address.module';

/**
 * Vendors
 */
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

/**
 * i18n.json loader
 */
export function HttpLoaderFactory (http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({
      appId: 'serverApp'
    }),
    BrowserAnimationsModule,
    RoutingModule,
    StorageModule.forRoot(),
    CoreModule,
    FormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (HttpLoaderFactory),
        deps: [HttpClient]
      }
    }),
    FundamentalsModule,
    AuthModule,
    AccountModule,
    CitiesModule,
    ActivityModule,
    MusicianModule,
    FavoritesModule,
    NotificationModule,
    PartnerModule,
    AddressModule
  ],
  providers: [
    {
      provide: APP_BASE_HREF,
      useValue: '/'
    }
  ],
  bootstrap: [
    AppComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule { }
