import * as moment from 'moment';

/**
 * Converter string to mask-date.
 * @param input
 * @constructor
 */
export const MaskDateInput = (input: string) => {
  if (input.length < 8) {
    return input;
  }
  const SI = input.split('');

  return `${SI[0]}${SI[1]}.${SI[2]}${SI[3]}.${SI[4]}${SI[5]}${SI[6]}${SI[7]}`;
};

/**
 * Converter date to hours string.
 * @param Input
 * @constructor
 */
export const DateToHoursString = (Input: object) => {
  for (const i of Object.keys(Input)) {
    Input[i] = Input[i] instanceof Date ? moment(Input[i]).format('HH:mm:ss') : Input[i];
  }

  return Input;
};


/**
 * Converter object to flat object.
 * @param ob
 * @constructor
 */
export const FlattenObject = (ob) => {
  const toReturn = {};

  for (const i in ob) {
    if (!ob.hasOwnProperty(i)) continue;

    if ((typeof ob[i]) === 'object' && !Array.isArray(ob[i])) {
      const flatObject = FlattenObject(ob[i]);
      for (const x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) {
          continue
        }
        toReturn[x] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
};


/**
 * Converter object to params for URL.
 * @param params
 * @constructor
 */
export const ObjectToUrlParams = (params: any) => {
  let urlParams = '';

  for (const key in params) {
    if (urlParams !== '') {
      urlParams += '&';
    }
    urlParams += key + '=' + encodeURIComponent(params[key]);
  }

  return urlParams;
};

/**
 * Converter file to Base64.
 * @param file
 * @constructor
 */
export const FileToBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});


/**
 * Converter array to params for URL.
 * @param array
 * @constructor
 */
export const ArrayToUrlParams = (array: any) => {
  let urlParams = '';

  array.map(e => {
    urlParams += e.name + '=' + e.value + '&';
  });

  return urlParams.substring(0, urlParams.length - 1);
};


/**
 * Converter object for request. Replaces string with null.
 * @param request
 * @param skips
 * @param inters
 * @constructor
 */
export const ObjectRequestFormatted = (request: object, inters?: any[], skips?: any[]): any => {
  if (!inters) inters = [];
  if (!skips) skips = [];

  for (const i of Object.keys(request)) {
    if (typeof request[i] === 'object' && request[i] !== null) {
      request[i] = ObjectRequestFormatted(request[i], inters, skips);
    }
    if (request[i] === '' && inters.some(str => str === i)) {
      request[i] = 0;
    }
    if (request[i] === '' && !skips.some(str => str === i)) {
      request[i] = null;
    }
  }
  return request;
};


/**
 * Converter phone link for request.
 * @param phone
 * @constructor
 */
export const PhoneRequestFormatted = (phone: any): any => {
  return phone ? phone.split('').slice(1).join('') : phone;
};


/**
 * Converter VK link for request.
 * @param vkLink
 * @constructor
 */
export const VKRequestFormatted = (vkLink: any): any => {
  return vkLink ? vkLink.split('/').slice(-1).join('') : vkLink;
};


/**
 * Converter FB link for request.
 * @param fbLink
 * @constructor
 */
export const FBRequestFormatted = (fbLink: any): any => {
  const regex = /((?:http|https):\/\/)?(?:www.)?(?:facebook.com|fb.com)\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w\-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w\-]*)?/gm;
  let id = null;

  while ((id = regex.exec(fbLink)) !== null) {
    if (id.index === regex.lastIndex) {
      regex.lastIndex++;
    }
    fbLink = id.slice(-1).join('');
  }

  return fbLink;
};


/**
 * Converter SoundCloud link for request.
 * @param urlArray
 * @constructor
 */
export const SoundCloudRequestFormatted = (urlArray: any): any => {
  const Controls = urlArray;
  const Values = [];

  for (const i of Object.keys(Controls)) {
    if (!Controls[i] || Controls[i] === null || Controls[i] === 'undefined') {
      continue;
    }
    if (Controls[i] !== '') {
      Values.push(Controls[i]);
    }
  }

  return Values;
};


/**
 * Converter SoundCloud link for request.
 * @param url
 * @constructor
 */
export const YoutubeRequestFormatted = (url: any): any => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
  const match = url.match(regExp);

  return (match && match[7].length === 11) ? match[7] : false;
};
