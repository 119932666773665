import { SeoPageModel } from '@app/core/services/seo/seo.model';

export const BulletinsSeoParams = {
  title: 'Мои объявления',
  description: 'Сервис по поиску музыкантов No1 в России и на Луне',
  image: '',
  url: '',
} as SeoPageModel;

export const ChangePasswordSeoParams = {
  title: 'Изменение пароля',
  description: 'Сервис по поиску музыкантов No1 в России и на Луне',
  image: '',
  url: '',
} as SeoPageModel;

export const ConfirmEmailSeoParams = {
  title: 'Подтверждение почты',
  description: 'Сервис по поиску музыкантов No1 в России и на Луне',
  image: '',
  url: '',
} as SeoPageModel;

export const DiscountSeoParams = {
  title: 'Карта музыканта',
  description: 'Сервис по поиску музыкантов No1 в России и на Луне',
  image: '',
  url: '',
} as SeoPageModel;

export const FavoritesSeoParams = {
  title: 'Избранное',
  description: 'Сервис по поиску музыкантов No1 в России и на Луне',
  image: '',
  url: '',
} as SeoPageModel;

export const NotificationSeoParams = {
  title: 'Уведомления',
  description: 'Сервис по поиску музыкантов No1 в России и на Луне',
  image: '',
  url: '',
} as SeoPageModel;

export const PointsSeoParams = {
  title: 'Мои баллы',
  description: 'Сервис по поиску музыкантов No1 в России и на Луне',
  image: '',
  url: '',
} as SeoPageModel;

export const ProductsSeoParams = {
  title: 'Товары и услуги',
  description: 'Сервис по поиску музыкантов No1 в России и на Луне',
  image: '',
  url: '',
} as SeoPageModel;

export const RecoveryPassSeoParams = {
  title: 'Восстановление пароля',
  description: 'Сервис по поиску музыкантов No1 в России и на Луне',
  image: '',
  url: '',
} as SeoPageModel;

export const ProfileSeoParams = {
  title: 'Профиль',
  description: 'Сервис по поиску музыкантов No1 в России и на Луне',
  image: '',
  url: '',
} as SeoPageModel;

export const QRScanSeoParams = {
  title: 'Отсканировать QR-код',
  description: 'Сервис по поиску музыкантов No1 в России и на Луне',
  image: '',
  url: '',
} as SeoPageModel;

export const SettingsSeoParams = {
  title: 'Настройки',
  description: 'Сервис по поиску музыкантов No1 в России и на Луне',
  image: '',
  url: '',
} as SeoPageModel;

export const StatisticsSeoParams = {
  title: 'Статистика',
  description: 'Сервис по поиску музыкантов No1 в России и на Луне',
  image: '',
  url: '',
} as SeoPageModel;
