<div class="account-shell__content">
  <div class="row">
    <div class="pg-wrapper">
      <div class="account-shell__content-wrapper">
        <div class="grid align-items-start justify-content-center"
          ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : ''}}">

          <div class="col-4 col-md-5 col-sm-12"
            ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mb-4' : 'mr-2'}}">

            <div class="points__qr" *ngIf="PointsQRCode">
              <img src="{{PointsQRCode}}">
            </div>
          </div>

          <div class="col-auto col-sm-12"
            ngClass="{{(deviceValue | async) === DTypes.Mobile ? '' : 'ml-2'}}">

            <div class="grid align-items-center justify-content-start points__card ui-background__pg-card">
              <div class="points__card-wrapper">
                <div class="points__card-heading">
                  <p>Карта музыканта</p>
                </div>
                <div class="points__card-profile" *ngIf="PUser">
                  <p>{{PUser?.name}} {{PUser?.lastName}}</p>
                </div>
                <div class="points__card-code">
                  <p>{{PointsCode | mask: '999 999 999'}}</p>
                </div>
                <div class="points__card-description">
                  <p>
                    Вы можете использовать номер своей карты для предъявления нашим партнерам или
                    показать для сканирования QR-код.
                  </p>
                  <p>За каждое использование карты начисляется 10 баллов.</p>
                </div>
                <div class="points__card-balalaika">
                  <div class="ui-graphics__balalaika-light animated fast fadeIn"></div>
                </div>
                <div class="points__card-logo">
                  <div class="ui-logo__footer  animated fast fadeIn"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="pg-wrapper">
        <div class="points__total">
          <div class="points__total-count">
            <div class="grid align-items-baseline justify-content-start">
              <div class="col-auto">
                <p class="__description">Количество баллов: </p>
              </div>
              <div class="col-auto">
                <p class="__value">{{PointsBalance}}</p>
              </div>
            </div>
            <div class="grid align-items-baseline justify-content-start">
              <div class="col-auto">
                <p class="__description">Количество совершенных покупок: </p>
              </div>
              <div class="col-auto">
                <p class="__value">{{PointsPurchaseLists?.totalCount}}</p>
              </div>
            </div>
          </div>

          <div class="points__total-table ui-scroll ui-scroll-horizontal">
            <p-table [value]="PointsPurchaseLists?.items" [styleClass]="'p-table__base'">
              <ng-template pTemplate="header">
                <tr>
                  <th width="15%">Дата</th>
                  <th>Услуга или товар</th>
                  <!--<th width="15%">Экономия</th>-->
                  <th width="15%">Стоимость</th>
                  <th width="20%">Партнер</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-item>
                <tr>
                  <td>{{item.created | formatDate}}</td>
                  <td>{{item.title}}</td>
                  <td>{{item.price}}</td>
                  <td>
                    <a routerLink="/{{Routing.Loyalty}}/{{Routing.Partner}}/{{item.partnerId}}">{{item.partnerTitle}}</a>
                  </td>
                </tr>
              </ng-template>
            </p-table>
            <div class="points__total-table__cup" *ngIf="!PointsPurchaseLists?.totalCount">
              <div class="grid align-items-baseline justify-content-center"
                ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'pt-4 pb-4' : 'pt-6 pb-6'}}">

                <span class="pi pi-exclamation-circle mr-2"></span>
                <h5>Нет данных для отображения</h5>
              </div>
            </div>
          </div>

          <div class="row points__pagination" *ngIf="PointsPurchaseLists?.totalCount">
            <div *ngFor="let item of [] | paginate: {
                itemsPerPage: 1,
                currentPage: PointsPurchaseLists?.pageIndex,
                totalItems: PointsPurchaseLists?.totalPages
            }">
            </div>
            <pagination-controls
              class="ui-pagination"
              (pageChange)="onPageChange($event)"
              responsive="true"
              maxSize="5"
              previousLabel="« Назад"
              nextLabel="Вперед »">
            </pagination-controls>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
