export enum PartnerTypeEnum {
  Base,
  Record,
  Shop,
  School,
  Workshop
}

export interface IPartnerSignUp {
  title: string;
  partnerType: number;
  partnerSiteUrl: string;
  phoneNumber: string;
  email: string;
  applicationContactName: string;
  cityId?: number;
}

export interface IPartnerUserInfo {
  isActiveSubscription: boolean;
  partnerType: number;
}

export interface IPartnerItem {
  itemId?: number;
  id: number;
  name: string;
  logoLink: string;
  address: IPartnerAddress
  workingHours: {
    timetable: IPartnerWorkingHours
  },
  partnerDiscountPercent: number;
  partnerType: number;
  webSite: string;
  rating: number;
  isInFavorites: boolean;
}

export interface IPartnerDetail {
  id: number;
  picPaths: Array<string>
  title: string;
  serviceItems: Array<IPartnerServices>;
  discount: number;
  additionalInfoVm: {
    logoPath: string,
    picPaths: Array<string>
    address: IPartnerAddress;
    workingHours: {
      timetable: IPartnerWorkingHours
    };
    additionalAmenities: IPartnerAdditionalAmenities
    description: string;
  },
  contacts: IPartnerUserContacts;
  products: Array<IPartnerPublicProduct>;
  rateInfo: IPartnerRateInfo;
  siteUrl: string;
  viewCount: string;
}

export interface IPartnerProfile {
  id: number;
  title: string;
  picPaths: Array<string>;
  discountAmount: number;
  additionalInfoVm: {
    logoPath: string;
    address: {
      location: {
        longitude: number;
        latitude: number;
      },
      description: string;
      fullAddress: string;
      metro: Array<string>
    },
    workingHours: {
      timetable: IPartnerWorkingHours
    },
    additionalAmenities: IPartnerAdditionalAmenities,
    description: string,
    picPaths: Array<string>
  },
  products: Array<IPartnerPublicProduct>
  contacts: IPartnerUserContacts,
  isEnabled: boolean
}

export interface IPartnerUserContacts {
  contactPhone: string;
  vkUsername: string;
  facebookUsername: string;
  instagramUsername: string;
  hasViber: boolean;
  hasTelegram: boolean;
  hasWhatsapp: boolean;
}

export interface IPartnerProduct {
  id?: number;
  title: string;
  position: number;
  price: number;
  description: string
  picKeys: Array<string>
}

export interface IPartnerPublicProduct {
  id: number;
  photos: Array<{
    thumbnailPath: string;
    path: string;
  }>
  picPath?: Array<string>
  price: number;
  type: string;
  description: string;
  title: string;
}

export interface IPartnerServices {
  id: number;
  title: string;
  picPath: string;
  price: number;
}

export interface IPartnerSaleMake {
  price: number;
  musicianCartCode: string;
  comment: string;
}

export interface IPartnerSaleMakeResponse {
  type: string;
  title: string;
  status: number;
  detail: string;
  instance: string;
}

export interface IPartnerMusicianInfo {
  registrationDate: string;
  name: string;
}

export interface IPartnerPutType {
  partnerType: number;
}

export interface IPartnerPutProfile {
  title: string;
  siteUrl: string;
  description: string;
  discount: string;
  contacts: IPartnerUserContacts;
  workingHours: IPartnerWorkingHours;
  additionalAmenities: IPartnerAdditionalAmenities;
  address: IPartnerAddress;
}

export interface IPartnerPutDiscount {
  discountAmount: number;
}

export interface IPartnerAdditionalAmenities {
  toilet: boolean;
  tea: boolean;
  conditioner: boolean;
  recording: boolean;
  instrumentsRent: boolean;
  wiFi: boolean;
  smokingRoom: boolean;
  coffeeVendingMachine: boolean;
}

export interface IPartnerWorkingHours {
  friday: {
    from: string;
    to: string;
    breakFrom: string;
    breakTo: string;
  }
  monday: {
    from: string;
    to: string;
    breakFrom: string;
    breakTo: string;
  }
  saturday: {
    from: string;
    to: string;
    breakFrom: string;
    breakTo: string;
  }
  sunday: {
    from: string;
    to: string;
    breakFrom: string;
    breakTo: string;
  }
  thursday: {
    from: string;
    to: string;
    breakFrom: string;
    breakTo: string;
  }
  tuesday: {
    from: string;
    to: string;
    breakFrom: string;
    breakTo: string;
  }
  wednesday: {
    from: string;
    to: string;
    breakFrom: string;
    breakTo: string;
  }
}

export interface IPartnerAddress {
  description: string;
  fullAddress: string;
  location?: any;
  latitude: number;
  longitude: number;
  metro: Array<{
    distanceKm: number;
    line: string;
    name: string;
  }>
}

export interface IPartnerRateInfo {
  totalRateCount: number;
  feedbacksCount: number;
  fiveStars: number;
  fourStars: number;
  threeStart: number;
  twoStars: number;
  oneStars: number;
}

export interface IPartnerSaleLists {
  pageIndex: number;
  totalCount: number;
  totalPages: number;
  items?: Array<IPartnerSale>;
}

export interface IPartnerSale {
  clientName: string;
  commentary: string;
  loyaltyCardNumber: string;
  points: number;
  price: number;
  sellDate: string;
}

