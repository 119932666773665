<div class="photos-gallery" *ngIf="PartnersGalleryData.length">
  <div class="photos-gallery__loading animated faster fadeIn" *ngIf="GalleryLoading">
    <div class="ui-loader ui-loader__page-light"></div>
  </div>
  <div class="photos-gallery__slide"
    ngClass="{{GalleryLoading ? 'hide' : ''}}">

    <ng-container *ngFor="let partner of PartnersGalleryData">
      <div class="photos-gallery__item pt-3 pb-3">
        <img src="{{partner.picture}}" alt="{{partner.title}}"/>
      </div>
    </ng-container>
  </div>
</div>
