import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, Input, OnInit } from '@angular/core';
import { AppPROD, AppStaticURL } from '@app/app.settings';
import { DevicesClass, DeviceTypes } from '@app/core/classes/component/devices.class';
import { IPartnerItem, IPartnerDetail } from '@app/modules/partner-modules/partner.model';
import { IAccountUserInfo } from '@app/modules/account-modules/account.model';
import { PrimeNGConfig } from 'primeng/api';
import { AccountEventBus, AccountStoryService } from '@app/modules/account-modules/account.story';
import { untilDestroyed } from '@ngneat/until-destroy';
import { RoutingConfig } from '@app/routing/routing.config';
import { AuthStoryService } from '@app/modules/auth-modules/auth.story';
import { GalleryDataModel } from '@app/core/models/gallery';
import { PartnerEventBus, PartnerStoryService } from '@app/modules/partner-modules/partner.story';
import { DialogComponentModel, DialogIncludesTypes } from '@app/core/models/diallog';
import { NotificationService } from '@app/modules/notification-modules/notification.service';
import { FavoritesService } from '@app/modules/favorites-modules/favorites.service';
import { RolesService } from '@app/core/services';
import { IPartnerPublicProduct } from '@app/modules/partner-modules/partner.model';
import { ProductItemViewTypes } from '@app/shared/product-item/product-item.component';
import { DialogService } from 'primeng/dynamicdialog';
import { PartnerHeadingPipe } from '@app/core/pipes/partners/heading.pipe';
import { _deviceType } from '@app/utils/device';
import * as moment from 'moment';

/**
 * Components
 */
import { MapAddressComponent } from '@app/modules/address-modules/map-address/map-address.component';

declare var $;

export enum PartnerItemViewTypes {
  Block,          // 0
  List,           // 1
  Detail,         // 2
  ProfileList,    // 3
  ProfileBlock,   // 4
}

export enum PartnerMessengerTypes {
  Viber,
  Whatsapp,
  Telegram
}

@Component({
  selector: 'shared-partner-item',
  templateUrl: 'partner-item.component.html',
  styleUrls: [`./partner-item.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class PartnerItemComponent extends DevicesClass implements OnInit {
  public Routing = RoutingConfig;
  public PITypes = PartnerItemViewTypes;
  public PrITypes = ProductItemViewTypes;
  public PIUserAuth: IAccountUserInfo = null;
  public PIMessenger = PartnerMessengerTypes;
  public StaticURL = AppStaticURL;

  /**
   * Favorites event variables.
   */
  public PIGallery: GalleryDataModel = [];
  public PIFavLoading: boolean = false;
  public PIFavActive: boolean = false;
  public PIFavId: number = 0;

  @Input() PartnerView: PartnerItemViewTypes;
  @Input() PartnerItem: IPartnerItem;
  @Input() PartnerDetail: IPartnerDetail;

  constructor(injector: Injector,
    public cdr: ChangeDetectorRef,
    private accountStory: AccountStoryService,
    private primengConfig: PrimeNGConfig,
    private authStoryService: AuthStoryService,
    private partnerStoryService: PartnerStoryService,
    private notificationService: NotificationService,
    private favoritesService: FavoritesService,
    private partnerStory: PartnerStoryService,
    private rolesService: RolesService,
    private dialogService: DialogService,
    private partnerHeadingPipe: PartnerHeadingPipe
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.primengConfig.ripple = true;

    /**
     * Set on accountStory emit changes.
     */
    this.onAccountStoryBus();

    /**
     * Set on partnerStory emit changes.
     */
    this.onPartnerStoryBus();

    /**
     * Set of partner gallery data.
     */
    this.PIGallery = this.onPartnersGalleryData();

    setTimeout(() => this.onPartnersProductsGalleryFn(), 100);
  }

  onAccountStoryBus(): void {
    this.accountStory.AccountEventBus$.pipe(untilDestroyed(this)).subscribe(
      emit => this.onAccountStoryListener(emit)
    );
  }

  onAccountStoryListener(emit: AccountEventBus): void {
    if (emit.event === 'onAccountUpdate') {
      this.PIUserAuth = emit.data;
    }

    if (emit.event === 'onAccountLogout' || emit.event === 'onAccountEmpty') {
      this.PIUserAuth = null;
    }

    this.cdr.markForCheck();
  }

  onPartnerStoryBus(): void {
    this.partnerStory.PartnerEventBus$.pipe(untilDestroyed(this)).subscribe(
      emit => this.onPartnerStoryListener(emit)
    );
  }

  onPartnerStoryListener(emit: PartnerEventBus): void {
    if (emit.event === 'onUpdateFavorites') {
      this.onMapFavoritesPartners(emit.data);
    }
  }

  onViewDetailPartner(): string {console.log();
    // return AppPROD ? '_blank' : '_self';
    // return this.deviceValue.getValue() === this.DTypes.Desktop ? '_blank' : '_self';
    return '_self';
  }

  onConvertWeekday(): Array<any> {
    let timetable = {};

    if (this.PartnerDetail) {
      timetable = this.PartnerDetail.additionalInfoVm.workingHours.timetable
    }

    if (this.PartnerItem) {
      timetable = this.PartnerItem.workingHours.timetable
    }

    const sortArray = [];
    const sortKey = {
      monday: 1,
      tuesday: 2,
      wednesday: 3,
      thursday: 4,
      friday: 5,
      saturday: 6,
      sunday: 7
    };

    for (const i of Object.keys(timetable)) {
      sortArray.push({
        day: i,
        from: moment(timetable[i].from, 'HH:mm:ss').toDate().getTime() / 1000,
        fromIni: timetable[i].from,
        to: moment(timetable[i].to, 'HH:mm:ss').toDate().getTime() / 1000,
        toIni: timetable[i].to
      })
    }

    return sortArray
      .sort(function sortByDay(a, b) {
        return sortKey[a.day.toLowerCase()] - sortKey[b.day.toLowerCase()];
      })
      .reduce((preVal, curVal) => {
        const last = preVal[preVal.length - 1];

        if (last && last.from === curVal.from && last.to === curVal.to) {
          last.endDay = curVal.day;
        } else {
          preVal.push(curVal);
        }

        return preVal;
      }, []);
  }

  onSplitWorkHours(hours: string): string {
    return hours.split(':').splice(0, 2).join(':');
  }

  onAddToFavorites(): void {
    if (!this.PIUserAuth) {
      return this.authStoryService.AuthEventBus.next({
        event: 'onAuthNeed',
        data: 'Для добавления в избранное'
      });
    }
    if (!this.rolesService.onUserRole(this.PIUserAuth)) {
      return this.notificationService.onDialog({
        type: DialogIncludesTypes.PopupTemplate,
        header: 'Ошибка',
        content: 'Добавление в избранное не доступно для партнеров.',
        icons: 'pi pi-exclamation-triangle'
      });
    }

    this.onLoadingFavorites(true);

    /**
     * Get partner ID.
     */
    const Id = this.PartnerItem ?
      this.PartnerItem.id : this.PartnerDetail.id;

    /**
     * Get partner Type.
     */
    const Type = 5;

    /**
     * Add to favorites request.
     */
    this.favoritesService.addToFavorites(Id, Type).subscribe(
      r => {
        this.PIFavActive = true;
        this.onLoadingFavorites(false);
      },
      e => {
        if (e === 400) {
          this.PIFavActive = false;
        }
        this.onLoadingFavorites(false)
      }
    );
  }

  onRemoveFromFavorites(): void {
    this.onLoadingFavorites(true);

    this.favoritesService.removeFromFavorites(this.PIFavId).subscribe(
      r => {
        this.PIFavActive = false;
        this.onLoadingFavorites(false);
      },
      e => {
        if (e === 400) {
          this.PIFavActive = false;
        }
        this.onLoadingFavorites(false)
      }
    );
  }

  onLoadingFavorites(status: boolean): void {
    this.PIFavLoading = status;
    this.cdr.markForCheck();
  }

  onMapFavoritesPartners(bulletins: Array<IPartnerItem>): void {
    bulletins.map(bulletinFavorite => {
      if (this.PartnerItem) {
        if (bulletinFavorite.itemId === (this.PartnerItem.itemId ||this.PartnerItem.id)) {
          this.PIFavId = bulletinFavorite.id;
          this.PIFavActive = true;
          this.cdr.markForCheck();
        }
      }

      if (this.PartnerDetail) {
        if (bulletinFavorite.itemId === this.PartnerDetail.id) {
          this.PIFavId = bulletinFavorite.id;
          this.PIFavActive = true;
          this.cdr.markForCheck();
        }
      }
    })
  }

  onPartnersGalleryData(): GalleryDataModel {
    return this.PartnerDetail ? this.PartnerDetail.picPaths.map(pic => { return {picture: AppStaticURL + pic} }) || [] : [];
  }

  onPartnerAboutUsText(): any {
    const aboutUsText = this.PartnerDetail.additionalInfoVm.description;

    return aboutUsText ? this.textInputFormatter(aboutUsText) : 'Информация отсутствует';
  }

  onPartnersProductsGalleryFn(): void {
    if (!this.PartnerDetail) {
      return;
    }
    const count = this.PartnerDetail.products.length || null;

    if (typeof $ === 'function' && count) {
      try {
        $('.groups-gallery__slide').slick({
          autoplay: true,
          autoplaySpeed: 5000,
          infinite: true,
          dots: false,
          slidesToShow: count < 4 ? count : 4,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '6%',
          responsive: [
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerPadding: '4%',
              }
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: count < 3 ? count : 3,
                slidesToScroll: 1,
                centerPadding: '5%',
              }
            }
          ]
        });
      } catch (e) {}
    }
  }

  onPartnerProducts(): Array<IPartnerPublicProduct> {
    return this.PartnerDetail.products.length ? this.PartnerDetail.products : null;
  }

  onRouteToSocialLinkURL(path: string, id: string) {
    if (path && id) {
      id = id.split('/').length > 1 ? id.split('/').splice(-1).join('') : id;
      window.open(path + id, '_blank');
    }
  }

  onRouteToMessengerLinkURL(messenger: PartnerMessengerTypes, status): void {
    if (!status || messenger === this.PIMessenger.Telegram) {
      return;
    }
    const contactPhone = this.PartnerDetail.contacts.contactPhone || null;

    if (!contactPhone) {
      return this.notificationService.onDialog({
        type: DialogIncludesTypes.PopupErrorTemplate,
        header: 'Мессенджер',
        content: `Не удалось открыть выбранный мессенджер. Попробуйте воспользоватья другими способами для связи, указанные в объявлении.`
      });
    }

    if (messenger === this.PIMessenger.Whatsapp) {
      window.open(`https://wa.me/+7${contactPhone}`, '_blank');
    }

    if (messenger === this.PIMessenger.Viber) {
      window.open(`viber://chat?number=+7${contactPhone}`, '_blank');
    }
  }

  onShowPartnerPhone(): void {
    this.partnerStoryService.PartnerEventBus.next({
      event: 'onShowPhone',
      data: this.PartnerDetail
    });
  }

  onCheckAdditionalInfoVm(): boolean {
    const additionalAmenities = this.PartnerDetail ? this.PartnerDetail.additionalInfoVm.additionalAmenities : {};

    let checkingAmenities = false;

    for (const i of Object.keys(additionalAmenities)) {
      if (additionalAmenities[i]) {
        checkingAmenities = true;
        break;
      }
    }

    return checkingAmenities;
  }

  textInputFormatter (str: string, isXhtml?: any){
    const breakTag = (isXhtml || typeof isXhtml === 'undefined') ? '<br />' : '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1'+ breakTag +'$2');
  };

  onPartnerViewOnMap(): void {
    const Heading = this.PartnerDetail ?
      this.partnerHeadingPipe.transform(this.PartnerDetail, 'detailType') :
      this.partnerHeadingPipe.transform(this.PartnerItem);

    const Address = this.PartnerDetail ?
      this.PartnerDetail.additionalInfoVm.address :
      this.PartnerItem.address;

    if (!Address.fullAddress) {
      return;
    }
    this.dialogService.open(MapAddressComponent, {
      header: Heading,
      width: _deviceType() === DeviceTypes.Mobile ? '90%' : '100%',
      styleClass: 'p-dialog__map',
      data: {
        includeType: DialogIncludesTypes.ComponentTemplate,
        includeData: Address
      } as DialogComponentModel
    });
  }
}
