<div class="crud-product__loading animated faster fadeIn" *ngIf="ProductLoading">
  <div class="ui-loader ui-loader__page-light"></div>
</div>

<div class="crud-product__wrapper"
  ngClass="{{ProductLoading ? 'loading' : ''}}">

  <div class="row">
    <form class="crud-product__forms" [formGroup]="ProductForm" (ngSubmit)="onSubmitForm()" autocomplete="off">
      <div class="grid align-items-start"
        ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : ''}}">

        <div class="col-4 col-sm-12"
          ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'order-1 pt-4 pl-2 pr-2' : ''}}">

          <div class="ui-gallery-upload__input" *ngIf="!ProductPhotos.length" (click)="onUploadPhotoHandler()">
            <input type="file" id="photoUpload" (change)="onUploadPhotoEvent($event.target['files'])" [accept]="fileAcceptString">
            <div class="grid align-items-center justify-content-center flex-column">
              <i class="pi pi-camera"></i>
              <h5>Загрузить фотографию</h5>
            </div>
          </div>
          <div class="ui-gallery-upload__preview" *ngIf="ProductPhotos.length">
            <div class="grid align-items-end flex-nowrap">
              <ng-container *ngFor="let photo of ProductPhotos; let i = index;">
                <div class="ui-gallery-upload__preview__item">
                  <img src="{{photo.src}}">

                  <div class="ui-gallery-upload__preview__event">
                    <div class="grid align-items-center justify-content-center flex-column h-full">
                      <button class="ui-buttons ui-buttons__red-small" (click)="onRemovePhotoEvent(i)">
                        <p class="pi pi-trash"></p>
                      </button>
                    </div>
                  </div>
                </div>
              </ng-container>

            </div>
          </div>
        </div>
        <div class="col-8 col-sm-12"
          ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'order-0' : ''}}">
          <div class="grid align-items-center"
            ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : ''}}">

            <div class="col-8 col-sm-12">
              <div class="ui-input__placeholder">
                <p>Заголовок:</p>
                <span class="require">*</span>
                <p class="error-holder" *ngIf="ErrorsField.title.status">
                  {{ErrorsField.title.value}}
                </p>
              </div>
              <div class="ui-input ui-input__text"
                ngClass="{{ErrorsField.title.status ? 'error' : ''}}">

                <input type="text" placeholder="Название товара или услуги" formControlName="title" maxlength="25">
              </div>
            </div>

            <div class="col-4 col-sm-12"
              ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'pt-2' : ''}}">

              <div class="ui-input__placeholder">
                <p>Цена:</p>
                <span class="require">*</span>
                <p class="error-holder" *ngIf="ErrorsField.price.status">
                  {{ErrorsField.price.value}}
                </p>
              </div>
              <div class="ui-input ui-input__text"
                ngClass="{{ErrorsField.price.status ? 'error' : ''}}">

                <input type="text" placeholder="1200" formControlName="price" currencyMask
                  [options]="maskOptions">
              </div>
            </div>
          </div>

          <div class="grid align-items-center"
            ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'pt-2' : 'pt-4'}}">

            <div class="col-12">
              <div class="ui-input__placeholder">
                <p>Описание:</p>
                <span class="require">*</span>
                <p class="error-holder" *ngIf="ErrorsField.description.status">
                  {{ErrorsField.description.value}}
                </p>
              </div>
              <div class="ui-input ui-input__text"
                ngClass="{{ErrorsField.description.status ? 'error' : ''}}">
                <textarea placeholder="Описание товара или услуги" formControlName="description" maxlength="200"></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="crud-product__helpers-errors __product__errors">
      <div class="grid justify-content-center"
        ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'align-items-center text-left' : 'align-items-baseline'}}">

        <span class="mr-icon mr-icon-status-error"></span>
        <p>{{ErrorsText}}</p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="crud-product__helpers">
      <div class="grid align-items-center justify-content-center"
        ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : ''}}">

        <div class="col-3 col-sm-12 pl-0">
          <button class="ui-buttons ui-buttons__blue-large" (click)="onSubmitForm()">
            {{onProductEventType === ProductTypes.Edit ? 'Сохранить' : 'Добавить'}}
          </button>
        </div>

        <div class="col-9 col-sm-12"
          ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'mt-4' : 'pl-4'}}">
          <div class="col-10">
            <div class="grid align-items-center">
              <div class="col-auto mr-3">
                <span class="mr-icon mr-icon-info"></span>
              </div>
              <div class="col-10 col-sm-10">
                <div class="grid align-items-start">
                  <p class="txt-require pr-1">*</p>
                  <p class="txt-caption__large">— Поля отмеченные звездочкой обязательны для заполнения и не могут быть пустыми.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="crud-product__helpers-graphic"
        *ngIf="(deviceValue | async) === DTypes.Desktop || (deviceValue | async) === DTypes.Tablet">
        <div class="ui-graphics__balalaika-dark"></div>
      </div>
    </div>
  </div>
</div>
