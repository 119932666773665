import { AppApiURL } from '@app/app.settings';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { HttpRequestFile, HttpRequestToken } from '@app/core/classes';
import { Observable, throwError } from 'rxjs';
import { catchError, debounceTime, map, timeout } from 'rxjs/operators';
import { IBlogPostNewsDetail, IBlogPostNewsLists, IBlogPostRequest, IBlogPostUploadImage } from './news.models';
import { StorageService } from '@app/core/services';
import { StorageNews } from '@app/core/constants';

@Injectable()

export class NewsService {
  constructor(
    private http: HttpClient,
    private storage: StorageService
  ) {}


  /**
   * Save NewsCreate FormData in LocalStorage
   * @param data
   */
  public saveNewsFormData(data: any): void {
    this.storage.setStorage(
      {
        key: StorageNews.newsFormData,
        value: JSON.stringify(data)
      },
    'local');
  }


  /**
   * Get NewsCreate FormData from LocalStorage
   */
  public getNewsFormData(): any {
    const artistFormData =  this.storage.getStorage(
      {
        key: StorageNews.newsFormData
      },
    'local') || null;

    return JSON.parse(artistFormData);
  }


  /**
   * Remove NewsCreate FormData from LocalStorage
   */
  public clearNewsFormData(): any {
    this.storage.removeStorage({ key: StorageNews.newsFormData }, 'local');
  }


  /**
   * Api: v01.
   * Method: BlogPost getting of all news
   * @param size
   * @param page
   * @param debounce
   */
  public getAllNews(size?: number, page?: number, debounce?: number): Observable<IBlogPostNewsLists> {
    return this.http.get<IBlogPostNewsLists>(AppApiURL + `/blog/?discriminator=News&page=${page ? page : 1}&size=${size ? size : 20}`, {
      params: new HttpRequestToken(true)
    }).pipe(
      debounceTime(debounce ? debounce : 0)
    );
  }


  /**
   * Api: v01.
   * Method: BlogPost getting of news by ID
   * @param id
   */
  public getNewsById(id: number): Observable<IBlogPostNewsDetail> {
    return this.http.get<any>(AppApiURL + `/blog/${id}`).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }


  /**
   * Api: v01.
   * Method: Like set like of news.
   * @param id
   */
  public likeNews(id: number): Observable<any> {
    return this.http.post<any>(AppApiURL + `/likes/${id}/like`, null)
      .pipe(
        map((response: HttpResponse<any>) => response),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  }


  /**
   * Api: v01.
   * Method: Like set like of news.
   * @param id
   */
  public dislikeNews(id: number): Observable<any> {
    return this.http.post<any>(AppApiURL + `/likes/${id}/dislike`, null)
      .pipe(
        map((response: HttpResponse<any>) => response),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  }


  /**
   * Api: v01.
   * Method: BlogPost Upload Images
   * @params files
   */
  public uploadImage(files: FileList): Observable<IBlogPostUploadImage> {
    const formData = new FormData();
    formData.append('file', files[0]);

    return this.http.post<any>(AppApiURL + '/blog/uploadImage/News', formData, {
      params: new HttpRequestFile(true)
    }).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error.error || 'Не удалось загрузить новый аватар!');
      })
    );
  }


  /**
   * Api: v01.
   * Method: BlogPost create of news
   * @param request
   */
  public createNews(request: IBlogPostRequest): Observable<any> {
    return this.http.post<any>(AppApiURL + '/blog',
      request,
      {
        withCredentials: true,
        observe: 'response',
        reportProgress: false
      }
    ).pipe(
      map((response: HttpResponse<any>) => response.body),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }


  /**
   * Api: v01.
   * Method: BlogPost update of news
   * @param id
   * @param request
   */
  public updateNews(id: number, request: IBlogPostRequest): Observable<any> {
    return this.http.put<any>(AppApiURL + '/blog/' + id,
      request,
      {
        withCredentials: true,
        observe: 'response',
        reportProgress: false
      }
    ).pipe(
      timeout(10000),
      map((response: HttpResponse<any>) => response.body),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }


  /**
   * Api: v01.
   * Method: BlogPost delete of news by ID
   * @param id
   */
  public deleteNewsById(id: number): Observable<any> {
    return this.http.delete<any>(AppApiURL + '/blog/' + id).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }
}
