import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FundamentalsModule } from '@app/modules/fundamentals-modules/fundamentals.module'

/**
 * Components
 */
import { CrudProductComponent } from './crud-product/crud-product.component';
import { DetailProductComponent } from './detail-product/detail-product.component';

/**
 * Services
 */
import { PartnerService } from './partner.service';

/**
 * Vendors
 */
import { TooltipModule } from 'primeng/tooltip';
import { NgxCurrencyModule } from 'ngx-currency';

/**
 * Directives
 */
import { ValidationModule } from '@app/core/directives';

/**
 * Pipes
 */
import { PipesCoreModule } from '@app/core/pipes/pipes.module';

@NgModule({
  declarations: [
    CrudProductComponent,
    DetailProductComponent
  ],
  exports: [
    CrudProductComponent,
    DetailProductComponent
  ],
  entryComponents: [
    CrudProductComponent,
    DetailProductComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FundamentalsModule,
    TooltipModule,
    NgxCurrencyModule,
    ValidationModule,
    PipesCoreModule
  ],
  providers: [
    PartnerService
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})

export class PartnerModule {}
