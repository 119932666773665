import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DevicesClass } from '@app/core/classes';
import { RoutingConfig } from '@app/routing/routing.config';
import { AuthService } from '@app/modules/auth-modules/auth.service'
import { AccountService } from '@app/modules/account-modules/account.service';
import { AccountStoryService } from '@app/modules/account-modules/account.story';
import { DialogIncludesTypes } from '@app/core/models/diallog';
import { NotificationService } from '@app/modules/notification-modules/notification.service';
import { TokenService } from '@app/core/services';
import { AuthStoryService } from '@app/modules/auth-modules/auth.story';
import { delay } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-account-discount',
  templateUrl: 'confirm-email.component.html',
  styleUrls: [`./confirm-email.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ConfirmEmailComponent extends DevicesClass implements OnInit {
  constructor(injector: Injector,
    public cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private authStory: AuthStoryService,
    private tokenService: TokenService,
    private accountService: AccountService,
    private accountStory: AccountStoryService,
    private notificationService: NotificationService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.route.queryParams.pipe(untilDestroyed(this)).subscribe(params => {
      this.authService.confirmEmail(params['userId'], params['code']).pipe(delay(1000)).subscribe(
        r => {
          this.notificationService.onDialog({
            type: DialogIncludesTypes.PopupSuccessTemplate,
            header: 'Подтверждение профиля',
            content: 'Ваш профиль успешно подтвержден.'
          });

          /**
           * Refresh Account Token.
           */
          this.onRefreshTokenAPI();
        },
        e => {
          this.notificationService.onDialog({
            type: DialogIncludesTypes.PopupErrorTemplate,
            header: 'Ошибка подтверждение',
            content: 'Не удалось подтвердить Ваш профиль.'
          });

          this.router.navigateByUrl(`/${RoutingConfig.Account}/${RoutingConfig.AccountUser}`).catch()
        }
      );
    });
  }

  onRefreshTokenAPI(): void {
    const refreshToken = this.tokenService.refreshToken || null;

    this.authService.tokensRefresh({ refreshToken: refreshToken }).subscribe(
      response => {
        this.authService.onAuthRequestSuccess(response, true);

        /**
         * Getting of Account User model.
         */
        this.onSignInAccountUserAPI();
      },
      error => {
        this.authStory.AuthEventBus.next({
          event: 'onAuthLogout'
        });

        this.router.navigateByUrl(`/${RoutingConfig.Login}/${RoutingConfig.SignIn}`).catch()
      }
    );
  }

  onSignInAccountUserAPI(): void {
    this.accountService.getMyInfo().subscribe(
      accountResponse => {
        this.accountStory.AccountEventBus.next({
          event: 'onAccountUpdate',
          data: accountResponse
        });

        /**
         * Hide loading spinner.
         */
        this.router.navigateByUrl(`/${RoutingConfig.Account}/${RoutingConfig.AccountUser}`).catch()
      },
      accountError => {
        this.router.navigateByUrl(`/${RoutingConfig.Root}`).catch()
      }
    );
  }
}
