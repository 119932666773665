import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export type PartnerEventBusTypes =
  'onShowPhone'               |
  'onUpdateFavorites'         |
  'onAddToFavorites'          |
  'onRemoveFromFavorites'     |
  'onUpdateFavorites'         |
  'onProductEdit'             |
  'onProductDelete'           |
  'onProductAddSuccess'       |
  'onProductAddError'         |
  'onProductUpdateSuccess'    |
  'onProductUpdateError';

export type PartnerEventBusData = any;
export interface PartnerEventBus {
  event: PartnerEventBusTypes;
  data?: PartnerEventBusData;
}

@Injectable({
  providedIn: 'root'
})

export class PartnerStoryService {
  public PartnerEventBus: Subject<PartnerEventBus> = new Subject<PartnerEventBus>();
  public PartnerEventBus$ = this.PartnerEventBus.asObservable();

  constructor() {
  }
}
