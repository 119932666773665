import { AppApiURL, AppDomainURL } from '@app/app.settings';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ILoyaltyCart, ILoyaltyPurchaseLists, ILoyaltyPartnersSearch } from './loyalty.model';
import { RoutingConfig } from '@app/routing/routing.config';

@Injectable()

export class LoyaltyService {

  constructor(
    private http: HttpClient
  ) {}

  /**
   * Api: v01.
   * Method: Loyalty Cart
   */
  public loyaltyCart(): Observable<ILoyaltyCart> {
    const returnPath = `?deepLinkRedirect=${AppDomainURL}/${RoutingConfig.Account}/${RoutingConfig.AccountQRScan}/`;

    return this.http.get<ILoyaltyCart>(AppApiURL + `/loyalty/cart${returnPath}`).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  /**
   * Api: v01.
   * Method: Loyalty PurchaseHistory
   * @param page
   * @param size
   */
  public loyaltyPurchaseHistory(page?: number, size?: number): Observable<ILoyaltyPurchaseLists> {
    return this.http.get<ILoyaltyPurchaseLists>(AppApiURL + `/loyalty/purchaseHistory?page=${page ? page : 1}&pageSize=${size ? size : 50}`)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }
      )
    );
  }

  /**
   * Api: v01.
   * Method: Loyalty Partners Search
   * @param size
   * @param page
   * @param partnerType
   * @param searchType
   */
  public loyaltyPartnersSearch(size?: number, page?: number, partnerType?: string, searchType?: string): Observable<ILoyaltyPartnersSearch> {
    const partnerTypeQuery = partnerType ? `&partnerType=${partnerType}` : '';

    return this.http.get<ILoyaltyPartnersSearch>(
      AppApiURL + `/loyalty/partners?page=${page ? page : 1}&pageSize=${size ? size : 50}${partnerTypeQuery}&orderType=${searchType ? searchType : 0}`
    ).pipe(
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }
      )
    );
  }
}
