import { AppApiURL } from '@app/app.settings';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { HttpRequestToken } from '@app/core/classes';
import { StorageBulletins } from '@app/core/constants';
import { RoutingConfig } from '@app/routing/routing.config';
import { StorageService } from '@app/core/services';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable()

export class BulletinsService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private storage: StorageService
  ) {}

  /**
   * Save ArtistCreate FormData in LocalStorage
   * @param data
   */
  public saveArtistFormData(data: any): void {
    this.storage.setStorage({
      key: StorageBulletins.artistFormData,
      value: JSON.stringify(data)
    }, 'local');
  }

  /**
   * Get ArtistCreate FormData from LocalStorage
   */
  public getArtistFormData(): any {
    const artistFormData =  this.storage.getStorage({
      key: StorageBulletins.artistFormData
    }, 'local') || null;

    return JSON.parse(artistFormData);
  }

  /**
   * Remove ArtistCreate FormData from LocalStorage
   */
  public clearArtistFormData(): any {
    this.storage.removeStorage({ key: StorageBulletins.artistFormData }, 'local');
  }

  /**
   * Save ArtistCreate FormData in LocalStorage
   * @param data
   */
  public saveBandFormData(data: any): void {
    this.storage.setStorage({
      key: StorageBulletins.bandFormData,
      value: JSON.stringify(data)
    }, 'local');
  }

  /**
   * Get ArtistCreate FormData from LocalStorage
   */
  public getBandFormData(): any {
    const bandFormData =  this.storage.getStorage({
      key: StorageBulletins.bandFormData
    }, 'local') || null;

    return JSON.parse(bandFormData);
  }

  /**
   * Remove ArtistCreate FormData from LocalStorage
   */
  public clearBandFormData(): any {
    this.storage.removeStorage({ key: StorageBulletins.bandFormData }, 'local');
  }

  /**
   * Api: v01.
   * Method: Get bulletin by id
   * @param id
   */
  public bulletinDetail(id: number): Observable<any> {
    return this.http.get<any>(AppApiURL + `/bulletins/${id}`,
      {
        withCredentials: true,
        observe: 'response',
        reportProgress: false,
        params: new HttpRequestToken(true)
      }
    ).pipe(
      map((response: HttpResponse<any>) => response.body),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 404) {
          this.router.navigateByUrl(RoutingConfig.PageErrors + '/' + RoutingConfig.Page404).catch()
        }
        return throwError(error);
      })
    );
  }

  /**
   * Api: v01.
   * Method: Bulletins Search Artist.
   * @param request
   * @param page
   * @param size
   */
  public searchArtists(request: any, page?: number, size?: number): Observable<any> {
    return this.http.post<any>(AppApiURL + `/search/artists?page=${page ? page : 1}&pageSize=${size ? size : 10}`,
      request,
      {
        withCredentials: true,
        observe: 'response',
        reportProgress: false,
        params: new HttpRequestToken(true)
      }
    ).pipe(
      map((response: HttpResponse<any>) => response.body),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  /**
   * Api: v01.
   * Method: Bulletins Search Bands.
   * @param request
   * @param page
   */
  public searchBands(request: any, page?: number, size?: number): Observable<any> {
    return this.http.post<any>(AppApiURL + `/search/bands?page=${page ? page : 1}&pageSize=${size ? size : 10}`,
      request,
      {
        withCredentials: true,
        observe: 'response',
        reportProgress: false,
        params: new HttpRequestToken(true)
      }
    ).pipe(
      map((response: HttpResponse<any>) => response.body),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  /**
   * Api: v01.
   * Method: Create bulletins by artist
   * @param request
   */
  public artistSearchBand(request: any): Observable<any> {
    return this.http.post<any>(AppApiURL + `/searchMatch/artistSearchBand`,
      request,
      {
        withCredentials: true,
        observe: 'response',
        reportProgress: false
      }
    ).pipe(
      map((response: HttpResponse<any>) => response.body),
      catchError((error: HttpErrorResponse) => {
        if (error.hasOwnProperty('error')) {
          return throwError(error.error);
        }
        return throwError(error);
      })
    );
  }

  /**
   * Api: v01.
   * Method: Edit bulletins by artist
   * @param request
   */
  public artistSearchBandEdit(request: any): Observable<any> {
    return this.http.put<any>(AppApiURL + `/searchMatch/artistSearchBand`,
      request,
      {
        withCredentials: true,
        observe: 'response',
        reportProgress: false
      }
    ).pipe(
      map((response: HttpResponse<any>) => response.body),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  /**
   * Api: v01.
   * Method: Create bulletins by band
   * @param request
   */
  public bandSearchArtist(request: any): Observable<any> {
    return this.http.post<any>(AppApiURL + `/searchMatch/bandSearchArtist`,
      request,
      {
        withCredentials: true,
        observe: 'response',
        reportProgress: false
      }
    ).pipe(
      map((response: HttpResponse<any>) => response.body),
      catchError((error: HttpErrorResponse) => {
        if (error.hasOwnProperty('error')) {
          return throwError(error.error);
        }
        return throwError(error);
      })
    );
  }
  /**
   * Api: v01.
   * Method: Edit bulletins by band
   * @param request
   */
  public bandSearchArtistEdit(request: any): Observable<any> {
    return this.http.put<any>(AppApiURL + `/searchMatch/bandSearchArtist`,
      request,
      {
        withCredentials: true,
        observe: 'response',
        reportProgress: false
      }
    ).pipe(
      map((response: HttpResponse<any>) => response.body),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  /**
   * Api: v01.
   * Method: Get bulletin by id
   * @param id
   */
  public bulletinDetailPhone(id: number): Observable<any> {
    return this.http.post<any>(AppApiURL + `/bulletins/${id}/getPhone`,
      {
        withCredentials: true,
        observe: 'response',
        reportProgress: false
      }
    ).pipe(
      map((response: HttpResponse<any>) => response),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  /**
   * Api: v01.
   * Method: Get of active bulletins for user
   */
  public bulletinsActive(): Observable<Array<any>> {
    return this.http.get<Array<any>>(AppApiURL + `/musicianSearchBulletins/active`,
      {
        withCredentials: true,
        observe: 'response',
        reportProgress: false
      }
    ).pipe(
      map((response: HttpResponse<any>) => response.body),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  /**
   * Api: v01.
   * Method: Get of expired bulletins for user
   */
  public bulletinsExpired(): Observable<Array<any>> {
    return this.http.get<Array<any>>(AppApiURL + `/musicianSearchBulletins/expired`,
      {
        withCredentials: true,
        observe: 'response',
        reportProgress: false
      }
    ).pipe(
      map((response: HttpResponse<any>) => response.body),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  /**
   * Api: v01.
   * Method: Get of blocked bulletins for user
   */
  public bulletinsBlocked(): Observable<Array<any>> {
    return this.http.get<Array<any>>(AppApiURL + `/musicianSearchBulletins/blocked`,
      {
        withCredentials: true,
        observe: 'response',
        reportProgress: false
      }
    ).pipe(
      map((response: HttpResponse<any>) => response.body),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  /**
   * Api: v01.
   * Method: Stop of current bulletin
   * @param id 1 - нашел здесь 2 - нашел в другом месте 3 - другая причина (reason обязателен)
   * @param reason
   */
  public bulletinStop(id: number): Observable<any> {
    const reason = {
      bulletinCloseReason: 1,
      reason: ''
    };
    return this.http.post<any>(AppApiURL + `/musicianSearchBulletins/${id}/close`,
      reason,
      {
        withCredentials: true,
        observe: 'response',
        reportProgress: false
      }
    ).pipe(
      map((response: HttpResponse<any>) => response.body),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  /**
   * Api: v01.
   * Method: Reactivate of current bulletin
   * @param id
   */
  public bulletinActivate(id: number): Observable<any> {
    return this.http.put<any>(AppApiURL + `/musicianSearchBulletins/activate`,
      {
        id: id
      },
      {
        withCredentials: true,
        observe: 'response',
        reportProgress: false
      }
    ).pipe(
      map((response: HttpResponse<any>) => response.body),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  /**
   * Api: v01.
   * Method: Delete of current bulletin
   * @param id
   */
  public bulletinDelete(id: number): Observable<any> {
    return this.http.delete<any>(AppApiURL + `/musicianSearchBulletins/${id}`,
      {
        withCredentials: true,
        observe: 'response',
        reportProgress: false
      }
    ).pipe(
      map((response: HttpResponse<any>) => response.body),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  /**
   * Api: v01.
   * Method: Checkin gof soundcloud URL
   */
  public checkSoundcloudUrl(trackUrl: string): any {
    return this.http.get<any>(AppApiURL + `/searchMatch/checkSoundcloudUrl?${trackUrl}`,
      {
        withCredentials: true,
        observe: 'response',
        reportProgress: false
      }
    ).pipe(
      map((response: HttpResponse<any>) => response.body),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }
}
