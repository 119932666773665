import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, Input, OnInit } from '@angular/core';
import { AppStaticURL } from '@app/app.settings';
import { DevicesClass, DeviceTypes } from '@app/core/classes/component/devices.class';
import { IPartnerPublicProduct } from '@app/modules/partner-modules/partner.model';
import { RoutingConfig } from '@app/routing/routing.config';
import { IAccountUserInfo } from '@app/modules/account-modules/account.model';
import { PartnerStoryService } from '@app/modules/partner-modules/partner.story';
import { DialogComponentModel, DialogIncludesTypes } from '@app/core/models/diallog';
import { DialogService } from 'primeng/dynamicdialog';
import { _deviceType } from '@app/utils/device';

export enum ProductItemViewTypes {
  Block,          // 0
  Detail,         // 1
  Profile,         // 1
}

/**
 * Components
 */
import { DetailProductComponent } from '@app/modules/partner-modules/detail-product/detail-product.component';

@Component({
  selector: 'shared-product-item',
  templateUrl: 'product-item.component.html',
  styleUrls: [`./product-item.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ProductItemComponent extends DevicesClass implements OnInit {
  public StaticURL = AppStaticURL;
  public Routing = RoutingConfig;
  public PITypes = ProductItemViewTypes;
  public PIUserAuth: IAccountUserInfo = null;

  /**
   * Special component variables.
   */
  public maskOptions = {
    align: 'left',
    prefix: '',
    thousands: ' ',
    decimal: '',
    precision: 0,
    max: 1000000
  };

  @Input() ProductView: ProductItemViewTypes;
  @Input() ProductItem: IPartnerPublicProduct;

  constructor(injector: Injector,
    public cdr: ChangeDetectorRef,
    public partnerStory: PartnerStoryService,
    private dialogService: DialogService,
  ) {
    super(injector);
  }

  ngOnInit(): void {
  }

  onEditProductProfile(): void {
    this.partnerStory.PartnerEventBus.next({
      event: 'onProductEdit',
      data: this.ProductItem
    });
  }

  onDeleteProductProfile(): void {
    this.partnerStory.PartnerEventBus.next({
      event: 'onProductDelete',
      data: this.ProductItem
    })
  }

  onViewProductDetail(): void {
    this.dialogService.open(DetailProductComponent, {
      header: this.ProductItem.title,
      width: _deviceType() === DeviceTypes.Mobile ? '90%' : '100%',
      styleClass: 'p-dialog__products',
      data: {
        includeType: DialogIncludesTypes.ComponentTemplate,
        includeData: this.ProductItem
      } as DialogComponentModel
    });
  }

  get onProductPhoto(): string {
    return this.ProductItem.photos.length ? this.ProductItem.photos[0].path : null;
  }
}
