import { NgModule } from '@angular/core';
import { TimeMaskDirective } from './timemask.directive';

@NgModule({
  declarations: [
    TimeMaskDirective
  ],
  exports: [
    TimeMaskDirective
  ]
})

export class TimeMaskModule {}
