import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { PartnerItemComponent } from './partner-item.component';

/**
 * Pipes
 */
import { PipesCoreModule } from '@app/core/pipes/pipes.module';

/**
 * Vendors
 */
import { ChipModule } from 'primeng/chip';
import { RippleModule } from 'primeng/ripple';
import { TooltipModule } from 'primeng/tooltip';

/**
 * Shared
 */
import { PhotosGalleryModule } from '@app/shared/photos-gallery/photos-gallery.module';
import { ProductItemModule } from '@app/shared/product-item/product-item.module';

@NgModule({
  declarations: [
    PartnerItemComponent
  ],
  exports: [
    PartnerItemComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    PipesCoreModule,
    PipesCoreModule,
    ChipModule,
    RippleModule,
    TooltipModule,
    PhotosGalleryModule,
    ProductItemModule
  ]
})

export class PartnerItemModule {
  constructor() {}
}
