import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, Input, OnInit } from '@angular/core';
import { DevicesClass } from '@app/core/classes/component/devices.class';

export enum SkeletonViewTypes {
  Profile,    // 0
  Search,     // 1
  Loyalty,    // 2
}

@Component({
  selector: 'shared-skeleton-item',
  templateUrl: 'skeleton-item.component.html',
  styleUrls: [`./skeleton-item.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class SkeletonItemComponent extends DevicesClass implements OnInit {
  public SITypes = SkeletonViewTypes;

  @Input() SkeletonView: SkeletonViewTypes;

  constructor(injector: Injector,
    public cdr: ChangeDetectorRef
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.cdr.markForCheck()
  }
}
