import { Pipe, PipeTransform } from '@angular/core';
import { IBulletinDetail } from '@app/modules/bulletins-modules/bulletins.model';
import { InstrumentModel } from '@app/modules/fundamentals-modules/fundamentals.models';
import { FundamentalsService } from '@app/modules/fundamentals-modules/fundamentals.service';

@Pipe({
  name: 'bulletinInstrument'
})

export class BulletinInstrumentPipe implements PipeTransform {
  constructor(
    private fundamentalsService: FundamentalsService
  ) {}

  transform(bulletin: IBulletinDetail, performer: boolean = false): string {
    if (bulletin.type === 'ArtistSearchBand' || bulletin.type === 0) {
      return this.getInstrumentText(bulletin.musicianViewModel.instrumentId);
    }

    if (bulletin.type === 'BandSearchArtist' || bulletin.type === 1) {
      return this.getInstrumentText(bulletin.bandRequestViewModel.instrumentId);
    }


    // if (performer) {
    //   return this.bulletin.bulletinInstrument(bulletin.musicianViewModel.instrumentId);
    // }
  }

  getInstrumentText(instrumentId: number): string {
    const InstrumentsDependencies = this.fundamentalsService.instrumentsDependencies || [];

    const Instrument = <InstrumentModel>InstrumentsDependencies.filter(
      item => item.id === instrumentId
    )[0] || null;

    return Instrument.genetiveForArtistTitle;
  }
}
