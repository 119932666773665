import { Pipe, PipeTransform } from '@angular/core';
import { IBulletinDetail } from '@app/modules/bulletins-modules/bulletins.model';
import { FundamentalsService } from '@app/modules/fundamentals-modules/fundamentals.service';

/**
 * Getting of self experience description.
 * @key experienceYears -- enum
 */

@Pipe({
  name: 'bulletinAboutExperience'
})

export class BulletinAboutExperiencePipe implements PipeTransform {
  constructor(
    private fundamentalsService: FundamentalsService
  ) {}

  transform(bulletin: IBulletinDetail): string {
    const ExperienceValue = this.fundamentalsService.experienceRecommendations.filter(item => {
      return item.id === (
        bulletin.type === 'ArtistSearchBand' || bulletin.type === 0 ?
        bulletin.artistAboutViewModel.experienceYears :
        bulletin.bandAboutViewModel.experienceYears
      );
    });
    return ExperienceValue.length ? ExperienceValue[0].name : 'Не важно';
  }
}

/**
 * Getting of search params experience description.
 * @key experienceYears -- enum
 */

@Pipe({
  name: 'bulletinSearchExperience'
})

export class BulletinSearchExperiencePipe implements PipeTransform {
  constructor(
    private fundamentalsService: FundamentalsService
  ) {}

  transform(bulletin: IBulletinDetail): string {
    const ExperienceValue = this.fundamentalsService.experienceRecommendations.filter(item => {
      return item.id === (
        bulletin.type === 'ArtistSearchBand' || bulletin.type === 0 ?
        bulletin.artistRequestViewModel.experienceYears :
        bulletin.bandRequestViewModel.experienceYears
      );
    });
    return ExperienceValue.length ? ExperienceValue[0].name : 'Не важно';
  }
}


/**
 * Getting of self concert experience description.
 * @key concertExperienceYears -- enum
 * @key startConcertExperience -- enum
 */

@Pipe({
  name: 'bulletinAboutConcertExperience'
})

export class BulletinAboutConcertExperiencePipe implements PipeTransform {
  constructor(
    private fundamentalsService: FundamentalsService
  ) {}

  transform(bulletin: IBulletinDetail): string {
    const ExperienceValue = this.fundamentalsService.experienceRecommendations.filter(item => {
      return item.id === (
        bulletin.type === 'ArtistSearchBand' || bulletin.type === 0 ?
          bulletin.artistAboutViewModel.startConcertExperience :
          bulletin.bandAboutViewModel.concertExperienceYears
      );
    });
    return ExperienceValue.length ? ExperienceValue[0].name : 'Не важно';
  }
}


/**
 * Getting of search concert experience description.
 * @key concertExperienceYears -- enum
 * @key startConcertExperience -- enum
 */

@Pipe({
  name: 'bulletinSearchConcertExperience'
})

export class BulletinSearchConcertExperiencePipe implements PipeTransform {
  constructor(
    private fundamentalsService: FundamentalsService
  ) {}

  transform(bulletin: IBulletinDetail): string {
    const ExperienceValue = this.fundamentalsService.experienceRecommendations.filter(item => {
      return item.id === (
        bulletin.type === 'ArtistSearchBand' || bulletin.type === 0 ?
          bulletin.artistRequestViewModel.concertExperienceYears :
          bulletin.bandRequestViewModel.concertExperience
      );
    });
    return ExperienceValue.length ? ExperienceValue[0].name : 'Не важно';
  }
}
