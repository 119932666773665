import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DevicesClass } from '@app/core/classes';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PartnerService } from '@app/modules/partner-modules/partner.service';
import { IPartnerMusicianInfo, IPartnerSaleMake } from '@app/modules/partner-modules/partner.model';
import { ReplaySubject } from 'rxjs';
import { DialogIncludesTypes } from '@app/core/models/diallog';
import { NotificationService } from '@app/modules/notification-modules/notification.service';
import { RoutingConfig } from '@app/routing/routing.config';

declare var $;

export interface BaseErrorModel {
  status: boolean;
  value: string;
}

export interface QRSaleErrorModels {
  price: BaseErrorModel
}

@UntilDestroy()
@Component({
  selector: 'app-qr-scan',
  templateUrl: 'qr-scan.component.html',
  styleUrls: [`./qr-scan.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})

export class QrScanComponent extends DevicesClass implements OnInit {
  /**
   * Base component variables
   */
  public QRLoading: boolean;
  public QRCodeLength: number = 9;
  public QRCodeValid: boolean;
  public QRCodeValue: string;
  public QRUserInfo: ReplaySubject<IPartnerMusicianInfo> = new ReplaySubject<IPartnerMusicianInfo>(1);
  public QRUserSale: FormGroup;

  /**
   * Errors view variables
   */
  public ErrorsField: QRSaleErrorModels;
  public ErrorsText: string;

  @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;

  constructor(injector: Injector,
    public cdr: ChangeDetectorRef,
    public route: ActivatedRoute,
    public router: Router,
    private formBuilder: FormBuilder,
    private partnerService: PartnerService,
    private notificationService: NotificationService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.route.params.pipe(untilDestroyed(this)).subscribe(params => {
      const QRCode = params.id || null;

      if (QRCode) {
        setTimeout(() => {
          this.ngOtpInput.setValue(QRCode);
          this.onInputQRCode(QRCode);
          this.onSubmitQRCode();
        }, 500);
      }
    });

    /**
     * Build of QR Sale form.
     */
    this.QRUserSale = this.formBuilder.group({
      price : [null, Validators.required],
      musicianCartCode : ['', Validators.required],
      comment : [''],
    });

    /**
     * Set of default errors value.
     */
    this.ErrorsField = {
      price: {status: false, value: ''}
    };

    /**
     * Form changes control.
     */
    this.onQRSaleFormCtrl();
  }

  onToggleViewErrors(show: boolean): void {
    const FieldErrors = $('.__qr__errors');
    show ? FieldErrors.slideDown(150) : FieldErrors.slideUp(150);
  }

  onQRLoading(status: boolean, delay: number = 0) {
    setTimeout(() => {
      this.QRLoading = status;
      this.cdr.detectChanges();
    }, delay);
  }

  onQRSaleFormCtrl(): void {
    for (const key of Object.keys(this.ErrorsField)) {
      if (this.QRUserSale.controls.hasOwnProperty(key)) {
        this.QRUserSale.controls[key].valueChanges.pipe(untilDestroyed(this)).subscribe(
          value => {
            this.ErrorsField[key].status = false;
            this.ErrorsText = '';

            this.onToggleViewErrors(false);
          }
        );
      }
    }
  }

  onInputQRCode(event: any): void {
    this.QRCodeValue = event;

    if (event.length === this.QRCodeLength) {
      this.QRCodeValid = true;
      this.cdr.markForCheck();
    }
  }

  onSubmitQRCode(): void {
    if (!this.QRCodeValid) {
      return;
    }
    this.partnerService.partnerSalesMusicianInfo(this.QRCodeValue).subscribe(
      response => {
        this.QRUserInfo.next(response);
        this.QRUserSale.controls.musicianCartCode.patchValue(this.QRCodeValue);
        this.cdr.markForCheck();
      },
      error => {
        this.notificationService.onDialog({
          type: DialogIncludesTypes.PopupErrorTemplate,
          header: 'Ошибка номера карты',
          content: `${error?.error?.message} <br/> Не удалось найти клиента по введеному номеру.`,
        });
      }
    );
  }

  onResetQRCode(): void {
    this.router.navigateByUrl(`/${RoutingConfig.Account}/${RoutingConfig.AccountQRScan}`).catch();
  }

  onSubmitSalePoints(): void {
    /**
     * Validation of Errors Fields.
     */
    for (const field of Object.keys(this.ErrorsField)) {
      if (this.ErrorsField[field].status) {
        this.ErrorsText = 'Проверьте правильность заполненных полей!';
        return this.onToggleViewErrors(true);
      }
    }

    /**
     * Initial validation.
     */
    if (!this.QRUserSale.valid) {
      for (const key of Object.keys(this.QRUserSale.controls)) {
        if (this.ErrorsField.hasOwnProperty(key)) {
          this.ErrorsField[key].status = !this.QRUserSale.controls[key].valid;

          if (key === 'cityId' && this.QRUserSale.controls[key].value === 0) {
            this.ErrorsField[key].status = true;
          }
        }
      }
      this.onQRLoading(false);

      /**
       * View errors description.
       */
      this.ErrorsText = 'Обязательные поля не могут быть пустыми!';
      return this.onToggleViewErrors(true);
    }

    /**
     * Loading.
     */
    this.onQRLoading(true);

    /**
     * Set of user sale.
     */
    this.partnerService.partnerSaleMake(this.QRUserSale.getRawValue() as IPartnerSaleMake).subscribe(
      response => {
        /**
         * Reset loading spinner.
         */
        this.onQRLoading(false);

        this.notificationService.onDialog({
          type: DialogIncludesTypes.PopupSuccessTemplate,
          header: 'Готово',
          content: `Транзакция успешно проведена.`,
        });
      },
      error => {
        /**
         * Reset loading spinner.
         */
        this.onQRLoading(false);

        this.notificationService.onDialog({
          type: DialogIncludesTypes.PopupErrorTemplate,
          header: 'Ошибка транзакции',
          content: `Не удалось совершить транзакцию с введенными параметрами.`,
        });
      }
    );
  }
}
