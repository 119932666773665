import { AppApiURL } from '@app/app.settings';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { RoutingConfig } from '@app/routing/routing.config';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { AuthResponseSuccess } from '@app/modules/auth-modules/auth.model';
import { catchError } from 'rxjs/operators';
import { HttpRequestToken, HttpRequestFile } from '@app/core/classes';
import {
  IPartnerSignUp,
  IPartnerUserInfo,
  IPartnerPutType,
  IPartnerProfile,
  IPartnerDetail,
  IPartnerSaleMake,
  IPartnerSaleMakeResponse,
  IPartnerMusicianInfo,
  IPartnerUserContacts,
  IPartnerPutProfile,
  IPartnerWorkingHours,
  IPartnerAdditionalAmenities,
  IPartnerProduct,
  IPartnerSaleLists
} from './partner.model';

@Injectable()

export class PartnerService {

  constructor(
    private http: HttpClient,
    private router: Router,
  ) {}

  /**
   * Api: v01.
   * Method: Partner panel upload pic
   * @params formData
   */
  public uploadPic(files: FileList): Observable<any> {
    const formData = new FormData();
    formData.append('file', files[0]);

    return this.http.post<any>(AppApiURL + '/loyalty/partner/panel/uploadPic', formData, {
      params: new HttpRequestFile(true)
    }).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error.error || 'Не удалось загрузить картинку!');
      })
    );
  }

  /**
   * Api: v01.
   * Method: Partner panel upload pic
   * @params formData
   */
  public uploadLogo(files: FileList): Observable<any> {
    const formData = new FormData();
    formData.append('file', files ? files[0] : null);

    return this.http.put<any>(AppApiURL + '/loyalty/partner/panel/logo', formData, {
      params: new HttpRequestFile(true)
    }).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error.error || 'Не удалось загрузить картинку!');
      })
    );
  }

  /**
   * Api: v01.
   * Method:  Partner SignUp
   * @param request
   * @param recaptcha
   */
  public partnerSignUp(request: IPartnerSignUp, recaptcha: string): Observable<AuthResponseSuccess> {
    return this.http.post<AuthResponseSuccess>(AppApiURL + `/loyalty/partner/panel/registration`, request, {
      params: new HttpRequestToken(true),
      headers: {
        'g-recaptcha-response': recaptcha
      }
    }).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  /**
   * Api: v01.
   * Method:  Partner Profile
   */
  public partnerProfile(): Observable<IPartnerProfile> {
    return this.http.get<IPartnerProfile>(AppApiURL + `/loyalty/partner/panel/profile`).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  /**
   * Api: v01.
   * Method:  Partner UserInfo
   */
  public partnerUserInfo(): Observable<IPartnerUserInfo> {
    return this.http.get<IPartnerUserInfo>(AppApiURL + `/loyalty/partner/panel/partnerUserInfo`).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  /**
   * Api: v01.
   * Method:  Partner make a sale.
   * @param request
   */
  public partnerSaleMake(request: IPartnerSaleMake): Observable<IPartnerSaleMakeResponse> {
    return this.http.post<IPartnerSaleMakeResponse>(AppApiURL + `/loyalty/partner/panel/sales`, request).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  /**
   * Api: v01.
   * Method:  Sales MusicianInfo
   * @param page
   * @param size
   * @param getSalesBy: 0 - year, 1 - month, 2 - today
   */
  public partnerSales(page?: number, size?: number, getSalesBy?: number): Observable<IPartnerSaleLists> {
    return this.http.get<IPartnerSaleLists>(AppApiURL + `/loyalty/partner/panel/sales?page=${page ? page : 1}&pageSize=${size ? size : 50}&getSalesBy=${getSalesBy ? getSalesBy : 0}`).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  /**
   * Api: v01.
   * Method:  Sales MusicianInfo
   * @param loyaltyCartId
   */
  public partnerSalesSummary(): Observable<IPartnerMusicianInfo> {
    return this.http.get<IPartnerMusicianInfo>(AppApiURL + `/loyalty/partner/panel/sales/summary?getSalesBy=0`).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  /**
   * Api: v01.
   * Method:  Sales MusicianInfo
   * @param loyaltyCartId
   */
  public partnerSalesMusicianInfo(loyaltyCartId: string): Observable<IPartnerMusicianInfo> {
    return this.http.get<IPartnerMusicianInfo>(AppApiURL + `/loyalty/partner/panel/sales/musicianInfo?loyaltyCartId=${loyaltyCartId}`).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  /**
   * Api: v01.
   * Method:  Partner update info contacts
   * @param request
   */
  public partnerPutInfoContacts(request: IPartnerUserContacts): Observable<any> {
    return this.http.put<any>(AppApiURL + `/loyalty/partner/panel/info/contacts`, request).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  /**
   * Api: v01.
   * Method:  Partner update info contacts
   * @param request
   */
  public partnerPutProfile(request: IPartnerPutProfile): Observable<any> {
    return this.http.put<any>(AppApiURL + `/loyalty/partner/panel/profile`, request).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  /**
   * Api: v01.
   * Method:  Partner update info contacts
   * @param request
   */
  public partnerPutType(request: IPartnerPutType): Observable<any> {
    return this.http.put<any>(AppApiURL + `/loyalty/partner/panel/profile/changePartnerType`, request).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  /**
   * Api: v01.
   * Method:  Partner update workingHours
   * @param request
   */
  public partnerPutWorkingHours(request: IPartnerWorkingHours): Observable<any> {
    return this.http.put<any>(AppApiURL + `​/loyalty/partner/panel/info/workingHours`, request).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  /**
   * Api: v01.
   * Method:  Partner update description
   * @param description
   */
  public partnerPutDescription(description: string): Observable<any> {
    return this.http.put<any>(AppApiURL + `​/loyalty/partner/panel/info/description`, {
      description: description
    }).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  /**
   * Api: v01.
   * Method:  Partner update description
   * @param discount
   */
  public partnerPutDiscount(discount: number): Observable<any> {
    return this.http.put<any>(AppApiURL + `​/loyalty/partner/panel/info/discount`, {
      discount: discount
    }).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  /**
   * Api: v01.
   * Method:  Partner update address
   * @param description
   */
  public partnerPutAddress(description: string): Observable<any> {
    return this.http.put<any>(AppApiURL + `​/loyalty/partner/panel/info/address`, {
      longitude: 0,
      latitude: 0,
      description: description,
    }).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  /**
   * Api: v01.
   * Method:  Partner update additional amenities
   * @param request
   */
  public partnerPutAdditionalAmenities(request: IPartnerAdditionalAmenities): Observable<any> {
    return this.http.put<any>(AppApiURL + `​/loyalty/partner/panel/info/additionalAmenities`, request).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  /**
   * Api: v01.
   * Method:  Partner update photo
   * @param photos
   */
  public partnerPutPhotos(photos: Array<string>): Observable<any> {
    return this.http.put<any>(AppApiURL + `​/loyalty/partner/panel/photos`, {
      picKeys: photos
    }).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  /**
   * Api: v01.
   * Method:  Partner publish profile
   * @param isEnabled
   */
  public partnerPublish(isEnabled: boolean): Observable<any> {
    return this.http.post<any>(AppApiURL + `/loyalty/partner/panel/publish`, {isEnabledProfile: isEnabled}).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  /**
   * Api: v01.
   * Method: Loyalty Partner Detail
   * @id number
   */
  public publicPartnerDetail(id: number): Observable<IPartnerDetail> {
    return this.http.get<IPartnerDetail>(AppApiURL + `/loyalty/partners/${id}`).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 404) {
          this.router.navigateByUrl(RoutingConfig.PageErrors + '/' + RoutingConfig.Page404).catch()
        }
        return throwError(error);
      })
    );
  }

  /**
   * Api: v01.
   * Method:  Partner add of product
   * @param product
   */
  public addPartnerProducts(product: IPartnerProduct): Observable<any> {
    return this.http.post<any>(AppApiURL + `/loyalty/partner/panel/products`, product).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.hasOwnProperty('error')) {
          return throwError(error.error);
        }
        return throwError(error);
      })
    );
  }

  /**
   * Api: v01.
   * Method:  Partner edit of product
   * @param product
   */
  public editPartnerProducts(product: IPartnerProduct): Observable<any> {
    return this.http.put<any>(AppApiURL + `/loyalty/partner/panel/products`, product).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.hasOwnProperty('error')) {
          return throwError(error.error);
        }
        return throwError(error);
      })
    );
  }

  /**
   * Api: v01.
   * Method:  Delete of partner's product
   * @param productId
   */
  public deletePartnerProducts(productId: number): Observable<any> {
    return this.http.delete<any>(AppApiURL + `/loyalty/partner/panel/products/${productId}`).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }
}
