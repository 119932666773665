<div class="account-shell__loading animated faster fadeIn" *ngIf="ProductsLoading">
  <div class="ui-loader ui-loader__page-light"></div>
</div>

<div class="account-shell__content"
  ngClass="{{ProductsLoading ? 'loading' : ''}}">

  <div class="pg-wrapper" *ngIf="ProductsPartnerSubject | async as Partner">
    <div class="account-shell__content-wrapper"
      ngClass="{{(deviceValue | async) === DTypes.Mobile ? '' : 'pl-3 pr-3'}}">

      <div class="account-shell__content-subtitle">
        <div class="grid align-items-center"
          ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : ''}}">

          <div class="col-8 col-sm-12">
            <h5>Товары и услуги</h5>
            <div class="grid align-items-start pt-2">
              <p class="txt-caption__large">Вы можете добавлять свои товары и услуги, которые будут отображаться на Вашей странице.</p>
            </div>
          </div>
          <div class="col-4 col-sm-12 products__btn" *ngIf="!onProductIsEmpty()">
            <button class="ui-buttons ui-buttons__blue-small" (click)="onAddProduct()">
              Добавить
            </button>
          </div>
        </div>


      </div>
    </div>

    <div class="account-shell__content-wrapper" *ngIf="!onProductIsEmpty()"
      ngClass="{{(deviceValue | async) === DTypes.Mobile ? '' : 'pl-3 pr-3'}}">

      <ng-container *ngFor="let product of ProductArray;">
        <shared-product-item [ProductItem]="product" [ProductView]="PIVTypes.Profile"></shared-product-item>
      </ng-container>
    </div>


    <div class="account-shell__content-wrapper" *ngIf="onProductIsEmpty()"
      ngClass="{{(deviceValue | async) === DTypes.Mobile ? '' : 'pl-3 pr-3'}}">

      <div class="row">
        <div class="products__empty status-warning">
          <div class="grid align-items-center justify-content-center flex-column">
            <div class="col-12 col-sm-12 text-center">
              <span class="mr-icon mr-icon-info mt-2 mb-4"></span>
              <h5>Отсутствуют товары или услуги</h5>
              <p class="mt-1 mb-5">
                У Вас пока нет ни одного созданного товара или услуги.
              </p>
            </div>
            <div class="col-3 col-sm-6">
              <button class="ui-buttons ui-buttons__blue-large" (click)="onAddProduct()">
                Добавить
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<p-confirmDialog #default [key]="'default'" [appendTo]="'body'" [styleClass]="'p-dialog__confirm'">
  <p-footer>
    <button class="ui-buttons ui-buttons__light-small" (click)="default.reject()">Отменить</button>
    <button class="ui-buttons ui-buttons__blue-small" (click)="default.accept()">Удалить</button>
  </p-footer>
</p-confirmDialog>
