import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, Input, OnInit } from '@angular/core';
import { DevicesClass } from '@app/core/classes';
import { RoutingConfig } from '@app/routing/routing.config';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DialogComponentModel } from '@app/core/models/diallog';
import { IPartnerPublicProduct } from '@app/modules/partner-modules/partner.model';
import { AppStaticURL } from '@app/app.settings';

@UntilDestroy()
@Component({
  selector: 'app-partner-crud-product',
  templateUrl: 'detail-product.component.html',
  styleUrls: [`./detail-product.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class DetailProductComponent extends DevicesClass implements OnInit {
  public StaticURL = AppStaticURL;
  public Routing = RoutingConfig;

  /**
   * Base component variables.
   */
  public Product: IPartnerPublicProduct;

  @Input() Data: any;

  constructor(injector: Injector,
    public cdr: ChangeDetectorRef,
    public ref: DynamicDialogRef,
    private router: Router,
    private dialogConfig: DynamicDialogConfig
  ) {
    super(injector);
  }

  ngOnInit(): void {
    /**
     * Set of base popup settings.
     */
    this.onProductInputConfig();
  }

  onProductInputConfig(): void {
    const InputConfig = this.dialogConfig.data as DialogComponentModel || null;
    this.Product = InputConfig.includeData as any || null;
  }

  onCloseDialog(): void {
    this.ref.close()
  }

  get onProductMainPhoto(): string {
    return this.Product.photos.length ? this.Product.photos[0].path : null;
  }
}
