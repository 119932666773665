import { Pipe, PipeTransform } from '@angular/core';
import { FundamentalsService } from '@app/modules/fundamentals-modules/fundamentals.service';
import { IPartnerItem, IPartnerDetail } from '@app/modules/partner-modules/partner.model';

export type IHeadingPipeType = 'detailType' | 'ssrType';

@Pipe({
  name: 'partnerHeading'
})

export class PartnerHeadingPipe implements PipeTransform {
  constructor(
    private fundamentalsService: FundamentalsService
  ) {}

  transform(partner: any, type?: IHeadingPipeType): string {
    const typeName = this.fundamentalsService.partnersTypeRecommendations[partner.partnerType]?.name || null;

    if (type && type === 'detailType') {
      return typeName ? `${typeName} «${(partner as IPartnerDetail).title}»` : (partner as IPartnerDetail).title;
    }

    return typeName ? `${typeName} «${(partner as IPartnerItem).name}»` : (partner as IPartnerItem).name;
  }
}
