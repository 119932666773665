<div class="skeleton" *ngIf="SkeletonView === SITypes.Profile">
  <div class="row mt-8 mb-4">
    <div class="grid align-items-center">
      <div class="col-4">
        <p-skeleton width="100%" height="{{(deviceValue | async) === DTypes.Mobile ? '30px' : '60px'}}" [styleClass]="'p-skeleton__light'"></p-skeleton>
      </div>
      <div class="col-4">
        <p-skeleton width="100%" height="{{(deviceValue | async) === DTypes.Mobile ? '30px' : '60px'}}" [styleClass]="'p-skeleton__light'"></p-skeleton>
      </div>
      <div class="col-4">
        <p-skeleton width="100%" height="{{(deviceValue | async) === DTypes.Mobile ? '30px' : '60px'}}" [styleClass]="'p-skeleton__light'"></p-skeleton>
      </div>
    </div>
  </div>

  <div class="row mt-2 mb-2">
    <div class="grid align-items-center">
      <div class="col-10">
        <p-skeleton width="100%" height="{{(deviceValue | async) === DTypes.Mobile ? '100px' : '130px'}}" [styleClass]="'p-skeleton__light'"></p-skeleton>
      </div>
      <div class="col-2">
        <p-skeleton width="100%" height="{{(deviceValue | async) === DTypes.Mobile ? '100px' : '130px'}}" [styleClass]="'p-skeleton__light'"></p-skeleton>
      </div>
    </div>
  </div>
  <div class="row mt-2 mb-2">
    <div class="grid align-items-center">
      <div class="col-10">
        <p-skeleton width="100%" height="{{(deviceValue | async) === DTypes.Mobile ? '100px' : '130px'}}" [styleClass]="'p-skeleton__light'"></p-skeleton>
      </div>
      <div class="col-2">
        <p-skeleton width="100%" height="{{(deviceValue | async) === DTypes.Mobile ? '100px' : '130px'}}" [styleClass]="'p-skeleton__light'"></p-skeleton>
      </div>
    </div>
  </div>
  <div class="row mt-2 mb-2">
    <div class="grid align-items-center">
      <div class="col-10">
        <p-skeleton width="100%" height="{{(deviceValue | async) === DTypes.Mobile ? '100px' : '130px'}}" [styleClass]="'p-skeleton__light'"></p-skeleton>
      </div>
      <div class="col-2">
        <p-skeleton width="100%" height="{{(deviceValue | async) === DTypes.Mobile ? '100px' : '130px'}}" [styleClass]="'p-skeleton__light'"></p-skeleton>
      </div>
    </div>
  </div>
</div>



<div class="skeleton" *ngIf="SkeletonView === SITypes.Search">
  <div class="row mt-12 mb-4">
    <div class="grid align-items-center"
      ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : ''}}">

      <div class="col-6 col-sm-12"
        ngClass="{{(deviceValue | async) === DTypes.Mobile ? '' : 'mr-2'}}">

        <p-skeleton width="100%" height="{{(deviceValue | async) === DTypes.Mobile ? '100px' : '220px'}}" [styleClass]="'p-skeleton__light'"></p-skeleton>
      </div>
      <div class="col-6 col-sm-12"
        ngClass="{{(deviceValue | async) === DTypes.Mobile ? '' : 'ml-2'}}">

        <p-skeleton width="100%" height="{{(deviceValue | async) === DTypes.Mobile ? '100px' : '220px'}}" [styleClass]="'p-skeleton__light'"></p-skeleton>
      </div>
    </div>
  </div>
  <div class="row mt-2 mb-4">
    <div class="grid align-items-center"
      ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : ''}}">

      <div class="col-6 col-sm-12"
        ngClass="{{(deviceValue | async) === DTypes.Mobile ? '' : 'mr-2'}}">

        <p-skeleton width="100%" height="{{(deviceValue | async) === DTypes.Mobile ? '100px' : '220px'}}" [styleClass]="'p-skeleton__light'"></p-skeleton>
      </div>
      <div class="col-6 col-sm-12"
        ngClass="{{(deviceValue | async) === DTypes.Mobile ? '' : 'ml-2'}}">

        <p-skeleton width="100%" height="{{(deviceValue | async) === DTypes.Mobile ? '100px' : '220px'}}" [styleClass]="'p-skeleton__light'"></p-skeleton>
      </div>
    </div>
  </div>
  <div class="row mt-2 mb-4">
    <div class="grid align-items-center"
      ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : ''}}">

      <div class="col-6 col-sm-12"
        ngClass="{{(deviceValue | async) === DTypes.Mobile ? '' : 'mr-2'}}">

        <p-skeleton width="100%" height="{{(deviceValue | async) === DTypes.Mobile ? '100px' : '220px'}}" [styleClass]="'p-skeleton__light'"></p-skeleton>
      </div>
      <div class="col-6 col-sm-12"
        ngClass="{{(deviceValue | async) === DTypes.Mobile ? '' : 'ml-2'}}">

        <p-skeleton width="100%" height="{{(deviceValue | async) === DTypes.Mobile ? '100px' : '220px'}}" [styleClass]="'p-skeleton__light'"></p-skeleton>
      </div>
    </div>
  </div>
</div>



<div class="skeleton" *ngIf="SkeletonView === SITypes.Loyalty">
  <div class="row mt-6 mb-4">
    <div class="grid align-items-center"
      ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : ''}}">

      <div class="col-6 col-sm-12"
        ngClass="{{(deviceValue | async) === DTypes.Mobile ? '' : 'mr-2'}}">

        <p-skeleton width="100%" height="{{(deviceValue | async) === DTypes.Mobile ? '100px' : '220px'}}" [styleClass]="'p-skeleton__light'"></p-skeleton>
      </div>
      <div class="col-6 col-sm-12"
        ngClass="{{(deviceValue | async) === DTypes.Mobile ? '' : 'ml-2'}}">

        <p-skeleton width="100%" height="{{(deviceValue | async) === DTypes.Mobile ? '100px' : '220px'}}" [styleClass]="'p-skeleton__light'"></p-skeleton>
      </div>
    </div>
  </div>
  <div class="row mt-2 mb-4">
    <div class="grid align-items-center"
      ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : ''}}">

      <div class="col-6 col-sm-12"
        ngClass="{{(deviceValue | async) === DTypes.Mobile ? '' : 'mr-2'}}">

        <p-skeleton width="100%" height="{{(deviceValue | async) === DTypes.Mobile ? '100px' : '220px'}}" [styleClass]="'p-skeleton__light'"></p-skeleton>
      </div>
      <div class="col-6 col-sm-12"
        ngClass="{{(deviceValue | async) === DTypes.Mobile ? '' : 'ml-2'}}">

        <p-skeleton width="100%" height="{{(deviceValue | async) === DTypes.Mobile ? '100px' : '220px'}}" [styleClass]="'p-skeleton__light'"></p-skeleton>
      </div>
    </div>
  </div>
  <div class="row mt-2 mb-4">
    <div class="grid align-items-center"
      ngClass="{{(deviceValue | async) === DTypes.Mobile ? 'flex-column' : ''}}">

      <div class="col-6 col-sm-12"
        ngClass="{{(deviceValue | async) === DTypes.Mobile ? '' : 'mr-2'}}">

        <p-skeleton width="100%" height="{{(deviceValue | async) === DTypes.Mobile ? '100px' : '220px'}}" [styleClass]="'p-skeleton__light'"></p-skeleton>
      </div>
      <div class="col-6 col-sm-12"
        ngClass="{{(deviceValue | async) === DTypes.Mobile ? '' : 'ml-2'}}">

        <p-skeleton width="100%" height="{{(deviceValue | async) === DTypes.Mobile ? '100px' : '220px'}}" [styleClass]="'p-skeleton__light'"></p-skeleton>
      </div>
    </div>
  </div>
</div>
