import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DevicesClass } from '@app/core/classes';
import { BreadcrumbsDataModel } from '@app/core/models/breadcrumbs';
import { RoutingConfig } from '@app/routing/routing.config';
import { AccountService } from '@app/modules/account-modules/account.service';
import { AuthService } from '@app/modules/auth-modules/auth.service';
import { DialogIncludesTypes } from '@app/core/models/diallog';
import { NotificationService } from '@app/modules/notification-modules/notification.service';
import { IRecovertPassword } from '@app/modules/account-modules/account.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
declare var $;

export interface RecoveryPassErrorModels {
  newPassword: boolean;
  newPasswordConfirm: boolean;
}

@UntilDestroy()
@Component({
  selector: 'app-account-recovery-password',
  templateUrl: 'recovery-password.component.html',
  styleUrls: [`./recovery-password.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class RecoveryPasswordComponent extends DevicesClass implements OnInit {
  public BreadcrumbsData: BreadcrumbsDataModel = [
    {
      title: 'Личный кабинет',
      url: '/' + RoutingConfig.Account
    },
    {
      title: 'Изменение пароля',
      url: '/' + RoutingConfig.AccountRecoveryPass,
      active: true
    }
  ];

  /**
   * profile variables.
   */
  public RecoveryPassCode: string;
  public RecoveryPassUserId: string;
  public RecoveryPassForm: FormGroup;
  public RecoveryPassErrorsField: RecoveryPassErrorModels;
  public RecoveryPassErrorsText: string;
  public RecoveryPassLoading: boolean;

  @ViewChild('newPasswordInput', {static: false}) newPasswordInput;
  @ViewChild('repeatPasswordInput', {static: false}) repeatPasswordInput;

  constructor(injector: Injector,
    public cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    private authService: AuthService,
    private notificationService: NotificationService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    /**
     * Getting recovery Code and UserId.
     */
    this.route.queryParams.pipe(untilDestroyed(this)).subscribe(params => {
      this.RecoveryPassCode = params['code'];
      this.RecoveryPassUserId = params['userId'];
    });

    /**
     * Build of change password form.
     */
    this.RecoveryPassForm = this.formBuilder.group({
      newPassword : ['', Validators.required],
      newPasswordConfirm : ['', Validators.required]
    });

    /**
     * Set of default errors value.
     */
    this.RecoveryPassErrorsField = {
      newPassword: false,
      newPasswordConfirm: false,
    };

    /**
     * Form changes control.
     */
    this.onRecoveryPassFormCtrl();
  }

  onRecoveryPassFormCtrl(): void {
    this.RecoveryPassForm.valueChanges.pipe(untilDestroyed(this)).subscribe(
      value => {
        for (const key of Object.keys(this.RecoveryPassErrorsField)) {
          this.RecoveryPassErrorsField[key] = false;
          this.RecoveryPassErrorsText = '';

          this.onToggleViewErrors(false);
        }
      }
    );
  }

  onRecoveryPassLoading(status: boolean, delay: number = 0) {
    setTimeout(() => {
      this.RecoveryPassLoading = status;
      this.cdr.detectChanges();
    }, delay);
  }

  isVisiblePassword(type: string): boolean {
    if (this[type + 'PasswordInput']) {
      return this[type + 'PasswordInput'].nativeElement.type === 'text';
    } else {
      return false;
    }
  }

  onToggleDisplayPassword(type: string): void {
    if (this[type + 'PasswordInput']) {
      const Password = this[type + 'PasswordInput'].nativeElement;

      Password.type === 'password' ? Password.type = 'text' : Password.type = 'password';
    }
  }

  onToggleViewErrors(show: boolean): void {
    const FieldErrors = $('.__recovery-password__errors');
    show ? FieldErrors.slideDown(150) : FieldErrors.slideUp(150);
  }

  onRemindPopup($event: Event): void {
    $event.preventDefault();
    this.authService.onAuthRemindPopup();
  }

  onCoincidencePassword(): boolean {
    return this.RecoveryPassForm.controls.newPassword.value === this.RecoveryPassForm.controls.newPasswordConfirm.value;
  }

  onResetFormValue(): void {
    this.RecoveryPassForm.controls.newPassword.patchValue('', { emitEvent: false });
    this.RecoveryPassForm.controls.newPasswordConfirm.patchValue('', { emitEvent: false });
  }

  onRecoveryPassword(): void {
    if (this.RecoveryPassLoading) {
      return;
    }
    this.onRecoveryPassLoading(true);

    /**
     * Initial validation.
     */
    if (!this.RecoveryPassForm.valid) {
      for (const key of Object.keys(this.RecoveryPassForm.controls)) {
        if (this.RecoveryPassErrorsField.hasOwnProperty(key)) {
          this.RecoveryPassErrorsField[key] = !this.RecoveryPassForm.controls[key].valid;
        }
      }
      /**
       * Hide loading.
       */
      this.onRecoveryPassLoading(false);

      /**
       * View errors description.
       */
      this.RecoveryPassErrorsText = 'Обязательные поля не могут быть пустыми!';
      return this.onToggleViewErrors(true);
    }


    /**
     * Coincidence password.
     */
    if (!this.onCoincidencePassword()) {
      this.onRecoveryPassLoading(false);

      this.RecoveryPassErrorsField.newPassword = true;
      this.RecoveryPassErrorsField.newPasswordConfirm = true;

      /**
       * View errors description.
       */
      this.RecoveryPassErrorsText = 'Пароли должны совпадать!';
      return this.onToggleViewErrors(true);
    }


    /**
     * Checking Code and UserId
     */
    if (!this.RecoveryPassCode || !this.RecoveryPassUserId) {
      this.onRecoveryPassLoading(false);

      this.RecoveryPassErrorsText = 'Восстановление пароля невозможно. Попрбуйте заново воспользоваться формой "Восстановить пароль"';
      return this.onToggleViewErrors(true);
    }


    /**
     * Recovery password request.
     */
    const Request = <IRecovertPassword>{
      userId: this.RecoveryPassUserId,
      password: this.RecoveryPassForm.getRawValue().newPassword,
      confirmPassword: this.RecoveryPassForm.getRawValue().newPasswordConfirm,
      code: this.RecoveryPassCode
    };

    this.accountService.resetPassword(Request).subscribe(
      r => {
        this.notificationService.onDialog({
          type: DialogIncludesTypes.PopupSuccessTemplate,
          header: 'Изменение пароля',
          content: 'Ваш пароль успешно изменен.'
        });

        this.onRecoveryPassLoading(false);
        this.router.navigateByUrl(`/${RoutingConfig.Login}/${RoutingConfig.SignIn}`).catch()
      },
      r => {
        this.notificationService.onDialog({
          type: DialogIncludesTypes.PopupErrorTemplate,
          header: 'Ошибка',
          content: 'Не удалось сохранить Ваш пароль.'
        });

        this.onRecoveryPassLoading(false);
        this.router.navigateByUrl(`/${RoutingConfig.Login}/${RoutingConfig.SignIn}`).catch()
      }
    )
  }
}
